<template>
  <div class="container-xxl">
    <div class="minicalculator-holder">
      <div class="minicalculator-title-holder">
        <Title
          htag="h2"
          class="small"
        >
          {{ miniKalkulatorConfigs.title }}
        </Title>
        <div class="minicalculator-title-tooltip-holder">
          <Tooltip
            :icon-size="24"
            :tooltip-content="miniKalkulatorConfigs.tooltip"
          />
        </div>
      </div>
      <ul
        id="pills-minicalculator"
        class="nav nav-pills"
        role="tablist"
      >
        <li
          v-for="(
            minikalkulator, index
          ) in miniKalkulatorConfigs.calculators.filter(
            (calculator) =>
              calculator.order > 0 && calculator.isVisibleOnHomePage
          )"
          :key="minikalkulator.order"
          class="nav-item col"
          role="presentation"
        >
          <button
            :id="'pills-' + minikalkulator.type + '-tab'"
            class="nav-link"
            :class="index === 0 ? 'active' : ''"
            data-bs-toggle="pill"
            :data-bs-target="'#pills-' + minikalkulator.type"
            type="button"
            role="tab"
            :aria-controls="'#pills-' + minikalkulator.type"
            :aria-selected="index === 0 ? true : false"
            @click="
              ;[
                backgroundImageFunction(minikalkulator.backgroundImage),
                setActiveType(minikalkulator.type),
              ]
            "
          >
            <div class="btn-icon">
              <PhIconManager :ph-icon="`ph-${minikalkulator.icon}`" />
            </div>
            <Title
              :htag="productHtag"
              class="btn-title"
            >
              {{ minikalkulator.title }}
            </Title>
            <div
              v-if="
                minikalkulator.discountLink &&
                  (minikalkulator.discountLink.internalLink ||
                    minikalkulator.discountLink.externalLink)
              "
              class="btn-tag bg-purple text-white"
            >
              <PhIconManager
                :ph-icon="`ph-${minikalkulator.discountIcon}`"
                :size="20"
              />
            </div>
          </button>
        </li>
      </ul>

      <div
        id="pills-tabContent"
        class="tab-content"
      >
        <div
          id="pills-baleset"
          class="tab-pane fade"
          :class="
            miniKalkulatorDefaultSelectedType === 'baleset' ? 'show active' : ''
          "
          role="tabpanel"
          aria-labelledby="pills-baleset-tab"
        >
          <Row12
            v-if="
              balesetCalculator &&
                balesetCalculator.discountLink &&
                (balesetCalculator.discountLink.internalLink ||
                  balesetCalculator.discountLink.externalLink)
            "
          >
            <div
              v-if="!isGamePage"
              class="tag-long w-auto bg-purple"
              @click="clickInnerNavigableLink"
            >
              <div class="icon-holder bg-white">
                <PhIconManager
                  :ph-icon="`ph-${balesetCalculator.discountIcon}`"
                  :size="20"
                  color="purple"
                />
              </div>
              <NavigableLink
                :link-object="balesetCalculator.discountLink"
                class="tag-text text-white text-bold t-normal"
              >
                {{ balesetCalculator.discountLink.title }}
              </NavigableLink>
              <PhIconManager
                ph-icon="ph-arrow-right"
                :size="16"
                color="white"
              />
            </div>
            <div v-else>
              <DiscountBarTooltip
                v-if="balesetCalculator.discountLink"
                class="discountbar-tooltip"
                :tooltip-content="balesetCalculator.discountLink.tooltip"
                :title="balesetCalculator.discountLink.title"
                :discount-icon="balesetCalculator.discountIcon"
              />
            </div>
          </Row12>

          <q-form ref="balesetForm">
            <Row444 class="mb-2">
              <template #first>
                <SelectInput
                  id="MINI_100"
                  v-model:model-value="balesetFormModel.szerzodoNeme"
                  :options="balesetFormModel.szerzodoNemeOpciok"
                  :label="
                    balesetCalculator.fields.find(
                      (field) => field.name === 'MINI_100'
                    ).label
                  "
                  :tooltip-content="
                    balesetCalculator.fields.find(
                      (field) => field.name === 'MINI_100'
                    ).tooltip
                  "
                  validation-rules="VAL_100_SELECT"
                />
              </template>
              <template #second>
                <TextInput
                  id="MINI_101"
                  :key="MINI_101crossFieldValidation"
                  v-model:model-value="balesetFormModel.felnottDarab"
                  :label="
                    balesetCalculator.fields.find(
                      (field) => field.name === 'MINI_101'
                    ).label
                  "
                  :tooltip-content="
                    balesetCalculator.fields.find(
                      (field) => field.name === 'MINI_101'
                    ).tooltip
                  "
                  :init-input-value="balesetFormModel.felnottDarab"
                  :validation-rules="MINI_101crossFieldValidation"
                  :max-number="10"
                  :min-number="0"
                  input-type="accident-number"
                />
              </template>
              <template #third>
                <TextInput
                  id="MINI_102"
                  :key="MINI_102key"
                  v-model:model-value="balesetFormModel.gyermekDarab"
                  mask="#"
                  :label="
                    balesetCalculator.fields.find(
                      (field) => field.name === 'MINI_102'
                    ).label
                  "
                  :tooltip-content="
                    balesetCalculator.fields.find(
                      (field) => field.name === 'MINI_102'
                    ).tooltip
                  "
                  :validation-rules="MINI_102crossFieldValidation"
                  :init-input-value="balesetFormModel.gyermekDarab"
                  :max-number="9"
                  :min-number="0"
                />
              </template>
            </Row444>
            <Row12 class="btn-row">
              <q-btn
                class="btn btn-secondary btn-dense gtmMainPageCalculationStartBaleset"
                @click="validate('balesetForm')"
              >
                {{ balesetCalculator.calculateLink.title }}
              </q-btn>
              <NavigableLink
                :link-object="balesetCalculator.productLink"
                class="text-secondary"
              >
                {{ balesetCalculator.productLink.title }}
              </NavigableLink>
            </Row12>
          </q-form>
        </div>
        <div
          id="pills-lakaseloszto"
          class="tab-pane fade"
          :class="
            miniKalkulatorDefaultSelectedType === 'lakaseloszto'
              ? 'show active'
              : ''
          "
          role="tabpanel"
          aria-labelledby="pills-lakaseloszto-tab"
        >
          <Row12
            v-if="
              lakasCalculator &&
                lakasCalculator.discountLink &&
                (lakasCalculator.discountLink.internalLink ||
                  lakasCalculator.discountLink.externalLink)
            "
          >
            <div
              v-if="!isGamePage"
              class="tag-long w-auto bg-purple"
              @click="clickInnerNavigableLink"
            >
              <div class="icon-holder bg-white">
                <PhIconManager
                  :ph-icon="`ph-${lakasCalculator.discountIcon}`"
                  :size="20"
                  color="purple"
                />
              </div>
              <NavigableLink
                :link-object="lakasCalculator.discountLink"
                class="tag-text text-white text-bold t-normal"
              >
                {{ lakasCalculator.discountLink.title }}
              </NavigableLink>
              <PhIconManager
                ph-icon="ph-arrow-right"
                :size="16"
                color="white"
              />
            </div>
            <div v-else>
              <DiscountBarTooltip
                v-if="lakasCalculator.discountLink"
                class="discountbar-tooltip"
                :tooltip-content="lakasCalculator.discountLink.tooltip"
                :title="lakasCalculator.discountLink.title"
                :discount-icon="lakasCalculator.discountIcon"
              />
            </div>
          </Row12>

          <q-form ref="lakasForm">
            <Row444 class="mb-2">
              <template #first>
                <SelectInput
                  id="MINI_103"
                  v-model:model-value="lakasFormModel.biztositasTargya"
                  :options="lakasFormModel.biztositastargyaOpciok"
                  :label="
                    lakasCalculator.fields.find(
                      (field) => field.name === 'MINI_103'
                    ).label
                  "
                  :tooltip-content="
                    lakasCalculator.fields.find(
                      (field) => field.name === 'MINI_103'
                    ).tooltip
                  "
                  validation-rules="VAL_100_SELECT"
                />
              </template>
              <template #second>
                <SelectInput
                  id="MINI_104"
                  v-model:model-value="lakasFormModel.ingatlanTipusa"
                  :options="lakasFormModel.ingatlantipusaOpciok"
                  :label="
                    lakasCalculator.fields.find(
                      (field) => field.name === 'MINI_104'
                    ).label
                  "
                  :tooltip-content="
                    lakasCalculator.fields.find(
                      (field) => field.name === 'MINI_104'
                    ).tooltip
                  "
                  validation-rules="VAL_100_SELECT"
                />
              </template>
              <template #third>
                <ZipCodeInput
                  id="MINI_105"
                  v-model:model-value="lakasFormModel.iranyitoSzam"
                  :label="
                    lakasCalculator.fields.find(
                      (field) => field.name === 'MINI_105'
                    ).label
                  "
                  :tooltip-content="
                    lakasCalculator.fields.find(
                      (field) => field.name === 'MINI_105'
                    ).tooltip
                  "
                  validation-rules="VAL_100_SELECT|VAL_111"
                />
              </template>
            </Row444>
            <Row12 class="btn-row">
              <q-btn
                class="btn btn-secondary btn-dense gtmMainPageCalculationStartLakas"
                @click="validate('lakasForm')"
              >
                {{ lakasCalculator.calculateLink.title }}
              </q-btn>
              <NavigableLink
                :link-object="lakasCalculator.productLink"
                class="text-secondary"
              >
                {{ lakasCalculator.productLink.title }}
              </NavigableLink>
            </Row12>
          </q-form>
        </div>
        <div
          id="pills-kotelezo"
          class="tab-pane fade"
          :class="
            miniKalkulatorDefaultSelectedType === 'kotelezo'
              ? 'show active'
              : ''
          "
          role="tabpanel"
          aria-labelledby="pills-kotelezo-tab"
        >
          <Row12
            v-if="
              kotelezoCalculator &&
                kotelezoCalculator.discountLink &&
                (kotelezoCalculator.discountLink.internalLink ||
                  kotelezoCalculator.discountLink.externalLink)
            "
          >
            <div
              v-if="!isGamePage"
              class="tag-long w-auto bg-purple"
              @click="clickInnerNavigableLink"
            >
              <div class="icon-holder bg-white">
                <PhIconManager
                  :ph-icon="`ph-${kotelezoCalculator.discountIcon}`"
                  :size="20"
                  color="purple"
                />
              </div>
              <NavigableLink
                :link-object="kotelezoCalculator.discountLink"
                class="tag-text text-white text-bold t-normal"
              >
                {{ kotelezoCalculator.discountLink.title }}
              </NavigableLink>
              <PhIconManager
                ph-icon="ph-arrow-right"
                :size="16"
                color="white"
              />
            </div>
            <div v-else>
              <DiscountBarTooltip
                v-if="kotelezoCalculator.discountLink"
                class="discountbar-tooltip"
                :tooltip-content="kotelezoCalculator.discountLink.tooltip"
                :title="kotelezoCalculator.discountLink.title"
                :discount-icon="kotelezoCalculator.discountIcon"
              />
            </div>
          </Row12>

          <q-form ref="kotelezoForm">
            <Row66 class="mb-2">
              <template #first>
                <SelectInput
                  id="MINI_106"
                  v-model:model-value="kotelezoFormModel.szerzodeskotesOka"
                  :options="kotelezoFormModel.szerzodeskotesOkaOpciok"
                  :label="
                    kotelezoCalculator.fields.find(
                      (field) => field.name === 'MINI_106'
                    ).label
                  "
                  :tooltip-content="
                    kotelezoCalculator.fields.find(
                      (field) => field.name === 'MINI_106'
                    ).tooltip
                  "
                  validation-rules="VAL_100_SELECT"
                />
              </template>
              <template #second>
                <TextInput
                  id="MINI_107"
                  :key="kotelezoFormModel.frszNincs"
                  v-model:model-value="kotelezoFormModel.rendszam"
                  :is-disabled="kotelezoFormModel.frszNincs === true"
                  :label="
                    kotelezoCalculator.fields.find(
                      (field) => field.name === 'MINI_107'
                    ).label
                  "
                  :tooltip-content="
                    kotelezoCalculator.fields.find(
                      (field) => field.name === 'MINI_107'
                    ).tooltip
                  "
                  :validation-rules="
                    kotelezoFormModel.frszNincs === true
                      ? ''
                      : 'VAL_100|VAL_191|VAL_345|VAL_346'
                  "
                  :max-length="8"
                />
              </template>
            </Row66>
            <Row12>
              <CheckboxInput
                v-if="kotelezoFormModel.szerzodeskotesOka === '0'"
                id="MINI_117"
                v-model="kotelezoFormModel.frszNincs"
                :tooltip-content="
                  kotelezoCalculator.fields.find(
                    (field) => field.name === 'MINI_117'
                  ).tooltip
                "
              >
                {{ kotelezoCalculator.fields.find(
                  (field) => field.name === 'MINI_117'
                ).label }}
              </CheckboxInput>
            </Row12>
            <Row12 class="btn-row">
              <q-btn
                class="btn btn-secondary btn-dense gtmMainPageCalculationStartKotelezo"
                @click="validate('kotelezoForm')"
              >
                {{ kotelezoCalculator.calculateLink.title }}
              </q-btn>
              <NavigableLink
                :link-object="kotelezoCalculator.productLink"
                class="text-secondary"
              >
                {{ kotelezoCalculator.productLink.title }}
              </NavigableLink>
            </Row12>
          </q-form>
        </div>
        <div
          id="pills-casco"
          class="tab-pane fade"
          :class="
            miniKalkulatorDefaultSelectedType === 'casco' ? 'show active' : ''
          "
          role="tabpanel"
          aria-labelledby="pills-casco-tab"
        >
          <Row12
            v-if="
              activeCascoCalculator &&
                activeCascoCalculator.discountLink &&
                (activeCascoCalculator.discountLink.internalLink ||
                  activeCascoCalculator.discountLink.externalLink)
            "
          >
            <div
              v-if="!isGamePage"
              class="tag-long w-auto bg-purple"
              @click="clickInnerNavigableLink"
            >
              <div class="icon-holder bg-white">
                <PhIconManager
                  :ph-icon="`ph-${activeCascoCalculator.discountIcon}`"
                  :size="20"
                  color="purple"
                />
              </div>
              <NavigableLink
                :link-object="activeCascoCalculator.discountLink"
                class="tag-text text-white text-bold t-normal"
              >
                <span v-sanitize="activeCascoCalculator.discountLink.title" />
              </NavigableLink>
              <PhIconManager
                ph-icon="ph-arrow-right"
                :size="16"
                color="white"
              />
            </div>
            <div v-else>
              <DiscountBarTooltip
                v-if="activeCascoCalculator.discountLink"
                class="discountbar-tooltip"
                :tooltip-content="activeCascoCalculator.discountLink.tooltip"
                :title="activeCascoCalculator.discountLink.title"
                :discount-icon="activeCascoCalculator.discountIcon"
              />
            </div>
          </Row12>

          <q-tabs
            v-model="cascoTabs"
            dense
            class="text-dark-300 text-left"
            active-color="dark"
            indicator-color="dark"
          >
            <q-tab
              class="col"
              name="casco"
            >
              <Title
                htag="h3"
                class="q-tab__label mb-0"
              >
                {{ cascoCalculator.title }}
              </Title>
              <Tooltip :tooltip-content="cascoCalculator.homePageTooltip" />
            </q-tab>

            <q-tab
              ref="miniCascoRef"
              class="col"
              name="minicasco"
            >
              <Title
                htag="h3"
                class="q-tab__label mb-0"
              >
                {{ miniCascoCalculator.title }}
              </Title>
              <Tooltip :tooltip-content="miniCascoCalculator.homePageTooltip" />
            </q-tab>
            <q-tab
              class="col"
              name="lopascasco"
            >
              <Title
                htag="h3"
                class="q-tab__label mb-0"
              >
                {{ lopasCascoCalculator.title }}
              </Title>
              <Tooltip
                :tooltip-content="lopasCascoCalculator.homePageTooltip"
              />
            </q-tab>
          </q-tabs>

          <q-tab-panels
            v-model="cascoTabs"
            animated
          >
            <q-tab-panel name="casco">
              <q-form ref="cascoForm">
                <Row444 class="mb-2">
                  <template #first>
                    <SelectInput
                      id="MINI_108"
                      v-model:model-value="cascoFormModel.szerzodeskotesOka"
                      :options="cascoFormModel.szerzodeskotesOkaOpciok"
                      :label="
                        cascoCalculator.fields.find(
                          (field) => field.name === 'MINI_108'
                        ).label
                      "
                      :tooltip-content="
                        cascoCalculator.fields.find(
                          (field) => field.name === 'MINI_108'
                        ).tooltip
                      "
                      validation-rules="VAL_100_SELECT"
                    />
                  </template>
                  <template #second>
                    <TextInput
                      id="MINI_107"
                      :key="cascoFormModel.frszNincs"
                      v-model:model-value="cascoFormModel.rendszam"
                      :is-disabled="cascoFormModel.frszNincs === true"
                      :label="
                        cascoCalculator.fields.find(
                          (field) => field.name === 'MINI_107'
                        ).label
                      "
                      :tooltip-content="
                        cascoCalculator.fields.find(
                          (field) => field.name === 'MINI_107'
                        ).tooltip
                      "
                      :validation-rules="
                        cascoFormModel.frszNincs === true
                          ? ''
                          : 'VAL_100|VAL_191|VAL_345|VAL_346'
                      "
                      :max-length="8"
                    />
                  </template>
                  <template #third>
                    <SelectInput
                      id="MINI_109"
                      v-model:model-value="cascoFormModel.felhasznalasmodja"
                      :options="cascoFormModel.felhasznalasmodjaOpciok"
                      :label="
                        cascoCalculator.fields.find(
                          (field) => field.name === 'MINI_109'
                        ).label
                      "
                      :tooltip-content="
                        cascoCalculator.fields.find(
                          (field) => field.name === 'MINI_109'
                        ).tooltip
                      "
                      validation-rules="VAL_100_SELECT|VAL_238_SELECT"
                    />
                  </template>
                </Row444>
                <Row12 v-if="cascoFormModel.szerzodeskotesOka === '1'">
                  <CheckboxInput
                    id="MINI_117"
                    v-model="cascoFormModel.frszNincs"
                    :tooltip-content="
                      cascoCalculator.fields.find(
                        (field) => field.name === 'MINI_117'
                      ).tooltip
                    "
                  >
                    {{
                      cascoCalculator.fields.find(
                        (field) => field.name === 'MINI_117'
                      ).label
                    }}
                  </CheckboxInput>
                </Row12>
                <Row12 class="btn-row">
                  <q-btn
                    class="btn btn-secondary btn-dense gtmMainPageCalculationStartCasco"
                    @click="validate('cascoForm')"
                  >
                    {{ cascoCalculator.calculateLink.title }}
                  </q-btn>
                  <NavigableLink
                    :link-object="cascoCalculator.productLink"
                    class="text-secondary"
                  >
                    {{ cascoCalculator.productLink.title }}
                  </NavigableLink>
                </Row12>
              </q-form>
            </q-tab-panel>

            <q-tab-panel name="minicasco">
              <q-form ref="miniCascoForm">
                <Row444 class="mb-2">
                  <template #first>
                    <SelectInput
                      id="MINI_110"
                      v-model:model-value="
                        miniCascoFormModel.szerzodoErdekeltseg
                      "
                      :options="miniCascoFormModel.szerzodoErdekeltsegOpciok"
                      :label="
                        miniCascoCalculator.fields.find(
                          (field) => field.name === 'MINI_110'
                        ).label
                      "
                      :tooltip-content="
                        miniCascoCalculator.fields.find(
                          (field) => field.name === 'MINI_110'
                        ).tooltip
                      "
                      validation-rules="VAL_100_SELECT"
                    />
                  </template>
                  <template #second>
                    <SelectInput
                      id="MINI_111"
                      v-model:model-value="miniCascoFormModel.forgalmiNev"
                      :options="miniCascoFormModel.forgalmiNevOpciok"
                      :label="
                        miniCascoCalculator.fields.find(
                          (field) => field.name === 'MINI_111'
                        ).label
                      "
                      :tooltip-content="
                        miniCascoCalculator.fields.find(
                          (field) => field.name === 'MINI_111'
                        ).tooltip
                      "
                      validation-rules="VAL_100_SELECT"
                    />
                  </template>
                  <template #third>
                    <SelectInput
                      id="MINI_112"
                      v-model:model-value="miniCascoFormModel.elozoKgfb"
                      :options="miniCascoFormModel.elozoKgfbOpciok"
                      :label="
                        miniCascoCalculator.fields.find(
                          (field) => field.name === 'MINI_112'
                        ).label
                      "
                      :tooltip-content="
                        miniCascoCalculator.fields.find(
                          (field) => field.name === 'MINI_112'
                        ).tooltip
                      "
                      validation-rules="VAL_100_SELECT"
                    />
                  </template>
                </Row444>
                <Row12 class="btn-row">
                  <q-btn
                    class="btn btn-secondary btn-dense gtmMainPageCalculationStartMiniCasco"
                    @click="validate('miniCascoForm')"
                  >
                    {{ miniCascoCalculator.calculateLink.title }}
                  </q-btn>
                  <NavigableLink
                    :link-object="miniCascoCalculator.productLink"
                    class="text-secondary"
                  >
                    {{ miniCascoCalculator.productLink.title }}
                  </NavigableLink>
                </Row12>
              </q-form>
            </q-tab-panel>

            <q-tab-panel name="lopascasco">
              <q-form ref="lopasCascoForm">
                <Row444 class="mb-2">
                  <template #first>
                    <SelectInput
                      id="MINI_110"
                      v-model:model-value="
                        lopasCascoFormModel.szerzodoErdekeltseg
                      "
                      :options="lopasCascoFormModel.szerzodoErdekeltsegOpciok"
                      :label="
                        lopasCascoCalculator.fields.find(
                          (field) => field.name === 'MINI_110'
                        ).label
                      "
                      :tooltip-content="
                        lopasCascoCalculator.fields.find(
                          (field) => field.name === 'MINI_110'
                        ).tooltip
                      "
                      validation-rules="VAL_100_SELECT"
                    />
                  </template>
                  <template #second>
                    <SelectInput
                      id="MINI_111"
                      v-model:model-value="lopasCascoFormModel.forgalmiNev"
                      :options="lopasCascoFormModel.forgalmiNevOpciok"
                      :label="
                        lopasCascoCalculator.fields.find(
                          (field) => field.name === 'MINI_111'
                        ).label
                      "
                      :tooltip-content="
                        lopasCascoCalculator.fields.find(
                          (field) => field.name === 'MINI_111'
                        ).tooltip
                      "
                      validation-rules="VAL_100_SELECT"
                    />
                  </template>
                  <template #third>
                    <SelectInput
                      id="MINI_113"
                      v-model:model-value="lopasCascoFormModel.gepjarmuFajtaja"
                      :options="lopasCascoFormModel.gepjarmuFajtajaOpciok"
                      :label="
                        lopasCascoCalculator.fields.find(
                          (field) => field.name === 'MINI_113'
                        ).label
                      "
                      :tooltip-content="
                        lopasCascoCalculator.fields.find(
                          (field) => field.name === 'MINI_113'
                        ).tooltip
                      "
                      validation-rules="VAL_100_SELECT"
                    />
                  </template>
                </Row444>
                <Row12 class="btn-row">
                  <q-btn
                    class="btn btn-secondary btn-dense gtmMainPageCalculationStartLopasCasco"
                    @click="validate('lopasCascoForm')"
                  >
                    {{ lopasCascoCalculator.calculateLink.title }}
                  </q-btn>
                  <NavigableLink
                    :link-object="lopasCascoCalculator.productLink"
                    class="text-secondary"
                  >
                    {{ lopasCascoCalculator.productLink.title }}
                  </NavigableLink>
                </Row12>
              </q-form>
            </q-tab-panel>
          </q-tab-panels>
        </div>
        <div
          id="pills-utas"
          class="tab-pane fade"
          :class="
            miniKalkulatorDefaultSelectedType === 'utas' ? 'show active' : ''
          "
          role="tabpanel"
          aria-labelledby="pills-utas-tab"
        >
          <Row12
            v-if="
              utasCalculator &&
                utasCalculator.discountLink &&
                (utasCalculator.discountLink.internalLink ||
                  utasCalculator.discountLink.externalLink)
            "
          >
            <div
              v-if="!isGamePage"
              class="tag-long w-auto bg-purple"
              @click="clickInnerNavigableLink"
            >
              <div class="icon-holder bg-white">
                <PhIconManager
                  :ph-icon="`ph-${utasCalculator.discountIcon}`"
                  :size="20"
                  color="purple"
                />
              </div>
              <NavigableLink
                :link-object="utasCalculator.discountLink"
                class="tag-text text-white text-bold t-normal"
              >
                {{ utasCalculator.discountLink.title }}
              </NavigableLink>
              <PhIconManager
                ph-icon="ph-arrow-right"
                :size="16"
                color="white"
              />
            </div>
            <div v-else>
              <DiscountBarTooltip
                v-if="utasCalculator.discountLink"
                class="discountbar-tooltip"
                :tooltip-content="utasCalculator.discountLink.tooltip"
                :title="utasCalculator.discountLink.title"
                :discount-icon="utasCalculator.discountIcon"
              />
            </div>
          </Row12>
          <q-form ref="utasForm">
            <Row444 class="mb-2">
              <template #first>
                <SelectInput
                  id="MINI_114"
                  v-model:model-value="utasFormModel.utazasOka"
                  :options="utasFormModel.utazasOkaOpciok"
                  :label="
                    utasCalculator.fields.find(
                      (field) => field.name === 'MINI_114'
                    ).label
                  "
                  :tooltip-content="
                    utasCalculator.fields.find(
                      (field) => field.name === 'MINI_114'
                    ).tooltip
                  "
                  validation-rules="VAL_100_SELECT"
                />
              </template>
              <template #second>
                <SelectInput
                  id="MINI_115"
                  v-model:model-value="utasFormModel.utazasiJarmu"
                  :options="utasFormModel.utazasiJarmuOpciok"
                  :label="
                    utasCalculator.fields.find(
                      (field) => field.name === 'MINI_115'
                    ).label
                  "
                  :tooltip-content="
                    utasCalculator.fields.find(
                      (field) => field.name === 'MINI_115'
                    ).tooltip
                  "
                  validation-rules="VAL_100_SELECT"
                />
              </template>
              <template #third>
                <div id="destination">
                  <SelectInput
                    id="MINI_116"
                    v-model:model-value="utasFormModel.uticel"
                    :options="destinationOptions"
                    :label="
                      utasCalculator.fields.find(
                        (field) => field.name === 'MINI_116'
                      ).label
                    "
                    :tooltip-content="
                      utasCalculator.fields.find(
                        (field) => field.name === 'MINI_116'
                      ).tooltip
                    "
                    validation-rules="VAL_100_SELECT"
                    is-autocomplete
                    is-destination-input
                  />
                </div>
              </template>
            </Row444>
            <Row12 class="btn-row">
              <q-btn
                class="btn btn-secondary btn-dense gtmMainPageCalculationStartUtas"
                @click="validate('utasForm')"
              >
                {{ utasCalculator.calculateLink.title }}
              </q-btn>
              <NavigableLink
                :link-object="utasCalculator.productLink"
                class="text-secondary"
              >
                {{ utasCalculator.productLink.title }}
              </NavigableLink>
            </Row12>
          </q-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// https://stackoverflow.com/questions/65108815/how-to-pass-a-ref-to-a-method-function-argument-without-unwrapping/65114047#65114047
import { useQuasar } from 'quasar'
import { computed, inject, onMounted, ref, watch } from 'vue'
import router from '../../router'
import { useCalculatorStore } from '../../stores/calculatorStore'
import optionValues from '../../utils/minicalculatorOptionsValues/optionValues'
import { useTravelDestionationsStore } from '../../stores/travelDestionationsStore'
import { useGtm } from '@gtm-support/vue-gtm'
import { focusFirstValidation } from '../../utils/validations/validationFocus'
import {useRoute} from "vue-router";
import { useMiniCalculatorHelperMixin } from "../../mixins/miniCalculatorHelperMixin"

const route = useRoute()
const calculatorStore = useCalculatorStore()

const $q = useQuasar()
const props = defineProps({
  productHtag: {
    type: String,
    required: true,
  },

  minicalculatorData: {
    type: Object,
    required: true,
  },

  backgroundImageFunction: {
    type: Function,
    default: null,
  },

  isGamePage: {
    type: Boolean,
    default: false,
  },
})

const toFormRefs = (refs) => ({
  ...refs,
  formRefs: {
    ...refs,
  },
})

const MINI_101crossFieldValidation = computed(() => {
  return `VAL_100|VAL_128|VAL_178|VAL_ACCIDENT_MAX:${balesetFormModel.value.gyermekDarab}`
})
const MINI_102crossFieldValidation = computed(() => {
  return `VAL_100|VAL_322|VAL_ACCIDENT_MAX:${balesetFormModel.value.felnottDarab}`
})

const MINI_102key = computed(() => {
  return balesetFormModel.value.gyermekDarab === null
    ? balesetFormModel.value.gyermekDarab
    : balesetFormModel.value.gyermekDarab + balesetFormModel.value.felnottDarab
})

const travelStore = useTravelDestionationsStore()

const destinationOptions = computed(() => {
  return travelStore.getDestinations
})

const {
  pushDataLayerAndRedirectBaleset,
  pushDataLayerLakas,
  pushDataLayerAndRedirectUtas,
  pushDataLayerAndRedirectKotelezo,
  pushDataLayerAndRedirectCasco,
  pushDataLayerAndRedirectMiniCasco,
  pushDataLayerAndRedirectLopasCasco
} = useMiniCalculatorHelperMixin();

const balesetForm = ref(null)
const lakasForm = ref(null)
const kotelezoForm = ref(null)
const cascoForm = ref(null)
const utasForm = ref(null)
const miniCascoForm = ref(null)
const lopasCascoForm = ref(null)
const activeCascoCalculator = ref(null)

const balesetFormModel = ref(optionValues.getAccidentValues())

const lakasFormModel = ref(optionValues.getHomeValues())

const kotelezoFormModel = ref(optionValues.getKgfbValues())

const utasFormModel = ref(optionValues.getTravelValues())

const cascoFormModel = ref(optionValues.getCascoValues())

const miniCascoFormModel = ref(optionValues.getMinicascoValues())

const lopasCascoFormModel = ref(optionValues.getTheftCasco())

const miniKalkulatorConfigs = ref(props.minicalculatorData)

const balesetCalculator = miniKalkulatorConfigs.value.calculators.find(
  (kalkulator) => kalkulator.type === 'baleset'
)
const kotelezoCalculator = miniKalkulatorConfigs.value.calculators.find(
  (kalkulator) => kalkulator.type === 'kotelezo'
)
const cascoCalculator = miniKalkulatorConfigs.value.calculators.find(
  (kalkulator) => kalkulator.type === 'casco'
)
const lakasCalculator = miniKalkulatorConfigs.value.calculators.find(
  (kalkulator) => kalkulator.type === 'lakaseloszto'
)
const utasCalculator = miniKalkulatorConfigs.value.calculators.find(
  (kalkulator) => kalkulator.type === 'utas'
)

const miniCascoCalculator = miniKalkulatorConfigs.value.calculators.find(
  (kalkulator) => kalkulator.type === 'minicasco'
)
const lopasCascoCalculator = miniKalkulatorConfigs.value.calculators.find(
  (kalkulator) => kalkulator.type === 'lopascasco'
)

function miniKalkulatorConfigDataSortComparer(a, b) {
  if (a.order < b.order) {
    return -1
  }
  if (a.order > b.order) {
    return 1
  }
  return 0
}

miniKalkulatorConfigs.value.calculators.sort(
  miniKalkulatorConfigDataSortComparer
)
const miniKalkulatorDefaultSelectedType = ref(
  miniKalkulatorConfigs.value.calculators.filter(
    (calculator) => calculator.order > 0 && calculator.isVisibleOnHomePage
  )[0].type
)

const miniKalkulatorDefaultSelectedBackgroundImage = ref(
  miniKalkulatorConfigs.value.calculators.filter(
    (calculator) => calculator.order > 0 && calculator.isVisibleOnHomePage
  )[0].backgroundImage
)

function setActiveType(type) {
  if (type === 'utas') {
    travelStore.setTravelDestinations()
  }
}

function setCascoCalculator(calculator) {
  activeCascoCalculator.value = calculator
}

props.backgroundImageFunction(
  miniKalkulatorDefaultSelectedBackgroundImage.value
)

onMounted(() => {
  setActiveType(miniKalkulatorDefaultSelectedType.value)

  if (
    cascoCalculator.discountLink &&
    (cascoCalculator.discountLink.internalLink ||
      cascoCalculator.discountLink.externalLink)
  ) {
    setCascoCalculator(cascoCalculator)
  }
})

function clickInnerNavigableLink(element) {
  const currentTarget = element.currentTarget
  const navigableLink = currentTarget.getElementsByClassName(
    'tag-text text-white text-bold t-normal'
  )
  navigableLink[0].click()
}

function validate(name) {
  switch (name) {
    case 'balesetForm':
      balesetForm.value.validate().then((success) => {
        if (success) {
          pushDataLayerAndRedirectBaleset(balesetCalculator, balesetFormModel)
        }
      })
      break
    case 'lakasForm':
      lakasForm.value.validate().then((success) => {
        if (success) {
          pushDataLayerLakas(lakasCalculator, lakasFormModel)

          submitFormToNew(
            lakasForm,
            props.minicalculatorData.calculators.find(
              (field) => field.type === 'lakaseloszto'
            ).calculateLink.internalLink,
            lakasFormModel
          )
        }
      })
      break
    case 'utasForm':
      utasForm.value.validate().then((success) => {
        if (success) {
		  const isGo = route.query.source === 'generalionline' ? 'true' : 'false'
          utasCalculator.calculateLink.internalLink += '/alapadatok'
          pushDataLayerAndRedirectUtas(utasCalculator, utasFormModel, destinationOptions, isGo)
        }
      })
      break
    case 'kotelezoForm':
      kotelezoForm.value.validate().then((success) => {
        if (success) {
          pushDataLayerAndRedirectKotelezo(kotelezoCalculator, kotelezoFormModel)
        }
      })
      break
    case 'cascoForm':
      cascoForm.value.validate().then((success) => {
        if (success) {
          pushDataLayerAndRedirectCasco(cascoCalculator, cascoFormModel)
        }
      })
      break
    case 'miniCascoForm':
      miniCascoForm.value.validate().then((success) => {
        if (success) {
          pushDataLayerAndRedirectMiniCasco(miniCascoCalculator, miniCascoFormModel)
        }
      })
      break
    case 'lopasCascoForm':
      lopasCascoForm.value.validate().then((success) => {
        if (success) {
          pushDataLayerAndRedirectLopasCasco(lopasCascoCalculator, lopasCascoFormModel)
        }
      })
      break

    default:
      console.log('form invalid')
      alert('Váratlan hiba történt!')
      throw new Error(form)
  }

  focusFirstValidation()
}

const cascoTabs = ref('casco')

watch(cascoTabs, (newValue, oldValue) => {
  if (newValue === 'casco') {
    setCascoCalculator(cascoCalculator)
  } else if (newValue === 'minicasco') {
    setCascoCalculator(miniCascoCalculator)
  } else if (newValue === 'lopascasco') {
    setCascoCalculator(lopasCascoCalculator)
  }
})
function submitFormToNew(inputRef, calculateLink, inputs) {
  inputRef.value.validate().then(
    (success) => {
      if (success) {
        calculatorStore.sethousehold(
          inputs.value.biztositastargyaOpciok.find(
            (field) => field.value === inputs.value.biztositasTargya
          ),
          inputs.value.ingatlantipusaOpciok.find(
            (field) => field.value === inputs.value.ingatlanTipusa
          ),

          inputs.value.iranyitoSzam
        )

        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);

        const queryObject = {};
        params.forEach((value, key) => {
          queryObject[key] = value;
        });

        router.push({ path: calculateLink, query: queryObject })
      } else {
        focusFirstValidation()
      }
    },
    (error) => {
      alert('Váratlan hiba történt!')
      throw new Error(error)
    }
  )
}

function AddUtmQueryParams() {
  const urlParams = new URLSearchParams(window.location.search)
  const utmSource = urlParams.get('utm_source') ?? urlParams.get('source')
  const utmMedium = urlParams.get('utm_medium') ?? urlParams.get('medium')
  const utmTerm = urlParams.get('utm_term') ?? urlParams.get('term')
  const utmContent = urlParams.get('utm_content') ?? urlParams.get('content')
  const utmCampaign = urlParams.get('utm_campaign') ?? urlParams.get('campaign')
  const csidteszt = urlParams.get('csidteszt') ?? urlParams.get('csidteszt')
  const gadSource = urlParams.get('gad_source');
  const fbclid = urlParams.get('fbclid');
  let propsLink = "";

  propsLink += utmSource !== null ? `&utm_source=${utmSource}` : ''
  propsLink += utmMedium !== null ? `&utm_medium=${utmMedium}` : ''
  propsLink += utmTerm !== null ? `&utm_term=${utmTerm}` : ''
  propsLink += utmContent !== null ? `&utm_content=${utmContent}` : ''
  propsLink += utmCampaign !== null ? `&utm_campaign=${utmCampaign}` : ''
  propsLink += csidteszt !== null ? `&csidteszt=${csidteszt}` : ''
  propsLink += gadSource !== null ? `&gad_source=${gadSource}` : ''
  propsLink += fbclid !== null ? `&fbclid=${fbclid}` : ''

  return propsLink;
}

watch(
  () => kotelezoFormModel.value.frszNincs,
  (newValue) => {
    if (!newValue) {
      kotelezoFormModel.value.rendszam = null
    }
  }
)

watch(
  () => kotelezoFormModel.value.szerzodeskotesOka,
  (newValue) => {
    if (newValue !== '0' && kotelezoFormModel.value.frszNincs) {
      kotelezoFormModel.value.frszNincs = false
    }
  }
)

watch(
  () => cascoFormModel.value.frszNincs,
  (newValue) => {
    if (!newValue) {
      cascoFormModel.value.rendszam = null
    }
  }
)

watch(
  () => cascoFormModel.value.szerzodeskotesOka,
  (newValue) => {
    if (newValue !== '1' && cascoFormModel.value.frszNincs) {
      cascoFormModel.value.frszNincs = false
    }
  }
)
</script>

<script>
export default {
  name: 'MinicalCulator',
}
</script>

<style lang="scss" scoped>
.btn-row {
  margin-top: 0.5rem;
}

.container-xxl {
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
}
#pills-casco {
  :deep(.q-tab-panels) {
    .scroll {
      overflow: hidden;
    }
  }
}

.minicalculator-holder {
  margin-top: 7rem;

  .minicalculator-title-holder {
    display: flex;
    flex-direction: row;
    align-items: center;

    .minicalculator-title-tooltip-holder {
      margin-bottom: 2rem;
    }
  }
}
@media screen and (max-width: $breakpoint-xl-max) and (min-width: $breakpoint-lg) {
  #home-page {
    .minicalculator-holder {
      margin-top: 5rem;
    }
  }
}
@media screen and (max-width: $breakpoint-lg-max) {
  .minicalculator-holder {
    width: 100% !important;
    margin: 2.5rem 0;
  }
}

@media screen and (max-width: $breakpoint-sm-max) {
  .minicalculator-holder {
    margin: 2rem 0rem;
    padding: 1rem 0;
  }
}

:deep(.minicalculator-holder) {
  width: 50.875rem;
  background: linear-gradient(121.27deg, #fafbfc 31.25%, #faf7f4 100%);
  border-radius: $normal;
  padding: 2.5rem;
  color: $dark;
  box-shadow: $box-shadow-md;

  & > .minicalculator-title-holder > h2.small {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 2rem;
  }

  .q-tabs__content.row.no-wrap.items-center.self-stretch.hide-scrollbar.relative-position.q-tabs__content--align-center.scroll {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    width: auto;
  }

  ul#pills-minicalculator {
    background: #e3e2ea;
    box-shadow: inset 0rem 0rem 0.25rem rgb(0 0 0 / 2%);
    border-radius: 0.75rem;
    padding: $small;
    margin-bottom: $double;

    .col {
      margin: 0;
      padding: 0;

      .nav-link {
        background-color: #ecebef;
        border-radius: 0.625rem;
        padding: $half;
        width: 100%;
        color: $dark;
        position: relative;

        &:hover {
          background: #f7f7f9;
        }

        h2 {
          color: $dark;
          font-size: $font-size-md;
          line-height: $line-height-md;
          text-transform: uppercase;
          font-weight: $font-weight-medium;
          margin-bottom: 0;
        }

        &.active {
          background-color: white;
          color: $secondary;
          box-shadow: 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.06),
            0rem 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
        }

        .btn-icon {
          margin-bottom: $half;
        }

        .btn-tag {
          border-radius: 100%;
          width: 1.5rem;
          height: 1.5rem;
          position: absolute;
          top: $half;
          right: $half;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
      }

      &.nav-item {
        margin-right: $small;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .tag-long {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: $small 0.75rem $small $small;
    border-radius: 0.75rem;
    max-width: fit-content;
    margin-bottom: $double;
    cursor: pointer;

    .icon-holder {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: $normal;
    }

    .tag-text {
      margin-right: $normal;
    }

    &:hover {
      background: #811d8a !important;
    }
  }

  .btn-row {
    .btn-dense {
      padding: 0.75rem 1.25rem;
      box-shadow: 0rem 0.625rem 0.9375rem rgba(78, 149, 251, 0.1);
      border-radius: 0.75rem;
      font-size: $normal;
      line-height: $line-height-base;
      margin-right: 1.5rem;
      color: white;
      text-transform: none;
      font-weight: $font-weight-medium;

      &::before {
        box-shadow: none;
      }
    }

    a.text-secondary {
      font-size: $normal;
      line-height: $line-height-base;
      font-weight: $font-weight-medium;
    }

    a:hover {
      color: #3e77c9 !important;
    }
    a.q-btn:hover {
      color: white !important;
    }
  }

  .q-tab-panels {
    background: transparent;

    .q-tab-panel {
      padding: 1.5rem 0 0;
      background: transparent;
    }
  }

  .q-tab {
    &__label {
      color: #77809d;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
    }

    &__content {
      padding: 0.5rem 0;
    }

    &__indicator {
      margin-right: 0.125rem;
      margin-left: 0.125rem;
    }

    &--active {
      background: #d5d9eb;
      border-radius: 0.25rem 0.25rem 0rem 0rem;

      .q-tab__label {
        color: #1d2c5b;
      }

      .q-tab__indicator {
        color: #d5d9eb !important;
      }
    }

    &--inactive {
      .q-tab__indicator {
        color: #d5d9eb !important;
      }
    }
  }
}
:deep(.q-item__label) {
  color: $D-700;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem !important;
}
#destination :deep(.q-field__label) {
  position: absolute;
}
.discountbar-tooltip {
  justify-content: start;
  height: auto !important;
  margin-left: 0;
  width: 100% !important;
}
</style>
