<template>
  <div class="text-input">
    <q-input
      v-model="value"
      :for="id"
      :mask="mask"
      standout
      unmasked-value
      :error="!!veeErrors[0] || warningMessages?.length > 0"
      :class="(warningMessages?.length > 0 && veeErrors.length == 0)?'has-warning':''"
      :label="label"
      :maxlength="maxLength"
      :readonly="readonly"
      :disable="isDisabled"
      :reverse-fill-mask="reverseFillMask"
      @blur="inputChanged, handleInfoWarningMessages(value),handleBlur()"
      @change="inputChanged"
      @focus="handleFocus"
    >
      <template #label>
        {{ label }}
        <Tooltip :tooltip-content="tooltipContent" />
      </template>
      <template #append>
        <div
          v-sanitize="postfix"
          class="input-postfix"
        />
      </template>
      <template #error>
        <ValidationMessage
          :messages="veeErrors"
          :info-messages="infoMessages"
          :warning-messages="warningMessages"
        />
      </template>
    </q-input>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, inject } from 'vue'
import { useField } from 'vee-validate'
import {
  getInfoMessagesForValue,
  getWarningMessagesForValue,
} from '@/utils/validations/customValidator.js'
import { useFormChild } from 'quasar'

const props = defineProps({
  validateOnMount: {
    type: Boolean,
    required: false,
    default: true,
  },
  id: {
    type: String,
    required: true,
  },

  mask: {
    type: String,
    default: '########################',
  },

  label: {
    type: String,
    required: true,
  },

  tooltipContent: {
    type: String,
    default: '',
  },

  validationRules: {
    type: String,
    default: '',
  },

  infoMessageRules: {
    type: String,
    default: '',
  },
  warningMessageRules: {
    type: String,
    default: '',
  },
  modelValue: {
    type: Number,
    default: 0,
  },
  initInputValue: {
    type: Number,
    default: 0,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  postfix: {
    type: String,
    required: false,
    default: '',
  },
  reverseFillMask: {
    type: Boolean,
    required: false,
  },
  warningMessagesTarget: {
    type: Object,
    required: false,
    default: () => {},
  },
  maxLength: {
    type: Number,
    default: -1,
  },
  ignoreZeroDefaultValue: {
    type: Boolean,
    required: false,
  }
})

const emit = defineEmits(['update:modelValue'])

const infoMessages = ref(null)
const warningMessages = ref(null)
const removeValueIfNotValid = inject('_removeValueIfNotValid')

onMounted(() => {
  if (props.modelValue !== 0) {
    value.value = props.modelValue
    emit('update:modelValue', value.value)
    if(props.validateOnMount) {
      validateQForm()
    }
  } else {
    value.value = 0
  }
})

watch(
  () => props.modelValue,
  (newValue) => {
    value.value = newValue
    validateQForm()
    handleInfoWarningMessages(value.value)
  }
)
async function validateQForm() {
  // run vee-validate validations
  await veeValidate()

  if (veeErrors.value.length > 0) {
    if(removeValueIfNotValid.value){
      value.value = 0;
      inputChanged();
      return true;
    }
    return false
  } else {
    return true
  }
}

function handleFocus() {
  if (value.value.toString().charAt(0) === '0') {
    value.value = null
  }
}

function inputChanged() {
  if (value.value === null || value.value === '') {
    if(!props.ignoreZeroDefaultValue) {
      value.value = 0
    }
  }
  emit('update:modelValue', value.value)
  validateQForm()
}

function handleBlur() {
  if (value.value === null || value.value === '') {
    if(!props.ignoreZeroDefaultValue) {
      value.value = 0
    }
  }
  inputChanged()
}

function resetValidationQForm() {}

function handleInfoWarningMessages(value) {
  infoMessages.value = getInfoMessagesForValue(value, props.infoMessageRules)
  warningMessages.value = getWarningMessagesForValue(
    value,
    props.warningMessageRules,
    props.warningMessagesTarget
  )
}

const {
  handleChange,
  errors: veeErrors,
  value,
  validate: veeValidate,
} = useField(props.id, props.validationRules, {
  validateOnValueUpdate: false,
  bails: true,
})

useFormChild({
  validate: validateQForm, // Function; Can be async; // Should return a Boolean (or a Promise resolving to a Boolean)
  resetValidation: resetValidationQForm, // Optional function which resets validation
  requiresQForm: false, // should it error out if no parent QForm is found?
})
</script>

<script>
export default {
  name: 'TextInput',
}
</script>

<style lang="scss" scoped>
:deep(.q-field--standout) {
  &.q-field * {
    border-width: 0 !important;
  }

  .q-field__control:before {
    background-color: transparent;
  }

  &.q-field--highlighted .q-field__control {
    box-shadow: none;
  }

  .q-field__control {
    border-radius: $half !important;
    padding: 0 1rem !important;
    box-shadow: inset 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.06) !important;
    border: 1px solid #f5f4f8 !important;
    background: white;
  }

  .q-field__label {
    font-size: $font-size-sm;
    line-height: $line-height-sm;
    font-weight: $font-weight-medium;
    color: $D-400 !important;
  }

  .q-field--float {
    .q-field__label {
      transform: translateY(-40%) scale(10 / 12) !important;
    }
  }

  .q-field__native,
  .q-field__prefix,
  .q-field__suffix,
  .q-field__input {
    font-weight: $font-weight-bold !important;
  }

  .q-field__append {
    background: transparent !important;
    width: auto !important;
  }
}

:deep(.q-field--standout:hover) {
  .q-field__control {
    border: 1px solid $border-data-hover !important;
    background-color: $input-data-bg-hover !important;
  }
}

:deep(.q-field--standout.q-field--focused) {
  .q-field__control {
    border: 1px solid $border-active !important;
  }
}

:deep(.q-field--focused:hover) {
  .q-field__control {
    background-color: white !important;
    border: 1px solid $border-active !important;
  }
}

:deep(.q-field--float:hover) {
  .q-field__control {
    border: 1px solid $border-data-hover !important;
    background-color: $input-data-bg-hover !important;
  }
}

:deep(.q-field--float:hover) {
  &.q-field--focused {
    .q-field__control {
      border: 1px solid $border-active !important;
    }
  }
}

:deep(.q-field--error) {
  & * {
    color: $input-text-validation !important;
  }

  .q-field__control {
    border: 1px solid $border-validation !important;
    background-color: white !important;
  }
}

:deep(.q-field--standout) {
  &.q-field.q-field--error * {
    color: $input-text-validation !important;
  }
}

:deep(.q-field--standout.q-field .q-field__append) {
  background: transparent !important;
  width: auto !important;

  svg,
  svg * {
    color: $N-600 !important;
  }

  .input-postfix {
    color: $D-700;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
}

:deep(.q-field--standout.q-field--disabled) {
  .q-field__control {
    background: #eef0f7;
    .q-field__label {
      color: #b4b9cb !important;
    }
  }
}
</style>
