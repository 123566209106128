<template>
  <span
    v-if="tooltipContent.length"
    class="cursor-pointer all-pointer-events"
    :class="alternateContentClass !== '' ? alternateContentClass : ''"
    @click="(e) => toggleState(e)"
  >
    <div
      ref="tooltip"
      data-bs-toggle="tooltip"
      :data-bs-title="tooltipContent"
      data-bs-container="body"
      :data-bs-placement="tooltipPlacement"
      :data-bs-custom-class="customClass"
      data-bs-html="true"
    >
      <PhIconManager
        v-if="alternateContent === ''"
        ph-icon="ph-info"
        :size="iconSize"
      />
      <div v-else>
        {{ alternateContent }}
      </div>
    </div>
  </span>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { Tooltip } from 'bootstrap'

const props = defineProps({
  tooltipContent: {
    type: String,
    required: false,
    default: '',
  },

  iconSize: {
    type: Number,
    required: false,
    default: 20,
  },

  tooltipPlacement: {
    type: String,
    required: false,
    default: 'right',
  },

  customClass: {
    type: String,
    required: false,
    default: 'gt-tooltip',
  },

  alternateContent: {
    type: String,
    required: false,
    default: '',
  },

  alternateContentClass: {
    type: String,
    required: false,
    default: '',
  },
})

const tooltip = ref(null)
const tooltipshow = ref(false)
const tooltipToggle = ref(null)

onMounted(() => {
  if (tooltip.value) {
    tooltipToggle.value = new Tooltip(tooltip.value)
  }
  observeTooltip()
})

function toggleState(e) {
  if (e) {
    if (!tooltipshow.value) {
      tooltipToggle.value.show()
      tooltipshow.value = !tooltipshow.value
    } else {
      tooltipToggle.value.hide()
      tooltipshow.value = !tooltipshow.value
    }
    e.preventDefault()
    e.stopPropagation()
  }
}

function observeTooltip() {
  const tooltipElements = document.getElementsByClassName(
    'tooltip fade show bs-tooltip-end'
  )

  for (let i = 0; i < tooltipElements.length; i++) {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'style'
        ) {
          const transformValue = tooltipElements[i].style.transform
          const matches = transformValue.match(
            /translate3d\(([^,]+), ([^,]+), ([^,]+)\)/
          )
          if (matches && matches[1] && matches[1].trim() === '0px') {
            tooltipElements[i].remove()
            observer.disconnect()
          }
        }
      })
    })

    observer.observe(tooltipElements[i], {
      attributes: true,
      attributeFilter: ['style'],
      childList: false,
      characterData: false,
    })
  }
}
</script>

<script>
export default {
  name: 'TooltipElement',
}
</script>

<style>
.gt-tooltip {
  z-index: 999999 !important;
}
</style>
