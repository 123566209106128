<template>
  <q-form
    ref="couponFormRef"
    @submit.prevent="useCoupon"
  >
    <div class="calculator-holder">
      <Row66
        id="KALK_119"
        class="input-container test-container"
      >
        <template #first>
          <div class="calculator-description-holder">
            <TitleTooltipModal
              htag="h3"
              class="input-title"
              :tooltip-content="tooltipText"
              :tooltip-title="title"
            >
              <PhIconManager
                v-if="props.icon"
                height="1.6rem"
                width="1.6rem"
                ph-icon="ph-tag"
                class="tag-icon"
              />
              {{ title }}
            </TitleTooltipModal>
            <p
              v-if="description"
              class="small"
              v-html="description"
            />
          </div>
        </template>
        <template #second>
          <div class="input-holder">
            <TextInputPackage
              id="UT_121"
              v-model:model-value="value"
              :init-input-value="value"
              label="Kuponkód"
              max-length="20"
              :error="false"
            />
          </div>
          <div class="coupon-button-container">
            <q-btn
              class="btn btn-secondary btn-outline btn-back"
              @click="useCoupon()"
            >
              Kuponkód beváltása
            </q-btn>
          </div>
          <div
            v-if="alertShow"
            class="coupon-alert"
          >
            <Alert
              :color="alertType"
              :title="alertTitle"
              :content="alertContent"
            />
          </div>
        </template>
      </Row66>
    </div>
  </q-form>
</template>

<script setup>
import { customDefineRule } from '@/utils/validations/customDefineRule'
import * as ValidationMessages from '@/utils/validations/validationMessages.js'
import { onMounted, ref, watch } from 'vue'
import TextInputPackage from "@/components/inputs/TextInputPackage.vue";

const emits = defineEmits(['useCoupon', 'clearCouponError', 'clearCoupon'])

const responseData = {
  HasError: false,
}

const props = defineProps({
  couponCode: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: '',
    required: true,
  },
  description: {
    type: String,
    default: '',
    required: false,
  },
  alertTitle: {
    type: String,
    default: '',
  },
  alertContent: {
    type: String,
    default: '',
  },
  alertType: {
    type: String,
    default: 'success',
  },
  alertShow: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
  },
  validationErrorCode: {
    type: String,
  },
  tooltipText: {
    type: String,
    required: false,
  },
})

const value = ref(props.couponCode ?? null)
const validationErrorCode = ref(props.validationErrorCode)

function useCoupon() {
  // console.log('MATT - useCoupon')
  emits('useCoupon', {
    coupon: {
      couponValue: value.value,
    },
    callback: async (errorCode) => {
      validationErrorCode.value = errorCode
    },
  })
}

const clearCouponInput = () => {
  if(value.value === null || value.value === undefined || value.value === '') {
    emits('clearCouponError', {
      coupon: {
        couponValue: value.value,
      }
    })
  }
}

function handleSubmit() {
  // console.log('Form submitted:', value.value);
}

customDefineRule('VAL_147', async (input) => {
  // Megjelenik, ha a megadott kuponkód nem található a kupon adatbázisban.
  if (value.value && validationErrorCode.value === '2201') {
    return ValidationMessages.VAL_147
  } else {
    return true
  }
})

customDefineRule('VAL_148', (input) => {
  // Megjelenik, ha a megadott kuponkód lejáratának dátuma kisebb, mint az adott nap.
  if (value.value && validationErrorCode.value === '2203') {
    return ValidationMessages.VAL_148
  }

  return true
})

customDefineRule('VAL_149', (input) => {
  // Megjelenik, ha a megadott kuponkód már felhasználásra került.
  if (value.value && validationErrorCode.value === '2202') {
    return ValidationMessages.VAL_149
  }

  return true
})
customDefineRule('VAL_150', (input) => {
  // Megjelenik, ha a megadott kuponkód X(biztosítás fajtája) termék esetében nem használható fel.
  if (value.value && validationErrorCode.value === '2204') {
    return ValidationMessages.VAL_150
  }
  return true
})
customDefineRule('VAL_151', (input) => {
  // Megjelenik, ha a megadott kuponkód az adott felületen nem beváltható.
  if (value.value && validationErrorCode.value === '2201') {
    return ValidationMessages.VAL_151
  }
  return true
})
customDefineRule('VAL_213', (input) => {
  // Megjelenik, ha a megadott kuponkód a kiválasztott fizetési ütemnél nem használható fel
  // (excelben nincs benne ez a hibakód)
  if (value.value && validationErrorCode.value === '2207') {
    return ValidationMessages.VAL_213
  }
  return true
})

customDefineRule('VAL_214', (input) => {
  // Megjelenik, ha a megadott kuponkód
  // a szerződés éves díjánál nem használható fel (alacsony a szerződés éves díja).
  if (value.value && validationErrorCode.value === '2206') {
    return ValidationMessages.VAL_214
  }
  return true
})
customDefineRule('VAL_457', (input) => {
  // Megjelenik, ha a megadott kuponkód adatbázis szerinti egyéb feltételei közül valamelyik nem teljesül /
  // utas biztosítás esetén: az adott kupon különbönböző, eltérő okok miatt nem váltható be.
  if (
    value.value &&
    (validationErrorCode.value === '2215' ||
      validationErrorCode.value === '2216' ||
      validationErrorCode.value === '2213')
  ) {
    return ValidationMessages.VAL_457
  }
  return true
})

watch(value, (newValue, oldValue) => {
  if (!!oldValue || !!newValue) {
    if (newValue.length > 0) {
      useCoupon()
    }
    emits('clearCouponError', {
      callback: async (errorCode) => {
        validationErrorCode.value = errorCode
      }
    })
  }
})

onMounted(() => (value.value = props.couponCode))
</script>

<style lang="scss" scoped>
.calculator-holder {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.test-container {
  padding: 2rem;
  template {
    width: 50%;
  }
}

.coupon-button-container {
  display: flex;
  justify-content: flex-end;
}

.coupon-alert {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.coupon-success {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: #ffffff;
  box-shadow: 0rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.06),
    0rem 0.0625rem 0.1875rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  padding: 1rem;

  .success-title {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #111a37;
    margin: 0;
  }
}

.coupon-summary-row {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  .coupon-summary-item {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex: 1;
  }

  .summary-title {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #828ba8;
    margin: 0;
  }

  .summary-price {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #111a37;
    margin: 0;
  }
}

.description-font-size {
  font-size: 14px;
}

.tag-icon {
  color: white;
  background: #a124ad;
  padding: 2px;
  border-radius: 50%;
}

@media screen and (max-width: $breakpoint-xs-max) {
  .calculator-holder {
    margin-top: 0;
    margin-bottom: 0;
  }
  .coupon-button-container {
    button {
      width: 100%;
    }
  }
  .test-container {
    padding: 1.5rem 1rem;
    template {
      width: 50%;
    }
  }
  .calculator-description-holder {
    padding-bottom: 1rem !important;
  }
}
</style>
