<template>
  <q-form ref="inputRef">
    <div class="calculator-container">
      <p
        v-if="shouldUseCMSLabel"
        class="text-center info-label-text"
        v-html="texts.vtxt_firstPageInfo_label"
      />
      <div class="content-holder">
        <div class="title-holder">
          <Title htag="h2">
            {{ travelWizardStore.getTexts?.vtxt_firstPageFirstBlockTitle_label }}
          </Title>
        </div>
        <div class="input-holder">
          <DatePicker
            v-if="viewModel"
            id="UT_107"
            v-model:model-value="viewModel.CoverageStartDate.Value"
            item="utas-dp-start"
            :init-input-value="coverageStartDateInitValue"
            :start-date="initDpStartDateStart"
            :end-date="initDpEndDateStart"
            :default-year-month="initDpViewMonthStart"
            label="Utazás kezdete"
            :tooltip-content="travelWizardStore.getTexts.vtxt_ut107_tooltipContent"
            validation-rules="VAL_100|VAL_115|VAL_320|VAL_333"
            ignore-override-input
            @blur="handleInsuranceStartDatePick"
            @update:model-value="handleInsuranceStartDatePick"
            @update:remove-value="removeDateValue"
          />
          <DatePicker
            v-if="viewModel"
            id="UT-108"
            v-model:model-value="viewModel.CoverageEndDate.Value"
            item="utas-dp-end"
            :init-input-value="coverageEndDateInitValue"
            :start-date="initDpStartDateEnd"
            :end-date="initDpEndDateEnd"
            :default-year-month="initDpViewMonthEnd"
            label="Utazás vége"
            :tooltip-content="travelWizardStore.getTexts?.vtxt_ut108_tooltipContent"
            validation-rules="VAL_100|VAL_116|VAL_297|VAL_333"
            ignore-override-input
            @blur="handleInsuranceEndDatePick"
            @update:model-value="handleInsuranceEndDatePick"
            @update:remove-value="removeDateValue"
          />
        </div>
      </div>
      <div class="content-holder">
        <div class="title-holder">
          <Title htag="h2">
            {{ travelWizardStore.getTexts?.vtxt_firstPageSecondBlockTitle_label }}
            <Tooltip
              :icon-size="24"
              :tooltip-content="travelWizardStore.getTexts?.vtxt_firstPageSecondBlockTitle_tooltipContent"
            />
          </Title>
          <p
            v-html="travelWizardStore.getTexts?.vtxt_firstPageSecondBlockDescription_label"
          />
        </div>
        <div class="input-holder">
          <div class="counter-container">
            <p class="counter-label">
              Felnőtt (18-69 éves)
              <span class="up-3">
                <Tooltip
                  :icon-size="21"
                  :tooltip-content="travelWizardStore.getTexts?.vtxt_ut109_tooltipContent"
                />
              </span>
            </p>
            <NumericUpDownInputWithUnit
              id="UT_109"
              ref="adultsRef"
              v-model:model-value="insuredAdultsNumber"
              :init-input-value="insuredAdultsInitValue"
              :max-number="maxNumberOfInsuredAdults"
              :min-number="MIN_NUMBER_OF_INSUREDS"
              right-label="Fő"
              mask="##"
              validation-rules="VAL_128:UT_109|VAL_126:UT_109"
              :aria-label="'Felnőtt (18-69 éves)'"
              :aria-labelledby="'Felnőtt (18-69 éves)'"
              :hide-bottom-space="true"
              @update:model-value="handleAdultsNumberChange"
            />
          </div>
          <div class="counter-container">
            <p class="counter-label">
              Gyerek (0-17 éves)
              <span class="up-3">
                <Tooltip
                  :icon-size="21"
                  :tooltip-content="travelWizardStore.getTexts?.vtxt_ut110_tooltipContent"
                />
              </span>
            </p>
            <NumericUpDownInputWithUnit
              id="UT_110"
              ref="childrenRef"
              v-model:model-value="insuredChildrenNumber"
              :init-input-value="insuredChildrenInitValue"
              :max-number="maxNumberOfInsuredChildren"
              :min-number="MIN_NUMBER_OF_INSUREDS"
              right-label="Fő"
              mask="##"
              validation-rules="VAL_128:UT_110|VAL_126:UT_110"
              :aria-label="'Gyerek (0-17 éves)'"
              :aria-labelledby="'Gyerek (0-17 éves)'"
              :hide-bottom-space="true"
              @update:model-value="handleChildrenNumberChange"
            />
          </div>
          <div class="counter-container">
            <p class="counter-label">
              Nyugdíjas (70-79 éves)
              <span class="up-3">
                <Tooltip
                  :icon-size="21"
                  :tooltip-content="travelWizardStore.getTexts?.vtxt_ut111_tooltipContent"
                />
              </span>
            </p>
            <NumericUpDownInputWithUnit
              id="UT_111"
              ref="pensionersRef"
              v-model:model-value="insuredPensionersNumber"
              :init-input-value="insuredPensionersInitValue"
              :max-number="maxNumberOfInsuredPensioners"
              :min-number="MIN_NUMBER_OF_INSUREDS"
              right-label="Fő"
              mask="##"
              validation-rules="VAL_128:UT_111|VAL_126:UT_111"
              :aria-label="'Nyugdíjas (70-79 éves)'"
              :aria-labelledby="'Nyugdíjas (70-79 éves)'"
              :hide-bottom-space="true"
              @update:model-value="handlePensionersNumberChange"
            />
          </div>
        </div>
      </div>
    </div>
  </q-form>
  <DevToolsUtas
    v-if="showDebug"
    :view-model="viewModel"
    :page="viewModel.Page"
    :texts="texts"
    :current-url="currentURL"
    @update:blacklist-check="(v) => (viewModel.TechnicalData.BlacklistCheck.Value = v)"
    @update:test-system-date="(v) => systemDateChanged(v)"
  />
</template>
<script setup>
import { useTravelWizardStore } from '@/stores/travelWizardStore'
import * as ValidationMessages from '@/utils/validations/validationMessages'
import { defineRule } from 'vee-validate'
import {computed, provide, ref, onMounted, onUnmounted, watch} from 'vue'
import DatePicker from "@/components/inputs/DatePicker.vue";
import DevToolsUtas from "@/components/controls/DevToolsUtas.vue";
import {customDefineRule} from "@/utils/validations/customDefineRule";

const MIN_NUMBER_OF_INSUREDS = 0
const MAX_NUMBER_OF_INSUREDS = 10

const props = defineProps({
  viewModel: {
    type: Object,
    required: false,
  },
  texts: {
    type: Object,
    required: false,
  },
})

const DateTime = window.luxon.DateTime
const inputRef = ref(null)
const adultsRef = ref(null)
const childrenRef = ref(null)
const pensionersRef = ref(null)
const insuredChildrenNumber = ref(null)
const insuredAdultsNumber = ref(null)
const insuredPensionersNumber = ref(null)
const travelWizardStore = useTravelWizardStore()
const viewModel = ref(travelWizardStore.getViewModel)

const initDpStartDateStart = ref(DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }))
const initDpEndDateStart = ref(DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).plus({days: 89}))
const initDpStartDateEnd = ref(DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }))
const initDpEndDateEnd = ref(DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).plus({days: 29}))
const initDpViewMonthStart = ref(DateTime.now().toFormat("yyyy/MM"))
const initDpViewMonthEnd = ref(DateTime.now().toFormat("yyyy/MM"))

const showDebug = ref(false)

const coverageStartDateInitValue = computed(
  () => viewModel.value.CoverageStartDate.Value ?? null
)

const coverageEndDateInitValue = computed(
  () => viewModel.value.CoverageEndDate.Value ?? null
)

const numberOfInsuredAdults = computed(
  () => viewModel.value.InsuredAdultsNumber.Value?.Id ??  insuredAdultsNumber.value
)
const numberOfInsuredChildren = computed(
  () => viewModel.value.InsuredChildrenNumber.Value?.Id ??  insuredChildrenNumber.value
)
const numberOfInsuredPensioners = computed(
  () => viewModel.value.InsuredPensionersNumber.Value.Id ?? insuredPensionersNumber.value
)

const sumOfInsured = computed(
  () =>
    numberOfInsuredAdults.value +
    numberOfInsuredChildren.value +
    numberOfInsuredPensioners.value
)

const insuredAdultsInitValue = computed(() => numberOfInsuredAdults.value ?? 2)
const insuredChildrenInitValue = computed(
  () => numberOfInsuredChildren.value ?? 0
)
const insuredPensionersInitValue = computed(
  () => numberOfInsuredPensioners.value ?? 0
)

const maxNumberOfInsuredAdults = computed(
  () =>
    MAX_NUMBER_OF_INSUREDS - sumOfInsured.value + numberOfInsuredAdults.value
)
const maxNumberOfInsuredChildren = computed(
  () =>
    MAX_NUMBER_OF_INSUREDS - sumOfInsured.value + numberOfInsuredChildren.value
)
const maxNumberOfInsuredPensioners = computed(
  () =>
    MAX_NUMBER_OF_INSUREDS -
    sumOfInsured.value +
    numberOfInsuredPensioners.value
)

const shouldUseCMSLabel = computed(() => {
  return (
    travelWizardStore.getTexts?.vtxt_firstPageInfo_label?.length > 0 &&
    travelWizardStore.getTexts?.vtxt_firstPageShoudDisplayInfo_label
  )
})
// const shouldUseHardcodedLabel = computed(() => {
//   return (
//     travelWizardStore.getTexts?.vtxt_firstPageInfo_label?.length === 0 &&
//       travelWizardStore.getTexts?.vtxt_firstPageShoudDisplayInfo_label
//   )
// })

const emit = defineEmits(['validateForm'])

let currentDate
currentDate = DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

onMounted(() => {
  document.addEventListener('keyup', onKeyUp)
  if (sessionStorage.getItem('tm') !== null || sessionStorage.getItem('tm') !== undefined) {
    showDebug.value = sessionStorage.getItem('tm') === 'true'
  }
  if ( viewModel.value.TestSystemDate.Value ) {
    currentDate = DateTime.fromISO(viewModel.value.TestSystemDate.Value.replaceAll('.','-'))
  }
})
onUnmounted( () => {
  document.removeEventListener('keyup', onKeyUp)
})

function validatForm() {
  emit('validateForm')
}

function removeDateValue(t) {
  // console.log('MATT - removeData... ', t)
  if (t === 'utas-dp-start') {
    delete viewModel.value.CoverageStartDate.Value
    if (viewModel.value.CoverageEndDate.Value) {
      initDpViewMonthStart.value = DateTime.fromISO(viewModel.value.CoverageEndDate.Value.replaceAll('.','-')).minus({ days : 29 }).toFormat("yyyy/MM")
    } else {
      initDpViewMonthStart.value = currentDate.toFormat("yyyy/MM")
      initDpViewMonthEnd.value = currentDate.toFormat("yyyy/MM")
    }
  } else if (t === 'utas-dp-end') {
    delete viewModel.value.CoverageEndDate.Value
    if (viewModel.value.CoverageStartDate.Value) {
      initDpViewMonthEnd.value = DateTime.fromISO(viewModel.value.CoverageStartDate.Value.replaceAll('.','-')).toFormat("yyyy/MM")
    } else {
      initDpViewMonthEnd.value = currentDate.toFormat("yyyy/MM")
      initDpViewMonthStart.value = currentDate.toFormat("yyyy/MM")
    }
  }
  createDpDates('start' )
  createDpDates( 'end' )
  travelWizardStore.setViewModel(viewModel.value, '1.Page - 1')
  // console.log('MATT - Start: ' + initDpViewMonthStart.value + " End: " + initDpViewMonthEnd.value)
}

function handleInsuranceStartDatePick(e) {
  viewModel.value.CoverageStartDate.Value = e
  travelWizardStore.setViewModel(viewModel.value, '1.Page - 2')
  initDpViewMonthEnd.value = DateTime.fromISO(e.replaceAll('.','-')).toFormat("yyyy/MM")
  // console.log('MATT - mod - END: ', initDpViewMonthEnd.value)
  createDpDates('end')
  if (viewModel.value.CoverageEndDate.Value !== undefined && viewModel.value.CoverageEndDate.Value !== null) {
    validatForm()
  }
}
function handleInsuranceEndDatePick(e) {
  viewModel.value.CoverageEndDate.Value = e
  travelWizardStore.setViewModel(viewModel.value, '1.Page - 3')
  initDpViewMonthStart.value = DateTime.fromISO(e.replaceAll('.','-')).minus({ days : 29 }).toFormat("yyyy/MM")
  // console.log('MATT - mod - START: ', initDpViewMonthStart.value)
  createDpDates('start')
  if (viewModel.value.CoverageStartDate.Value !== undefined && viewModel.value.CoverageStartDate.Value !== null) {
    validatForm()
  }
}

function Date90DaysFromNow() {
  return currentDate.value.plus({ days: 89 })
}
function actAllInsuredControl() {
  if (sumOfInsured.value > 0 ) {
    travelWizardStore.setViewModel(viewModel.value, '1.Page - 4')
  }
}

function handleAdultsNumberChange(e) {
  const found = viewModel.value.InsuredAdultsNumber.Values.find(
    (x) => x.Id == e
  )
  insuredAdultsNumber.value = e
  viewModel.value.InsuredAdultsNumber.Value = found
  if (e < viewModel.value.InsuredAdults.length) {
    viewModel.value.InsuredAdults.splice(-1, 1)
  }
  actAllInsuredControl()
}

function handleChildrenNumberChange(e) {
  const found = viewModel.value.InsuredChildrenNumber.Values.find(
    (x) => x.Id == e
  )
  insuredChildrenNumber.value = e
  viewModel.value.InsuredChildrenNumber.Value = found
  if (e < viewModel.value.InsuredChildren.length) {
    viewModel.value.InsuredChildren.splice(-1, 1)
  }
  actAllInsuredControl()
}

function handlePensionersNumberChange(e) {
  const found = viewModel.value.InsuredPensionersNumber.Values.find(
    (x) => x.Id == e
  )
  insuredPensionersNumber.value = e
  viewModel.value.InsuredPensionersNumber.Value = found
  if (e < viewModel.value.InsuredPensioners.length) {
    viewModel.value.InsuredPensioners.splice(-1, 1)
  }
  actAllInsuredControl()
}

function deleteAllMessages() {
  adultsRef.value.deleteErrorMessages()
  childrenRef.value.deleteErrorMessages()
  pensionersRef.value.deleteErrorMessages()
}

function deleteOtherMessages(id) {
  switch (id) {
    case 'UT_109':
      childrenRef.value.deleteErrorMessages()
      pensionersRef.value.deleteErrorMessages()
      break
    case 'UT_110':
      adultsRef.value.deleteErrorMessages()
      childrenRef.value.deleteErrorMessages()
      break
    case 'UT_111':
      adultsRef.value.deleteErrorMessages()
      childrenRef.value.deleteErrorMessages()
  }
}

function createDpDates(type) {
  // DATEPICKER TRIP START
  if (type === 'start') {
      if (viewModel.value.CoverageEndDate.Value) {
        initDpStartDateStart.value = currentDate
        const diffDays = DateTime.fromISO(viewModel.value.CoverageEndDate.Value.replaceAll('.', '-')).diff(currentDate, 'days')
        if (diffDays.days < 120) {
          initDpEndDateStart.value = DateTime.fromISO(viewModel.value.CoverageEndDate.Value.replaceAll('.','-'))
        }
      } else {
        initDpStartDateStart.value = currentDate
        initDpEndDateStart.value = currentDate.plus({ days: 89 })
      }
  }
  // DATEPICKER TRIP END
  if (type === 'end') {
    if (viewModel.value.CoverageStartDate.Value) {
      initDpStartDateEnd.value = DateTime.fromISO(viewModel.value.CoverageStartDate.Value.replaceAll('.','-'))
      initDpEndDateEnd.value = DateTime.fromISO(viewModel.value.CoverageStartDate.Value.replaceAll('.','-')).plus({ days: 29 })
    } else {
      initDpStartDateEnd.value = currentDate
      initDpEndDateEnd.value = currentDate.plus({ days: 29 })
    }
  }
  travelWizardStore.setViewModel(viewModel.value, '1.Page - 5')
}

defineRule('VAL_126', (value, [id]) => {
  adultsRef.value.deleteErrorMessages()

  if (value !== undefined) {
    if (sumOfInsured.value === 0) {
      deleteOtherMessages(id)
      return ValidationMessages.VAL_126
    }
    if (value === 1 && sumOfInsured.value === 1) {
      deleteAllMessages()
    }
  }

  return true
})

defineRule('VAL_128', (value, [id]) => {
  if (value !== undefined && value > 0 && sumOfInsured.value > MAX_NUMBER_OF_INSUREDS) {
      return ValidationMessages.VAL_128
  } else if(sumOfInsured.value <= MAX_NUMBER_OF_INSUREDS) {
   deleteAllMessages()
  }
  return true
})

customDefineRule('VAL_115', (value) => {
  if (value !== undefined) {
    if (DateTime.fromISO(value.replaceAll('.', '-')) < currentDate) {
      return ValidationMessages.VAL_115
    }
  }
  return true
})

customDefineRule('VAL_116', (value) => {
  if (value !== undefined && value.length === 10 && viewModel.value.CoverageStartDate.Value != null) {
    value = value.replaceAll('.', '-')
    const sd = viewModel.value.CoverageStartDate.Value.replaceAll('.', '-')

    const selectedDate = DateTime.fromISO(value)
    const startDate = DateTime.fromISO(sd)

    if (selectedDate < startDate) {
      return ValidationMessages.VAL_116
    }
  }
  return true
})

customDefineRule('VAL_297', (value) => {
  if (value !== undefined && viewModel.value.CoverageStartDate.Value != null) {
    const selectedDate = DateTime.fromISO(value.replaceAll('.', '-'))
    const sDate = DateTime.fromISO(viewModel.value.CoverageStartDate.Value.replaceAll('.', '-'))
    const diffInDays = selectedDate.diff(sDate, 'days').days + 1
    if (diffInDays > 30) {
      return ValidationMessages.VAL_297
    }
  }
  return true
})

customDefineRule('VAL_320', (value) => {
  if (value !== undefined) {
    value = value.replaceAll('.', '-')
    const selectedDate = DateTime.fromISO(value)
    const minDate = currentDate
    const maxDate = currentDate.plus({days: 89})
    if (value.length === 10 && selectedDate !== 'Invalid DateTime' && DateTime.fromISO(value).isValid) {
      if ((minDate > selectedDate) || (maxDate < selectedDate)) {
        return ValidationMessages.VAL_320
      }
    }
  }
  return true
})

customDefineRule('VAL_333', (value) => {
  if (value !== undefined) {
    value = value.replaceAll('.', '-')

    if (value.length === 10 && !DateTime.fromISO(value).isValid) {
      return ValidationMessages.VAL_333
    }
  }
  return true
})

provide('viewModel', viewModel)

//DEVTOOLS functions

function systemDateChanged(date) {
  if (date.length === 10) {
    viewModel.value.TestSystemDate.Value = date
    currentDate = DateTime.fromFormat(date, 'yyyy.MM.dd')
  } else {
    delete viewModel.value.TestSystemDate.Value
    currentDate = DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  }
  viewModel.value.CoverageStartDate.Value =  currentDate.toFormat("yyyy.MM.dd")
  travelWizardStore.setViewModel(viewModel.value, '1.Page - 6')
  createDpDates('start')
  createDpDates('end')
  validatForm()
}

function onKeyUp(event) {
  if (event.key === 'F2' && import.meta.env.MODE !== 'production') {
    showDebug.value = !showDebug.value
    sessionStorage.setItem('tm', showDebug.value)
  }
}

</script>
<style lang="scss" scoped>
.counter-container {
  .counter-label {
    margin-bottom: 8px;
    font-size: 0.875rem;
    color: #111a37;
  }
  &:not(:last-child) {
    margin-bottom: 24px;
  }

  .unit-display {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: unset;
    color: #111a37;
  }
}

.info-label-text {
  font-size: 16px;
}

.content-holder {
  display: flex;
  justify-content: space-between;
  padding: 2.5rem;
  width: 100%;
  background: #f7f7fb;
  border-radius: 12px;
  :deep(a) {
    color: #4e95fb;
  }
}

.calculator-container {
  padding: 0 2rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 90rem;
  width: 100%;
  gap: 1.5rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  :deep(a) {
    color: $secondary;
  }
}

@media screen and (max-width: $breakpoint-xl-max) {
  .content-holder {
    padding: 2rem;
  }
}

@media screen and (max-width: $breakpoint-md-max) {
  .calculator-container {
    margin-top: 0;
  }
  .content-holder {
    padding: 1.5rem 1rem;
  }
}

@media screen and (max-width: $breakpoint-xs-max) {
  .calculator-container {
    margin-top: 0;
    padding: 0 1rem;
  }

  .content-holder {
    padding: 1.5rem 1rem;
    flex-direction: column;
  }

  .title-holder {
    h2 {
      margin-bottom: 1.5rem;
    }
  }
}

.title-holder {
  flex: 1;
  h2 {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #1d2c5b;
  }
}

.input-holder {
  flex: 1;
}
.up-3 {
  top: -3px;
  position: relative;
}
</style>
