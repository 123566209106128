export default {
  install: (app, options) => {
    // app.config.globalProperties.$pushDataLayer= (event, insurance, paramater_1, paramater_2, paramater_3,paramater_4, device) => {

    function pushDataLayer(event, insurance, parameter1, parameter2, parameter3, device, utmParams, eventCallback = null) {
      // console.log('MATT - pushDataLayer - 3')
      let dataLayerObject = {
        event: event,
        insurance: insurance,
        parameter_1: parameter1,
        paramater_2: parameter2,
        parameter_3: parameter3,
        device: device,
        utm_uniqueEventId: 37
      }

      if (eventCallback) {
        // console.log('MATT - eventCallback - ??? ', dataLayerObject)
        dataLayerObject.eventCallback = eventCallback
      }

      if (utmParams != null) {
        for (let i in utmParams) {
          if (utmParams[i]) {
            dataLayerObject[i] = utmParams[i]
          }
        }
      }

      window.dataLayer.push(dataLayerObject);
    }

    function pushDataLayerNext(width, calculator, blockName, userType, step, utmParams) {
      const device = width > 1199 ? "desktop" : "mobil";

      let dataLayerObject = {
        event: "calculation_step",
        flow: "biztositas",
        calculator: calculator,
        block: blockName,
        device: device,
        user_type: userType,
        step: step
      }

      if (utmParams != null) {
        for (let i in utmParams) {
          dataLayerObject[i] = utmParams[i]
        }
      }

      window.dataLayer?.push(dataLayerObject);
    }

    function pushDataLayerBase(object, width) {
      const device = width > 1199 ? "desktop" : "mobil";

      if(import.meta.env.VITE_RESP_BASEURL.includes('koszonjuk')){
        console.log('pushDataLayerBase')
        console.log(device)
        console.log('calculator')
        console.log('purchase')
      }

      window.dataLayer?.push(object);
    }

    function pushDataLayerMarketingStatement(width, calculator, blockName, userType) {
      const device = width > 991 ? "desktop" : "mobil";

      dataLayer.push({
        event: "marketing_consent",
        calculator: calculator,
        block: blockName,
        device: device,
        user_type: userType
      });
    }

    function pushDataLayerCoupon(width, event, insurance, coupon, userType, errorMessage) {
      const device = width > 991 ? "desktop" : "mobil";

      if (event == "coupon_redemption_success") {
        dataLayer.push({
          event: event,
          insurance: insurance,
          coupon: coupon,
          device: device,
          user_type: userType
        });
      } else {
        // coupon_redemption_error
        dataLayer.push({
          event: event,
          insurance: insurance,
          coupon: coupon,
          error_message: errorMessage.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          device: device,
          user_type: userType
        });
      }
    }

    app.provide('pushDataLayer', pushDataLayer)
    app.provide('pushDataLayerNext', pushDataLayerNext)
    app.provide('pushDataLayerMarketingStatement', pushDataLayerMarketingStatement)
    app.provide('pushDataLayerCoupon', pushDataLayerCoupon)
    app.provide('pushDataLayerBase', pushDataLayerBase)
  },
}