import { useGtm } from '@gtm-support/vue-gtm'
import router from '../../router'
import { focusFirstValidation } from '../validations/validationFocus'

const gtm = useGtm()

let productViewHelper = {
  getTextValue(obj, type, name) {
    return obj.filter((field) => field.name === name)[0][type]
  },
  getBreakpoints(sliderLength) {
    switch (sliderLength) {
      case 1:
        return {
          320: {
            slidesPerView: 1,
            spaceBetween: 16,
          },
          576: {
            slidesPerView: 1,
            spaceBetween: 16,
          },
          992: {
            slidesPerView: 1,
            spaceBetween: 16,
          },
        }
      case 2:
        return {
          320: {
            slidesPerView: 1,
            spaceBetween: 16,
          },
          576: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          992: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
        }
      case 3:
        return {
          320: {
            slidesPerView: 1,
            spaceBetween: 16,
          },
          576: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
        }
      default:
        return {
          320: {
            slidesPerView: 1,
            spaceBetween: 16,
          },
          576: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
        }
    }
  },
  submitForm(inputRef, calculateLink, inputs, isIframe, newRoute) {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const couponId = urlParams.get('couponId')
    const utmSource = urlParams.get('utm_source') ?? urlParams.get('source')
    const utmMedium = urlParams.get('utm_medium') ?? urlParams.get('medium')
    const utmTerm = urlParams.get('utm_term') ?? urlParams.get('term')
    const utmContent = urlParams.get('utm_content') ?? urlParams.get('content')
    const utmCampaign = urlParams.get('utm_campaign') ?? urlParams.get('campaign')
    const csidteszt = urlParams.get('csidteszt') ?? urlParams.get('csidteszt')
    const gadSource = urlParams.get('gad_source');
    const fbclid = urlParams.get('fbclid');

    inputRef.validate().then(
      (success) => {
        if (success) {
          let propsLink = calculateLink.internalLink
          if (calculateLink.internalLink.indexOf('utasbiztositas') > -1 && isIframe) {
            propsLink = calculateLink.internalLink + '/alapadatok'
          }
          propsLink += `?${inputs.value1.id}=${inputs.value1.value}`

          /*
            GTF-1339:
            Utasbiztosítás esetén a 'source' nevű URL paraméter értékét tárolja a value4 mező (MINI_177).
            Megjegyzés: Az itt lévő IF feltételeket nem sikerült megfejtenünk, hogy mi célt szolgálnak,
            hogy a value2-t miért ne adjuk át URL paraméterként, ha a value4 értéke true. Jelenleg jól
            működik, ezért nem töröltem ezt a kódrészletet. - y0b5069
          */
          if (inputs.value4.id !== null && inputs.value4.id !== "MINI_177" && inputs.value4.value === true) {
            propsLink += `&${inputs.value2.id}=${null}`
          } else {
            propsLink += `&${inputs.value2.id}=${inputs.value2.value}`
          }

          if (inputs.value3.id !== null) {
            if (inputs.value3.id == 'MINI_112') {
              propsLink += `&${inputs.value3.id}=${inputs.value3.value}&${inputs.value3.id}_text=${inputs.value3.text}`
            } else {
              propsLink += `&${inputs.value3.id}=${inputs.value3.value}`
            }
          }
          if (inputs.value4.id !== null) {
            propsLink += `&${inputs.value4.id}=${inputs.value4.value}`
          }

          propsLink += couponId !== null ? `&couponCode=${couponId}` : ''
          propsLink += utmSource !== null ? `&utm_source=${utmSource}` : ''
          propsLink += utmMedium !== null ? `&utm_medium=${utmMedium}` : ''
          propsLink += utmTerm !== null ? `&utm_term=${utmTerm}` : ''
          propsLink += utmContent !== null ? `&utm_content=${utmContent}` : ''
          propsLink += utmCampaign !== null ? `&utm_campaign=${utmCampaign}` : ''
          propsLink += csidteszt !== null ? `&csidteszt=${csidteszt}` : ''
          propsLink += gadSource !== null ? `&gad_source=${gadSource}` : ''
          propsLink += fbclid !== null ? `&fbclid=${fbclid}` : ''


          if (!isIframe) {
            if (newRoute) {
              router.push(newRoute)
            } else {
              window.location.href = propsLink
            }
          } else {
            // Ha iframe és a base url végén van / és a link elején is van / akkor az egyiket el kell távolítani
            if (
              import.meta.env.VITE_RESP_BASEURL.charAt(
                import.meta.env.VITE_RESP_BASEURL.length - 1
              ) === '/' &&
              propsLink.charAt(0) === '/'
            ) {
              propsLink = propsLink.substring(1)
            }
            // console.log('MATT - Ready propsLink: ', propsLink)
            window.open(
              import.meta.env.VITE_RESP_BASEURL + propsLink,
              '_blank'
            )
          }

          // TODO submit with paramters
          // this.$router.push('/gyakori-kerdesek');
          // window.location.href = import.meta.env.VITE_RESP_BASEURL +'/biztositas/balesetbiztositas/balesetbiztositas-kalkulator';
        } else {
          focusFirstValidation()
        }
      },
      (error) => {
        alert('Váratlan hiba történt!')
        throw new Error(error)
      }
    )
  },
  validateForm(inputRef) {
    return new Promise((resolve) => {
      inputRef.validate().then((success) => {
        if (success) {
          resolve(true)
        } else {
          resolve(false)
        }
      })
    })
  },
}
export default productViewHelper
