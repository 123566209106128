import axios from 'axios'

let apiCallCounter = 0
let closeTimeout = null
const timeoutBeforeClose = 300

const loadingEnvActive = import.meta.env.VITE_WIZARDMANAGER_USEACTIVITYINDICATOR === 'true' ? true : false;

export const apiHandler = {
  getMultiple: async (
    urls,
    names,
    options,
    loadingIndicatorActive = true,
    loadingIndicatorTime = 300
  ) => {
    apiCallCounter++
    if (loadingIndicatorActive && loadingEnvActive && apiCallCounter === 1) {
      setTimeout(() => {
        if (apiCallCounter > 0) showLoadingIndicator()
      }, loadingIndicatorTime)
    }

    const requests = urls.map((url, index) =>
      axios.get(url.includes('/calculator/') ? import.meta.env.VITE_PORTAL_API_BASE_URL + url : import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL + url,
        options[index]
      )
    )
    const responses = await axios.all(requests)
    const result = {}
    responses.forEach((response, index) => {
      result[names[index]] = response.data
    })

    apiCallCounter--
    if (apiCallCounter === 0) {
      if (closeTimeout) clearTimeout(closeTimeout)

      closeTimeout = setTimeout(() => {
        if (apiCallCounter === 0) {
          hideLoadingIndicator()
        }
      }, timeoutBeforeClose)
    }
    return result
  },
  get: async (
    url,
    name,
    option = null,
    loadingIndicatorActive = true,
    loadingIndicatorTime = 300
  ) => {
    apiCallCounter++
    if (loadingIndicatorActive && loadingEnvActive && apiCallCounter === 1) {
      setTimeout(() => {
        if (apiCallCounter > 0) showLoadingIndicator()
      }, loadingIndicatorTime)
    }

    const request = await axios.get(
      url.includes('/calculator/') ? import.meta.env.VITE_PORTAL_API_BASE_URL + url : import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL + url,
      option === null ? {} : option
    )

    const result = {}
    result[name] = request.data

    apiCallCounter--
    if (apiCallCounter === 0) {
      if (closeTimeout) clearTimeout(closeTimeout)

      closeTimeout = setTimeout(() => {
        if (apiCallCounter === 0) {
          hideLoadingIndicator()
        }
      }, timeoutBeforeClose)
    }
    return result
  },
  postMultiple: async (
    urls,
    dataArr,
    names,
    options,
    loadingIndicatorActive = true,
    loadingIndicatorTime = 300
  ) => {
    apiCallCounter++

    if (loadingIndicatorActive && loadingEnvActive && apiCallCounter === 1) {
      setTimeout(() => {
        if (apiCallCounter > 0) showLoadingIndicator()
      }, loadingIndicatorTime)
    }

  let axiosInstance = getAxiosInstance()

    const requests = urls.map((url, index) =>
    axiosInstance.post(
        import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL + url,
        dataArr[index],
        options[index]
      )
    )

    const responses = await axios.all(requests)
    const result = {}
    responses.forEach((response, index) => {
      result[names[index]] = response.data
    })

    apiCallCounter--
    if (apiCallCounter === 0) {
      if (closeTimeout) clearTimeout(closeTimeout)

      closeTimeout = setTimeout(() => {
        if (apiCallCounter === 0) {
          hideLoadingIndicator()
        }
      }, timeoutBeforeClose)
    }
    return result
  },
  post: async (
    url,
    data,
    name,
    option = null,
    loadingIndicatorActive = true,
    loadingIndicatorTime = 300
  ) => {

    let time = Date.now();

    apiCallCounter++
    if (loadingIndicatorActive && loadingEnvActive && apiCallCounter === 1) {
      setTimeout(() => {
        if (apiCallCounter > 0) showLoadingIndicator()
      }, loadingIndicatorTime)
    }

    let axiosInstance = getAxiosInstance(url)

    const request = axiosInstance.post(
      url.includes('/calculator/') ? import.meta.env.VITE_PORTAL_API_BASE_URL + url : import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL + url,
      data,
      option === null ? {} : option
    )
    const result = {}

    const response = await axios.all([request])

    result[name] = response[0].data

    apiCallCounter--

    if (apiCallCounter === 0) {
      if (closeTimeout) clearTimeout(closeTimeout)

      closeTimeout = setTimeout(() => {
        if (apiCallCounter === 0) {
          hideLoadingIndicator()
        }
      }, timeoutBeforeClose)
    }
    return result
  },
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURI(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function getAxiosInstance(url = '')
{
  let axiosInstance = axios.create(
    url.includes('/calculator/') ? { withCredentials: true } : ''
  )
  let campaign_segment_cookie_value = getCookie('campaign_segment')
  if(campaign_segment_cookie_value)
  {
    axiosInstance.defaults.headers = {
      'campaign_segment' : campaign_segment_cookie_value
    }
  }
  return axiosInstance
}

function showLoadingIndicator() {
  const element = document.getElementById('loading-indicator-styles')
  if (element) return;

  const styleTag = document.createElement('style')
  styleTag.id = 'loading-indicator-styles'
  styleTag.innerHTML = `
    .loading-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.92);
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .loading-spinner {
        width: 5rem;
        height: 5rem;
        border: 0.25rem solid #4e95fb;
        border-top: 0.25rem solid rgba(255, 255, 255, 0.92);
        border-radius: 50%;
        animation: spin 1s linear infinite;
      }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    @media screen and (max-width: 767px) {
        .loading-spinner {
            width: 3rem;
            height: 3rem;
            border: 0.1563rem solid #4e95fb;
            border-top: 0.1563rem solid rgba(255, 255, 255, 0.92);
        }
    }
    `
  document.head.appendChild(styleTag)

  const overlay = document.createElement('div')
  overlay.className = 'loading-overlay'
  const spinner = document.createElement('div')
  spinner.className = 'loading-spinner'
  overlay.appendChild(spinner)
  document.body.appendChild(overlay)
}

function hideLoadingIndicator() {
  const overlay = document.querySelector('.loading-overlay')
  if (overlay) {
    document.body.removeChild(overlay)
  }

  const styleTag = document.getElementById('loading-indicator-styles')
  if (styleTag) {
    document.head.removeChild(styleTag)
  }
}
