import axios from 'axios'
import { defineStore } from 'pinia'

export const useTravelDestionationsStore = defineStore('travelDestinations', {
  state: () => {
    return {
      destinations: [],
    }
  },
  getters: {
    getDestinations(state) {
      return state.destinations
    },
  },
  actions: {
    async setTravelDestinations() {
      if (this.destinations.length === 0) {
        await axios
          .get(import.meta.env.VITE_PORTAL_API_BASE_URL +
            '/calculator/TravelWizardApi/Destinations'
          )
          .then((response) => {
            response.data.ResponseData.forEach((element) => {
              let destination = {}
              destination.label = element.Text
              destination.value = element.Id
              this.destinations.push(destination)
            })

            return true
          })
      }
    },
  },
})
