import { defineStore } from 'pinia'
import { apiHandler } from '@/utils/infrastructure/apiHandler'

export const useTravelWizardStore = defineStore('travelWizard', {
  state: () => {
    return {
      calculatorBaseUrl: '/utas/utasbiztositas-kalkulator',
      viewModel: null,
      texts: null,
      fromCalculator: false,
      pagePaths: ['alapadatok','csomagvalasztas','szerzodeskotes','adatellenorzes'],
      currentPage: 'alapadatok',
      documents: null,
      fromSave: false,
      streetOptions: [],
      lastSave: '00000000-0000-0000-0000-000000000000',
      lastSavedModel: {},
      lastContractorAgeGroup: 0,
      partnerDiscountData: {}
    }
  },
  getters: {
    getViewModel(state) {
      // console.log('MATT - vissza adva: ', state.viewModel)
      return state.viewModel
    },
    getTexts: ({texts}) => texts,
    getFromCalculator(state) {
      return state.fromCalculator
    },
    getPagePaths(state) {
      return state.pagePaths
    },
    getCurrentPage(state) {
      return state.currentPage
    },
    getDocuments(state) {
      return state.documents
    },
    getNextPage(state) {
      const indexOfCurrentPage = state.pagePaths.indexOf(state.currentPage)

      if (
        indexOfCurrentPage > -1 &&
        indexOfCurrentPage < state.pagePaths.length - 1
      ) {
        return `${state.calculatorBaseUrl}${
          state.pagePaths[indexOfCurrentPage + 1]
        }`
      }
      return null
    },
    getFromGenerali(state) {
      return state.viewModel.JustCode === '70802628' || state.viewModel.JustCode === '70802632'
    },
    getPreviousPage(state) {
      const indexOfCurrentPage = state.pagePaths.indexOf(state.currentPage)
      if (indexOfCurrentPage > 0) {
        return `${state.calculatorBaseUrl}${
          state.pagePaths[indexOfCurrentPage - 1]
        }`
      }
      return null
    },
    getFromSave(state) {
      return state.fromSave
    },
    getState(state) {
      return state
    },
    getLastSaveId(state) {
      return state.lastSave
    },
    getLastSavedModel(state) {
      return state.lastSavedModel
    },
    getStreetOptions(state) {
      return state.streetOptions
    },
    getPartnerDiscountData(state) {
      return state.partnerDiscount
    },
    getLastContractorAgeGroup(state) {
      return state.lastContractorAgeGroup
    }
  },
  mutations: {},
  actions: {
    async saveViewModel({ pageId, sendEmail }) {
      if (this.lastSave !== '00000000-0000-0000-0000-000000000000') {
        this.viewModel.TechnicalData.SavingId = this.lastSave
      } else {
        // console.log('MATT - ELSŐ MENTÉS !!!')
      }
      const url = '/calculator/TravelWizardApi/Save'
      const data = Object.assign({}, { viewModel: this.viewModel }, { pageId, sendEmail })
      const response = await apiHandler.post(url, data, 'response')
      if (response.response.HasError === false) {
        this.viewModel.TechnicalData = response.response.ResponseData.TechnicalData
        this.lastSave = response.response.ResponseData.TechnicalData.SavingId
        this.lastSavedModel = response.response.ResponseData
      }
    },
    setViewModel(value, from = null) {
      this.viewModel = Object.assign({}, value)
      // console.log('MATT - mentve - ' + from + ': ', this.viewModel)
    },
    setDocuments(value) {
      this.documents = Object.assign({}, value)
    },
    setFromCalculator(value) {
      this.fromCalculator = value
    },
    setTexts(value) {
      this.texts = Object.assign({}, value)
    },
    setCurrentPage(path) {
      const indexOfPath = this.pagePaths.indexOf(`${path}`)
      try {
        this.currentPage = this.pagePaths[indexOfPath]
      } catch (e) {
        return null
      }
    },
    setFromSave(value) {
      this.fromSave = value
    },
    setStreetOptions(value) {
      this.streetOptions = value
    },
    setPartnerDiscountData(value) {
      this.partnerDiscountData = {
        JustCode: value.JustCode,
        PartnerDiscountCode: value.PartnerDiscountCode.Value,
        PartnerDiscount: value.PartnerDiscount.value
      }
    },
    setLastSavingId(value) {
      this.lastSavingId = value
    },
    setLastContractorAgeGroup(value) {
      this.lastContractorAgeGroup = value
    }
  }
})
