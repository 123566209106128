<template>
  <RadioInput
    v-model="value"
    :for="id"
    :options="statementOptions"
    :validation-rules="validationRules"
    :warning-message-rules="warningMessageRules"
    :warning-messages-target="warningMessagesTarget"
    :init-input-value="value"
    :info-message-rules="infoMessageRules"
    @update:model-value="inputChanged"
  />
</template>
<script setup>
import { ref, onMounted } from 'vue'
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  default: {
    type: Boolean,
    required: false,
  },
  optionTrue: {
    type: String,
    required: false,
    default: 'Igen',
  },
  optionFalse: {
    type: String,
    required: false,
    default: 'Nem',
  },
  validationRules: {
    type: String,
    required: false,
    default: '',
  },
  warningMessageRules: {
    type: String,
    default: '',
  },
  warningMessagesTarget: {
    type: Object,
    required: false,
    default: () => {},
  },
  infoMessageRules: {
    type: String,
    default: '',
  },
})
const value = ref(props.default)
const emit = defineEmits(['update:modelValue'])

const statementOptions = ref([])
onMounted(() => {
  statementOptions.value.push(
    {
      label: props.optionTrue,
      value: true,
    },
    {
      label: props.optionFalse,
      value: false,
    }
  )
})

function inputChanged() {
  emit('update:modelValue', value.value)
}
</script>
<style lang="scss" scoped>
.radio-input {
  :deep(.q-option-group) {
    gap: 1rem;
  }
  :deep(.q-option-group > div) {
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: calc(50% - 0.5rem);
    height: 3.25rem;
    box-shadow: 0rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.06),
      0rem 0.0625rem 0.1875rem rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    .q-radio.cursor-pointer.no-outline.row.inline.no-wrap.items-center {
      height: 100%;
      width: 100%;
      padding: 0 1rem;
      margin-right: 0 !important;
      .q-radio__label {
        width: 100%;
      }
    }
  }
}
</style>
