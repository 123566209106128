<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
  <q-layout>
    <BreakingNewsModule
      v-if="
        !['internetExplorer', 'kulsoErtekesites'].includes($route.name) &&
          showNavigation
      "
      :route="$route.path"
    />
    <Header
      v-if="
        ![
          'internetExplorer',
          'kulsoErtekesites',
          'lakasbiztositascalculatorPOC',
          'lakasbiztositasWizard',
          'utabiztositasTest',
          'lakasbiztositascalculatorPocSubpage',
          'MFOlakasbiztositasWizard',
          'MFOlakasbiztositascalculatorSubpage',
          'lakasbiztositascalculatorSubpage',
          'lakasbiztositascalculatorthankyou',
          'lakasbiztositascalculatorsave',
          'lakasbiztositascalculatorsavesuccess',
          'MFOlakasbiztositascalculatorsave',
          'MFOlakasbiztositascalculatorsavesuccess',
          'travelinsurancecalculatorsave',
          'travelinsurancecalculatorsavesuccess',
          'EmailConfirmationGT',
          'EmailConfirmationMFO',
          'EmailConfirmationGTSuccessful',
          'EmailConfirmationMFOSuccessful',
          'EmailConfirmationGTUnsuccessful',
          'EmailConfirmationMFOUnsuccessful',
          'utasbiztositasLayout',
          'EmailConfirmationGTMultipleSuccessful',
          'EmailConfirmationMFOMultipleSuccessful',
          'utasbiztositascalculator',
          'utasbiztositascalculatorcsomagvalasztas',
          'utasbiztositascalculatorszerzodeskotes',
          'utasbiztositascalculatoradatellenorzes',
          'utasbiztositascalculatorkoszonjuk'
        ].includes($route.name) && showNavigation
      "
      @prevent-Scroll="preventScroll"
    />
    <main class="container-fluid">
      <RouterView @view-ready="onViewReady()" />
    </main>

    <Footer
      v-if="
        ![
          'honlapterkep',
          'internetExplorer',
          'kulsoErtekesites',
          'lakasbiztositascalculatorPOC',
          'lakasbiztositascalculator',
          'lakasbiztositasWizard',
          'MFOlakasbiztositasWizard',
          'utabiztositasTest',
          'MFOlakasbiztositascalculatorSubpage',
          'lakasbiztositascalculatorPocSubpage',
          'lakasbiztositascalculatorSubpage',
          'lakasbiztositascalculatorthankyou',
          'lakasbiztositascalculatorsave',
          'lakasbiztositascalculatorsavesuccess',
          'travelinsurancecalculatorsave',
          'travelinsurancecalculatorsavesuccess',
          'MFOlakasbiztositascalculatorsave',
          'MFOlakasbiztositascalculatorsavesuccess',
          'EmailConfirmationGT',
          'utasbiztositasLayout',
          'EmailConfirmationMFO',
          'EmailConfirmationGTSuccessful',
          'EmailConfirmationMFOSuccessful',
          'EmailConfirmationGTUnsuccessful',
          'EmailConfirmationMFOUnsuccessful',
          'EmailConfirmationGTMultipleSuccessful',
          'EmailConfirmationMFOMultipleSuccessful',
          'utasbiztositascalculator',
          'utasbiztositascalculatorcsomagvalasztas',
          'utasbiztositascalculatorszerzodeskotes',
          'utasbiztositascalculatoradatellenorzes',
          'utasbiztositascalculatorkoszonjuk'
        ].includes($route.name) &&
          showFooter &&
          showNavigation
      "
      :class="
        productPages.includes($route.name)
          ? 'productfooter'
          : vehiclePages.includes($route.name)
            ? 'vehiclefooter'
            : paymentPages.includes($route.name)
              ? 'paymentFooter'
              : 'home' === $route.name
                ? 'homeFooter'
                : ''
      "
    />
    <CalculatorFooter
      v-if="
        [
          'lakasbiztositascalculatorPOC',
          'lakasbiztositascalculator',
          'MFOlakasbiztositasWizard',
          'MFOlakasbiztositascalculatorSubpage',
          'lakasbiztositasWizard',
          'lakasbiztositascalculatorSubpage',
          'utasbiztositascalculatorcsomagvalasztas',
          'utabiztositasTest',
          'utasbiztositasLayout',
          'lakasbiztositascalculatorthankyou',
          'lakasbiztositascalculatorsave',
          'lakasbiztositascalculatorsavesuccess',
          'travelinsurancecalculatorsave',
          'travelinsurancecalculatorsavesuccess',
          'MFOlakasbiztositascalculatorsave',
          'MFOlakasbiztositascalculatorsavesuccess',
          'EmailConfirmationGT',
          'EmailConfirmationMFO',
          'EmailConfirmationGTSuccessful',
          'EmailConfirmationMFOSuccessful',
          'EmailConfirmationGTUnsuccessful',
          'utasbiztositascalculatorszerzodeskotes',
          'EmailConfirmationMFOUnsuccessful',
          'EmailConfirmationGTMultipleSuccessful',
          'utasbiztositascalculatoradatellenorzes',
          'EmailConfirmationMFOMultipleSuccessful',
          'utasbiztositascalculator',
          'utasbiztositascalculatorkoszonjuk'
        ].includes($route.name)
      "
      :class="footerClasses"
    />

    <div
      v-if="'emptypathhome' === $route.name"
      class="backtotop-holder"
    >
      <q-page-scroller
        position="bottom-right"
        :scroll-offset="150"
        :offset="[32, 32]"
      >
        <q-btn class="btn-backtotop">
          <div class="btn-text d-xl-none d-flex">
            Vissza a tetejére
          </div>
          <PhIconManager
            ph-icon="ph-arrow-up"
            weight="bold"
          />
        </q-btn>
      </q-page-scroller>
    </div>
    <LoadingOverlay :is-loading="showLoadingIndicator" />
  </q-layout>
  <teleport to="head">
    <component
      :is="'script'"
      type="application/ld+json"
    >
      { "@context": "https://schema.org", "@type": "WebSite", "@id":
      "https://www.genertel.hu", "url": "https://www.genertel.hu", "name":
      "Genertel Biztosító Zrt.", "publisher": { "@type": "Organization", "name":
      "Genertel Biztosító Zrt.", "url": "https://www.genertel.hu", "logo":
      "https://www.genertel.hu/Content/desktop/images/genertel-logo.svg",
      "contactPoint": [ { "@type":"ContactPoint", "telephone": "06 1 288 0000",
      "email": "genertel@genertel.hu", "contactType": "TeleCenter" } ] },
      "potentialAction": { "@type": "SearchAction", "target":
      "https://www.genertel.hu/kereses?query={search_term_string}", "query":
      "required", "query-input": { "@type": "PropertyValueSpecification",
      "valueRequired": true, "valueName": "search_term_string" } } }
    </component>
  </teleport>
</template>

<script setup>
import { computed, provide, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useCalculatorStore } from '../stores/calculatorStore'
import { useCampaignStore } from '../stores/campaignStore'

const campaignStore = useCampaignStore()
const calculatorStore = useCalculatorStore()

const showLoadingIndicator = ref(false)
provide('showLoadingIndicator', showLoadingIndicator)

const showNavigation = computed(() => {
  return campaignStore.getShowNavigation
})

const footerClasses = computed(() => {
  return {
    'footer-cart-annual-fee':
      calculatorStore.getShowAnnualFee && calculatorStore.getShowCart,
    'footer-cart-visible': calculatorStore.getShowCart,
  }
})

const route = useRoute()
// const showFooter = ref(route.meta.hasViewReady ? false : true)
const showFooter = ref(true)

function onViewReady() {
  showFooter.value = true
}

</script>

<script>
import { RouterView } from 'vue-router'
// import CookieModule from '../components/modules/CookieModule.vue'

export default {
  components: {
    RouterView,
    // CookieModule,
  },

  data() {
    return {
      productPages: [
        'utasbiztositas',
        'balesetbiztositas',
        'lakasbiztositas',
        'lakasEloszto',
        'lakasbiztositasmfo',
        'minicasco',
        'lopascasco',
      ],

      vehiclePages: ['kgfb', 'casco'],

      calculatorPages: ['lakasbiztositascalculatorPOC'],

      paymentPages: ['BankkartyasDijfizetesEgyenlegView'],
      menustate: false,
      cookieAccepted: false,
      showDebug: false,
    }
  },

  // created() {
  //   const $q = useQuasar()
  //   this.cookieAccepted = $q.cookies.get('genertel-cookie-law')
  // },

  methods: {
    preventScroll(value) {
      this.menustate = value
      if (value) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    },

    // setCookie(val) {
    //   this.cookieAccepted = val
    // },
  },
}
</script>
