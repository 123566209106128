<template>
  <div class="container-xxl">
    <div class="minicalculator-holder">
      <div class="minicalculator-title-holder">
        <Title
          htag="h2"
          class="small"
        >
          {{ miniKalkulatorConfigs.title }}
        </Title>
        <div class="minicalculator-title-tooltip-holder">
          <Tooltip
            :icon-size="24"
            :tooltip-content="miniKalkulatorConfigs.tooltip"
          />
        </div>
      </div>
      <ul
        id="pills-tablet-minicalculator"
        class="nav nav-pills"
        role="tablist"
      >
        <li
          v-for="(
            minikalkulator, index
          ) in miniKalkulatorConfigs.calculators.filter(
            (calculator) =>
              calculator.order > 0 && calculator.isVisibleOnHomePage
          )"
          :key="minikalkulator.order"
          class="nav-item col"
          role="presentation"
        >
          <button
            :id="'pills-tablet-' + minikalkulator.type + '-tab'"
            class="nav-link"
            :class="index === 0 ? 'active' : ''"
            data-bs-toggle="pill"
            :data-bs-target="'#pills-tablet-' + minikalkulator.type"
            type="button"
            role="tab"
            :aria-controls="'#pills-tablet-' + minikalkulator.type"
            :aria-selected="index === 0 ? true : false"
            @click="setActiveType(minikalkulator.type)"
          >
            <div class="btn-icon">
              <PhIconManager :ph-icon="`ph-${minikalkulator.icon}`" />
            </div>
            <Title
              htag="h2"
              class="btn-title"
            >
              {{ minikalkulator.title }}
            </Title>
            <div
              v-if="
                minikalkulator.discountLink &&
                  (minikalkulator.discountLink.internalLink ||
                    minikalkulator.discountLink.externalLink)
              "
              class="btn-tag bg-purple text-white"
            >
              <PhIconManager
                :ph-icon="`ph-${minikalkulator.discountIcon}`"
                :size="20"
              />
            </div>
          </button>
        </li>
      </ul>
      <div
        id="pills-tablet-tabContent"
        class="tab-content"
      >
        <template
          v-for="minikalkulator in miniKalkulatorConfigs.calculators.filter(
            (calculator) => calculator.order > 0
          )"
          :key="minikalkulator.order"
        >
          <div
            v-if="minikalkulator.type === 'baleset'"
            :id="'pills-tablet-' + minikalkulator.type"
            class="tab-pane fade"
            :class="
              miniKalkulatorDefaultSelectedType === 'baleset'
                ? 'show active'
                : ''
            "
            role="tabpanel"
            :aria-labelledby="'pills-tablet-' + minikalkulator.type + '-tab'"
          >
            <Row12>
              <div
                v-if="
                  minikalkulator.discountLink &&
                    (minikalkulator.discountLink.internalLink ||
                      minikalkulator.discountLink.externalLink)
                "
                class="tag-long w-auto bg-purple"
                @click="clickInnerNavigableLink"
              >
                <div class="icon-holder bg-white">
                  <PhIconManager
                    :ph-icon="`ph-${minikalkulator.discountIcon}`"
                    :size="20"
                    color="purple"
                  />
                </div>
                <NavigableLink
                  :link-object="minikalkulator.discountLink"
                  class="tag-text text-white text-bold t-normal"
                >
                  {{ minikalkulator.discountLink.title }}
                </NavigableLink>
                <PhIconManager
                  ph-icon="ph-arrow-right"
                  :size="16"
                  color="white"
                />
              </div>
            </Row12>
            <q-form ref="balesetFormTablet">
              <Row444 class="mb-2">
                <template #first>
                  <SelectInput
                    id="MINI_100"
                    v-model:model-value="balesetFormModel.szerzodoNeme"
                    :options="balesetFormModel.szerzodoNemeOpciok"
                    :label="
                      balesetCalculator.fields.find(
                        (field) => field.name === 'MINI_100'
                      ).label
                    "
                    :tooltip-content="
                      balesetCalculator.fields.find(
                        (field) => field.name === 'MINI_100'
                      ).tooltip
                    "
                    validation-rules="VAL_100_SELECT"
                  />
                </template>
                <template #second>
                  <TextInput
                    id="MINI_101"
                    :key="MINI_101crossFieldValidation"
                    v-model:model-value="balesetFormModel.felnottDarab"
                    :label="
                      balesetCalculator.fields.find(
                        (field) => field.name === 'MINI_101'
                      ).label
                    "
                    :tooltip-content="
                      balesetCalculator.fields.find(
                        (field) => field.name === 'MINI_101'
                      ).tooltip
                    "
                    :init-input-value="balesetFormModel.felnottDarab"

                    :validation-rules="MINI_101crossFieldValidation"
                    :max-number="10"
                    :min-number="0"
                    input-type="accident-number"
                  />
                </template>
                <template #third>
                  <TextInput
                    id="MINI_102"
                    :key="MINI_102key"
                    v-model:model-value="balesetFormModel.gyermekDarab"
                    mask="#"
                    :label="
                      balesetCalculator.fields.find(
                        (field) => field.name === 'MINI_102'
                      ).label
                    "
                    :tooltip-content="
                      balesetCalculator.fields.find(
                        (field) => field.name === 'MINI_102'
                      ).tooltip
                    "
                    :init-input-value="balesetFormModel.gyermekDarab"

                    :validation-rules="MINI_102crossFieldValidation"
                    :max-number="9"
                    :min-number="0"
                  />
                </template>
              </Row444>
              <Row12 class="btn-row">
                <q-btn
                  class="btn btn-secondary btn-dense gtmMainPageCalculationStartBaleset"
                  @click="validate('balesetFormTablet')"
                >
                  {{ balesetCalculator.calculateLink.title }}
                </q-btn>
                <NavigableLink
                  :link-object="balesetCalculator.productLink"
                  class="text-secondary"
                >
                  {{ balesetCalculator.productLink.title }}
                </NavigableLink>
              </Row12>
            </q-form>
          </div>

          <div
            v-if="minikalkulator.type === 'utas'"
            :id="'pills-tablet-' + minikalkulator.type"
            class="tab-pane fade"
            :class="
              miniKalkulatorDefaultSelectedType === 'utas' ? 'show active' : ''
            "
            role="tabpanel"
            :aria-labelledby="'pills-tablet-' + minikalkulator.type + '-tab'"
          >
            <Row12>
              <div
                v-if="
                  minikalkulator.discountLink &&
                    (minikalkulator.discountLink.internalLink ||
                      minikalkulator.discountLink.externalLink)
                "
                class="tag-long w-auto bg-purple"
                @click="clickInnerNavigableLink"
              >
                <div class="icon-holder bg-white">
                  <PhIconManager
                    :ph-icon="`ph-${minikalkulator.discountIcon}`"
                    :size="20"
                    color="purple"
                  />
                </div>
                <NavigableLink
                  :link-object="minikalkulator.discountLink"
                  class="tag-text text-white text-bold t-normal"
                >
                  {{ minikalkulator.discountLink.title }}
                </NavigableLink>
                <PhIconManager
                  ph-icon="ph-arrow-right"
                  :size="16"
                  color="white"
                />
              </div>
            </Row12>
            <q-form ref="utasFormTablet">
              <Row444 class="mb-2">
                <template #first>
                  <SelectInput
                    id="MINI_114"
                    v-model:model-value="utasFormModel.utazasOka"
                    :options="utasFormModel.utazasOkaOpciok"
                    :label="
                      utasCalculator.fields.find(
                        (field) => field.name === 'MINI_114'
                      ).label
                    "
                    :tooltip-content="
                      utasCalculator.fields.find(
                        (field) => field.name === 'MINI_114'
                      ).tooltip
                    "
                    validation-rules="VAL_100_SELECT"
                  />
                </template>
                <template #second>
                  <SelectInput
                    id="MINI_115"
                    v-model:model-value="utasFormModel.utazasiJarmu"
                    :options="utasFormModel.utazasiJarmuOpciok"
                    :label="
                      utasCalculator.fields.find(
                        (field) => field.name === 'MINI_115'
                      ).label
                    "
                    :tooltip-content="
                      utasCalculator.fields.find(
                        (field) => field.name === 'MINI_115'
                      ).tooltip
                    "
                    validation-rules="VAL_100_SELECT"
                  />
                </template>
                <template #third>
                  <div id="destination">
                    <SelectInput
                      id="MINI_116"
                      v-model:model-value="utasFormModel.uticel"
                      :options="destinationOptions"
                      :label="
                        utasCalculator.fields.find(
                          (field) => field.name === 'MINI_116'
                        ).label
                      "
                      :tooltip-content="
                        utasCalculator.fields.find(
                          (field) => field.name === 'MINI_116'
                        ).tooltip
                      "
                      validation-rules="VAL_100_SELECT"
                      is-autocomplete
                      is-destination-input
                    />
                  </div>
                </template>
              </Row444>
              <Row12 class="btn-row">
                <q-btn
                  class="btn btn-secondary btn-dense gtmMainPageCalculationStartUtas"
                  @click="validate('utasFormTablet')"
                >
                  {{ utasCalculator.calculateLink.title }}
                </q-btn>
                <NavigableLink
                  :link-object="utasCalculator.productLink"
                  class="text-secondary"
                >
                  {{ utasCalculator.productLink.title }}
                </NavigableLink>
              </Row12>
            </q-form>
          </div>
          <div
            v-if="minikalkulator.type === 'lakaseloszto'"
            :id="'pills-tablet-' + minikalkulator.type"
            class="tab-pane fade"
            :class="
              miniKalkulatorDefaultSelectedType === 'lakaseloszto'
                ? 'show active'
                : ''
            "
            role="tabpanel"
            :aria-labelledby="'pills-tablet-' + minikalkulator.type + '-tab'"
          >
            <Row12>
              <div
                v-if="
                  minikalkulator.discountLink &&
                    (minikalkulator.discountLink.internalLink ||
                      minikalkulator.discountLink.externalLink)
                "
                class="tag-long w-auto bg-purple"
                @click="clickInnerNavigableLink"
              >
                <div class="icon-holder bg-white">
                  <PhIconManager
                    :ph-icon="`ph-${minikalkulator.discountIcon}`"
                    :size="20"
                    color="purple"
                  />
                </div>
                <NavigableLink
                  :link-object="minikalkulator.discountLink"
                  class="tag-text text-white text-bold t-normal"
                >
                  {{ minikalkulator.discountLink.title }}
                </NavigableLink>
                <PhIconManager
                  ph-icon="ph-arrow-right"
                  :size="16"
                  color="white"
                />
              </div>
            </Row12>
            <q-form ref="lakasFormTablet">
              <Row444 class="mb-2">
                <template #first>
                  <SelectInput
                    id="MINI_103"
                    v-model:model-value="lakasFormModel.biztositasTargya"
                    :options="lakasFormModel.biztositastargyaOpciok"
                    :label="
                      lakasCalculator.fields.find(
                        (field) => field.name === 'MINI_103'
                      ).label
                    "
                    :tooltip-content="
                      lakasCalculator.fields.find(
                        (field) => field.name === 'MINI_103'
                      ).tooltip
                    "
                    validation-rules="VAL_100_SELECT"
                  />
                </template>
                <template #second>
                  <SelectInput
                    id="MINI_104"
                    v-model:model-value="lakasFormModel.ingatlanTipusa"
                    :options="lakasFormModel.ingatlantipusaOpciok"
                    :label="
                      lakasCalculator.fields.find(
                        (field) => field.name === 'MINI_104'
                      ).label
                    "
                    :tooltip-content="
                      lakasCalculator.fields.find(
                        (field) => field.name === 'MINI_104'
                      ).tooltip
                    "
                    validation-rules="VAL_100_SELECT"
                  />
                </template>
                <template #third>
                  <ZipCodeInput
                    id="MINI_105"
                    v-model:model-value="lakasFormModel.iranyitoSzam"
                    :label="
                      lakasCalculator.fields.find(
                        (field) => field.name === 'MINI_105'
                      ).label
                    "
                    :tooltip-content="
                      lakasCalculator.fields.find(
                        (field) => field.name === 'MINI_105'
                      ).tooltip
                    "
                    validation-rules="VAL_100_SELECT|VAL_111"
                  />
                </template>
              </Row444>
              <Row12 class="btn-row">
                <q-btn
                  class="btn btn-secondary btn-dense gtmMainPageCalculationStartLakas"
                  @click="validate('lakasFormTablet')"
                >
                  {{ lakasCalculator.calculateLink.title }}
                </q-btn>
                <NavigableLink
                  :link-object="lakasCalculator.productLink"
                  class="text-secondary"
                >
                  {{ lakasCalculator.productLink.title }}
                </NavigableLink>
              </Row12>
            </q-form>
          </div>

          <div
            v-if="minikalkulator.type === 'kotelezo'"
            :id="'pills-tablet-' + minikalkulator.type"
            class="tab-pane fade"
            :class="
              miniKalkulatorDefaultSelectedType === 'kotelezo'
                ? 'show active'
                : ''
            "
            role="tabpanel"
            :aria-labelledby="'pills-tablet-' + minikalkulator.type + '-tab'"
          >
            <Row12>
              <div
                v-if="
                  minikalkulator.discountLink &&
                    (minikalkulator.discountLink.internalLink ||
                      minikalkulator.discountLink.externalLink)
                "
                class="tag-long w-auto bg-purple"
                @click="clickInnerNavigableLink"
              >
                <div class="icon-holder bg-white">
                  <PhIconManager
                    :ph-icon="`ph-${minikalkulator.discountIcon}`"
                    :size="20"
                    color="purple"
                  />
                </div>
                <NavigableLink
                  :link-object="minikalkulator.discountLink"
                  class="tag-text text-white text-bold t-normal"
                >
                  {{ minikalkulator.discountLink.title }}
                </NavigableLink>
                <PhIconManager
                  ph-icon="ph-arrow-right"
                  :size="16"
                  color="white"
                />
              </div>
            </Row12>
            <q-form ref="kotelezoFormTablet">
              <Row66 class="mb-2">
                <template #first>
                  <SelectInput
                    id="MINI_106"
                    v-model:model-value="kotelezoFormModel.szerzodeskotesOka"
                    :options="kotelezoFormModel.szerzodeskotesOkaOpciok"
                    :label="
                      kotelezoCalculator.fields.find(
                        (field) => field.name === 'MINI_106'
                      ).label
                    "
                    :tooltip-content="
                      kotelezoCalculator.fields.find(
                        (field) => field.name === 'MINI_106'
                      ).tooltip
                    "
                    validation-rules="VAL_100_SELECT"
                  />
                </template>
                <template #second>
                  <TextInput
                    id="MINI_107"
                    :key="kotelezoFormModel.frszNincs"
                    v-model:model-value="kotelezoFormModel.rendszam"
                    :is-disabled="kotelezoFormModel.frszNincs === true"
                    :label="
                      kotelezoCalculator.fields.find(
                        (field) => field.name === 'MINI_107'
                      ).label
                    "
                    :tooltip-content="
                      kotelezoCalculator.fields.find(
                        (field) => field.name === 'MINI_107'
                      ).tooltip
                    "
                    :validation-rules="
                      kotelezoFormModel.frszNincs === true
                        ? ''
                        : 'VAL_100|VAL_191|VAL_345|VAL_346'
                    "
                    :max-length="8"
                  />
                </template>
              </Row66>
              <Row12>
                <CheckboxInput
                  v-if="kotelezoFormModel.szerzodeskotesOka === '0'"
                  id="MINI_117"
                  v-model="kotelezoFormModel.frszNincs"
                  :tooltip-content="
                    kotelezoCalculator.fields.find(
                      (field) => field.name === 'MINI_117'
                    ).tooltip
                  "
                >
                  {{ kotelezoCalculator.fields.find(
                    (field) => field.name === 'MINI_117'
                  ).label }}
                </CheckboxInput>
              </Row12>
              <Row12 class="btn-row">
                <q-btn
                  class="btn btn-secondary btn-dense gtmMainPageCalculationStartKotelezo"
                  @click="validate('kotelezoFormTablet')"
                >
                  {{ minikalkulator.calculateLink.title }}
                </q-btn>
                <NavigableLink
                  :link-object="minikalkulator.productLink"
                  class="text-secondary"
                >
                  {{ minikalkulator.productLink.title }}
                </NavigableLink>
              </Row12>
            </q-form>
          </div>

          <div
            v-if="minikalkulator.type === 'casco'"
            :id="'pills-tablet-' + minikalkulator.type"
            class="tab-pane fade"
            :class="
              miniKalkulatorDefaultSelectedType === 'casco' ? 'show active' : ''
            "
            role="tabpanel"
            :aria-labelledby="'pills-tablet-' + minikalkulator.type + '-tab'"
          >
            <Row12
              v-if="
                activeCascoCalculator &&
                  activeCascoCalculator.discountLink &&
                  (activeCascoCalculator.discountLink.internalLink ||
                    activeCascoCalculator.discountLink.externalLink)
              "
            >
              <div
                class="tag-long w-auto bg-purple"
                @click="clickInnerNavigableLink"
              >
                <div class="icon-holder bg-white">
                  <PhIconManager
                    :ph-icon="`ph-${activeCascoCalculator.discountIcon}`"
                    :size="20"
                    color="purple"
                  />
                </div>
                <NavigableLink
                  :link-object="activeCascoCalculator.discountLink"
                  class="tag-text text-white text-bold t-normal"
                >
                  {{ activeCascoCalculator.discountLink.title }}
                </NavigableLink>
                <PhIconManager
                  ph-icon="ph-arrow-right"
                  :size="16"
                  color="white"
                />
              </div>
            </Row12>
            <q-tabs
              v-model="tab"
              dense
              class="text-dark-300 text-left justify-content-start"
              active-color="dark"
              indicator-color="dark"
            >
              <q-tab
                class="col"
                name="casco"
              >
                <Title
                  htag="h3"
                  class="q-tab__label mb-0"
                >
                  {{ cascoCalculator.title }}
                </Title>
                <Tooltip :tooltip-content="cascoCalculator.homePageTooltip" />
              </q-tab>

              <q-tab
                class="col"
                name="minicasco"
              >
                <Title
                  htag="h3"
                  class="q-tab__label mb-0"
                >
                  {{ miniCascoCalculator.title }}
                </Title>
                <Tooltip :tooltip-content="miniCascoCalculator.homePageTooltip" />
              </q-tab>
              <q-tab
                class="col"
                name="lopascasco"
              >
                <Title
                  htag="h3"
                  class="q-tab__label mb-0"
                >
                  {{ lopasCascoCalculator.title }}
                </Title>
                <Tooltip :tooltip-content="lopasCascoCalculator.homePageTooltip" />
              </q-tab>
            </q-tabs>

            <q-tab-panels
              v-model="tab"
              animated
            >
              <q-tab-panel name="casco">
                <q-form ref="cascoFormTablet">
                  <Row444 class="mb-2">
                    <template #first>
                      <SelectInput
                        id="MINI_108"
                        v-model:model-value="cascoFormModel.szerzodeskotesOka"
                        :options="cascoFormModel.szerzodeskotesOkaOpciok"
                        :label="
                          cascoCalculator.fields.find(
                            (field) => field.name === 'MINI_108'
                          ).label
                        "
                        :tooltip-content="
                          cascoCalculator.fields.find(
                            (field) => field.name === 'MINI_108'
                          ).tooltip
                        "
                        validation-rules="VAL_100_SELECT"
                      />
                    </template>
                    <template #second>
                      <TextInput
                        id="MINI_107"
                        :key="cascoFormModel.frszNincs"
                        v-model:model-value="cascoFormModel.rendszam"
                        :is-disabled="cascoFormModel.frszNincs === true"
                        :label="
                          cascoCalculator.fields.find(
                            (field) => field.name === 'MINI_107'
                          ).label
                        "
                        :tooltip-content="
                          cascoCalculator.fields.find(
                            (field) => field.name === 'MINI_107'
                          ).tooltip
                        "
                        :validation-rules="
                          cascoFormModel.frszNincs === true
                            ? ''
                            : 'VAL_100|VAL_191|VAL_345|VAL_346'
                        "
                        :max-length="8"
                      />
                    </template>
                    <template #third>
                      <SelectInput
                        id="MINI_109"
                        v-model:model-value="cascoFormModel.felhasznalasmodja"
                        :options="cascoFormModel.felhasznalasmodjaOpciok"
                        :label="
                          cascoCalculator.fields.find(
                            (field) => field.name === 'MINI_109'
                          ).label
                        "
                        :tooltip-content="
                          cascoCalculator.fields.find(
                            (field) => field.name === 'MINI_109'
                          ).tooltip
                        "
                        validation-rules="VAL_100_SELECT|VAL_238_SELECT"
                      />
                    </template>
                  </Row444>
                  <Row12 v-if="cascoFormModel.szerzodeskotesOka === '1'">
                    <CheckboxInput
                      id="MINI_117"
                      v-model="cascoFormModel.frszNincs"
                      :tooltip-content="
                        cascoCalculator.fields.find(
                          (field) => field.name === 'MINI_117'
                        ).tooltip
                      "
                    >
                      {{ cascoCalculator.fields.find(
                        (field) => field.name === 'MINI_117'
                      ).label }}
                    </CheckboxInput>
                  </Row12>
                  <Row12 class="btn-row">
                    <q-btn
                      class="btn btn-secondary btn-dense gtmMainPageCalculationStartCasco"
                      @click="validate('cascoFormTablet')"
                    >
                      {{ cascoCalculator.calculateLink.title }}
                    </q-btn>
                    <NavigableLink
                      :link-object="cascoCalculator.productLink"
                      class="text-secondary"
                    >
                      {{ cascoCalculator.productLink.title }}
                    </NavigableLink>
                  </Row12>
                </q-form>
              </q-tab-panel>

              <q-tab-panel name="minicasco">
                <q-form ref="miniCascoFormTablet">
                  <Row444 class="mb-2">
                    <template #first>
                      <SelectInput
                        id="MINI_110"
                        v-model:model-value="
                          miniCascoFormModel.szerzodoErdekeltseg
                        "
                        :options="miniCascoFormModel.szerzodoErdekeltsegOpciok"
                        :label="
                          miniCascoCalculator.fields.find(
                            (field) => field.name === 'MINI_110'
                          ).label
                        "
                        :tooltip-content="
                          miniCascoCalculator.fields.find(
                            (field) => field.name === 'MINI_110'
                          ).tooltip
                        "
                        validation-rules="VAL_100_SELECT"
                      />
                    </template>
                    <template #second>
                      <SelectInput
                        id="MINI_111"
                        v-model:model-value="miniCascoFormModel.forgalmiNev"
                        :options="miniCascoFormModel.forgalmiNevOpciok"
                        :label="
                          miniCascoCalculator.fields.find(
                            (field) => field.name === 'MINI_111'
                          ).label
                        "
                        :tooltip-content="
                          miniCascoCalculator.fields.find(
                            (field) => field.name === 'MINI_111'
                          ).tooltip
                        "
                        validation-rules="VAL_100_SELECT"
                      />
                    </template>
                    <template #third>
                      <SelectInput
                        id="MINI_112"
                        v-model:model-value="miniCascoFormModel.elozoKgfb"
                        :options="miniCascoFormModel.elozoKgfbOpciok"
                        :label="
                          miniCascoCalculator.fields.find(
                            (field) => field.name === 'MINI_112'
                          ).label
                        "
                        :tooltip-content="
                          miniCascoCalculator.fields.find(
                            (field) => field.name === 'MINI_112'
                          ).tooltip
                        "
                        validation-rules="VAL_100_SELECT"
                      />
                    </template>
                  </Row444>
                  <Row12 class="btn-row">
                    <q-btn
                      class="btn btn-secondary btn-dense gtmMainPageCalculationStartMiniCasco"
                      @click="validate('miniCascoFormTablet')"
                    >
                      {{ miniCascoCalculator.calculateLink.title }}
                    </q-btn>
                    <NavigableLink
                      :link-object="miniCascoCalculator.productLink"
                      class="text-secondary"
                    >
                      {{ miniCascoCalculator.productLink.title }}
                    </NavigableLink>
                  </Row12>
                </q-form>
              </q-tab-panel>

              <q-tab-panel name="lopascasco">
                <q-form ref="lopasCascoFormTablet">
                  <Row444 class="mb-2">
                    <template #first>
                      <SelectInput
                        id="MINI_110"
                        v-model:model-value="
                          lopasCascoFormModel.szerzodoErdekeltseg
                        "
                        :options="lopasCascoFormModel.szerzodoErdekeltsegOpciok"
                        :label="
                          lopasCascoCalculator.fields.find(
                            (field) => field.name === 'MINI_110'
                          ).label
                        "
                        :tooltip-content="
                          lopasCascoCalculator.fields.find(
                            (field) => field.name === 'MINI_110'
                          ).tooltip
                        "
                        validation-rules="VAL_100_SELECT"
                      />
                    </template>
                    <template #second>
                      <SelectInput
                        id="MINI_111"
                        v-model:model-value="lopasCascoFormModel.forgalmiNev"
                        :options="lopasCascoFormModel.forgalmiNevOpciok"
                        :label="
                          lopasCascoCalculator.fields.find(
                            (field) => field.name === 'MINI_111'
                          ).label
                        "
                        :tooltip-content="
                          lopasCascoCalculator.fields.find(
                            (field) => field.name === 'MINI_111'
                          ).tooltip
                        "
                        validation-rules="VAL_100_SELECT"
                      />
                    </template>
                    <template #third>
                      <SelectInput
                        id="MINI_113"
                        v-model:model-value="
                          lopasCascoFormModel.gepjarmuFajtaja
                        "
                        :options="lopasCascoFormModel.gepjarmuFajtajaOpciok"
                        :label="
                          lopasCascoCalculator.fields.find(
                            (field) => field.name === 'MINI_113'
                          ).label
                        "
                        :tooltip-content="
                          lopasCascoCalculator.fields.find(
                            (field) => field.name === 'MINI_113'
                          ).tooltip
                        "
                        validation-rules="VAL_100_SELECT"
                      />
                    </template>
                  </Row444>
                  <Row12 class="btn-row">
                    <q-btn
                      class="btn btn-secondary btn-dense gtmMainPageCalculationStartLopasCasco"
                      @click="validate('lopasCascoFormTablet')"
                    >
                      {{ lopasCascoCalculator.calculateLink.title }}
                    </q-btn>
                    <NavigableLink
                      :link-object="lopasCascoCalculator.productLink"
                      class="text-secondary"
                    >
                      {{ lopasCascoCalculator.productLink.title }}
                    </NavigableLink>
                  </Row12>
                </q-form>
              </q-tab-panel>
            </q-tab-panels>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, inject,computed } from 'vue'
import RadioInput from '../inputs/RadioInput.vue'
import optionValues from '../../utils/minicalculatorOptionsValues/optionValues'
import { useTravelDestionationsStore } from '../../stores/travelDestionationsStore'
import { useRouter } from 'vue-router'
import axios from 'axios'
import { focusFirstValidation } from '../../utils/validations/validationFocus'
import { useMiniCalculatorHelperMixin } from "../../mixins/miniCalculatorHelperMixin"

const props = defineProps({
  minicalculatorData: {
    type: Object,
    required: true,
  },
})

const toFormRefs = (refs) => ({
  ...refs,
  formRefs: {
    ...refs,
  },
})

const {
  pushDataLayerAndRedirectBaleset,
  pushDataLayerAndRedirectLakas,
  pushDataLayerAndRedirectUtas,
  pushDataLayerAndRedirectKotelezo,
  pushDataLayerAndRedirectCasco,
  pushDataLayerAndRedirectMiniCasco,
  pushDataLayerAndRedirectLopasCasco
} = useMiniCalculatorHelperMixin();

const MINI_101crossFieldValidation = computed(() => {
    return `VAL_100|VAL_128|VAL_178|VAL_ACCIDENT_MAX:${balesetFormModel.value.gyermekDarab}`
})
const MINI_102crossFieldValidation = computed(() => {
    return `VAL_100|VAL_322|VAL_ACCIDENT_MAX:${balesetFormModel.value.felnottDarab}`
})

const MINI_102key = computed(() => {
  return balesetFormModel.value.gyermekDarab === null ? balesetFormModel.value.gyermekDarab : balesetFormModel.value.gyermekDarab+balesetFormModel.value.felnottDarab
})

const travelStore = useTravelDestionationsStore()

const destinationOptions = computed(() => {
  return travelStore.getDestinations
})
const balesetFormTablet = ref(null)
const cascoFormTablet = ref(null)
const lopasCascoFormTablet = ref(null)
const miniCascoFormTablet = ref(null)
const kotelezoFormTablet = ref(null)
const lakasFormTablet = ref(null)
const utasFormTablet = ref(null)
const activeCascoCalculator = ref(null)

const router = useRouter()

const balesetFormModel = ref(optionValues.getAccidentValues())

const lakasFormModel = ref(optionValues.getHomeValues())

const kotelezoFormModel = ref(optionValues.getKgfbValues())

const utasFormModel = ref(optionValues.getTravelValues())

const cascoFormModel = ref(optionValues.getCascoValues())

const miniCascoFormModel = ref(optionValues.getMinicascoValues())

const lopasCascoFormModel = ref(optionValues.getTheftCasco())

const miniKalkulatorConfigs = ref(props.minicalculatorData)

const balesetCalculator = miniKalkulatorConfigs.value.calculators.find(
  (kalkulator) => kalkulator.type === 'baleset'
)
const kotelezoCalculator = miniKalkulatorConfigs.value.calculators.find(
  (kalkulator) => kalkulator.type === 'kotelezo'
)
const cascoCalculator = miniKalkulatorConfigs.value.calculators.find(
  (kalkulator) => kalkulator.type === 'casco'
)
const utasCalculator = miniKalkulatorConfigs.value.calculators.find(
  (kalkulator) => kalkulator.type === 'utas'
)
const miniCascoCalculator = miniKalkulatorConfigs.value.calculators.find(
  (kalkulator) => kalkulator.type === 'minicasco'
)
const lopasCascoCalculator = miniKalkulatorConfigs.value.calculators.find(
  (kalkulator) => kalkulator.type === 'lopascasco'
)
const lakasCalculator = miniKalkulatorConfigs.value.calculators.find(
  (kalkulator) => kalkulator.type === 'lakaseloszto'
)

function miniKalkulatorConfigDataSortComparer(a, b) {
  if (a.order < b.order) {
    return -1
  }
  if (a.order > b.order) {
    return 1
  }
  return 0
}
const consentReadMore = true

function showText() {
  this.consentReadMore = !this.consentReadMore
}

function toggleState(e) {
  if (e) {
    e.preventDefault()
    e.stopPropagation()
  }
}
function setActiveType(type) {
  if (type === 'utas') {
    travelStore.setTravelDestinations()
  }
}

miniKalkulatorConfigs.value.calculators.sort(
  miniKalkulatorConfigDataSortComparer
)
const miniKalkulatorDefaultSelectedType = ref(
  miniKalkulatorConfigs.value.calculators.filter(
    (calculator) => calculator.order > 0
  )[0].type
)

onMounted(() => {
  if (
    cascoCalculator.discountLink &&
    (cascoCalculator.discountLink.internalLink ||
      cascoCalculator.discountLink.externalLink)
  ) {
    setCascoCalculator(cascoCalculator)
  }
})

function clickInnerNavigableLink(element) {
  const currentTarget = element.currentTarget
  const navigableLink = currentTarget.getElementsByClassName('tag-text text-white text-bold t-normal')
  navigableLink[0].click()
}

function validate(name) {
  switch (name) {
    case 'lakasFormTablet':
      lakasFormTablet.value[0].validate().then(async (success) => {
        if (success) {
          pushDataLayerAndRedirectLakas(lakasCalculator, lakasFormModel);
        }
      })
      break
    case 'balesetFormTablet':
      balesetFormTablet.value[0].validate().then((success) => {
        if (success) {
          pushDataLayerAndRedirectBaleset(balesetCalculator, balesetFormModel)
        }
      })
      break
    case 'utasFormTablet':
      utasFormTablet.value[0].validate().then((success) => {
        if (success) {
          pushDataLayerAndRedirectUtas(utasCalculator, utasFormModel, destinationOptions)
        }
      })
      break
    case 'kotelezoFormTablet':
      kotelezoFormTablet.value[0].validate().then((success) => {
        if (success) {
          pushDataLayerAndRedirectKotelezo(kotelezoCalculator, kotelezoFormModel)
        }
      })
      break
    case 'cascoFormTablet':
      cascoFormTablet.value[0].validate().then((success) => {
        if (success) {
          pushDataLayerAndRedirectCasco(cascoCalculator, cascoFormModel)
        }
      })
      break
    case 'miniCascoFormTablet':
      miniCascoFormTablet.value[0].validate().then((success) => {
        if (success) {
          pushDataLayerAndRedirectMiniCasco(miniCascoCalculator, miniCascoFormModel)
        }
      })
      break
    case 'lopasCascoFormTablet':
      lopasCascoFormTablet.value[0].validate().then((success) => {
        if (success) {
          pushDataLayerAndRedirectLopasCasco(lopasCascoCalculator, lopasCascoFormModel)
        }
      })
      break

    default:
      console.log('form invalid')
      alert('Váratlan hiba történt!')
      throw new Error(form)
  }

  focusFirstValidation();
}

const nyilatkozatOptions = ref([
  {
    label: miniKalkulatorConfigs.value.calculators
      .find((kalkulator) => kalkulator.type === 'lakaseloszto')
      .fields.find((field) => field.name === 'MINI_122').label,

    value: true,
  },
  {
    label: miniKalkulatorConfigs.value.calculators
      .find((kalkulator) => kalkulator.type === 'lakaseloszto')
      .fields.find((field) => field.name === 'MINI_123').label,

    value: false,
  },
])

function setCascoCalculator(calculator) {
  activeCascoCalculator.value = calculator
}

const tab = ref('casco')

watch(tab, (newValue, oldValue) => {
  if (
    newValue === 'casco'
  ) {
    setCascoCalculator(cascoCalculator)
  } else if (
    newValue === 'minicasco'
  ) {
    setCascoCalculator(miniCascoCalculator)
  } else if (
    newValue === 'lopascasco'
  ) {
    setCascoCalculator(lopasCascoCalculator)
  }

})

watch(
  () => kotelezoFormModel.value.frszNincs,
  (newValue) => {
    if (!newValue) {
      kotelezoFormModel.value.rendszam = null
    }
  }
)

watch(
  () => kotelezoFormModel.value.szerzodeskotesOka,
  (newValue) => {
    if (newValue !== '0' && kotelezoFormModel.value.frszNincs) {
      kotelezoFormModel.value.frszNincs = false
    }
  }
)

watch(
  () => cascoFormModel.value.frszNincs,
  (newValue) => {
    if (!newValue) {
      cascoFormModel.value.rendszam = null
    }
  }
)

watch(
  () => cascoFormModel.value.szerzodeskotesOka,
  (newValue) => {
    if (newValue !== '1' && cascoFormModel.value.frszNincs) {
      cascoFormModel.value.frszNincs = false
    }
  }
)

</script>

<script>
export default {
  name: 'TabletMinicalculator',
}
</script>
<style lang="scss" scoped>
.container-xxl {
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
}

.minicalculator-holder {
  margin-top: 7rem;
  .minicalculator-title-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
    .minicalculator-title-tooltip-holder {
      margin-bottom: 2rem;
    }
  }
}

#destination .q-field__label.no-pointer-events.absolute.ellipsis{
  position: absolute;
}
@media screen and (max-width: $breakpoint-lg-max) {
  .minicalculator-holder {
    width: 100% !important;
    margin: 2.5rem 0;
  }
}

@media screen and (max-width: $breakpoint-sm-max) {
  .minicalculator-holder {
    margin: 2rem 0rem;
    padding: 1rem 0;
  }
}

:deep(.minicalculator-holder) {
  width: 50.875rem;
  background: linear-gradient(121.27deg, #fafbfc 31.25%, #faf7f4 100%);
  border-radius: $normal;
  padding: 2.5rem;
  color: $dark;
  box-shadow: $box-shadow-md;
  & > .minicalculator-title-holder > h2.small {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 2rem;
  }

  .q-tabs__content.row.no-wrap.items-center.self-stretch.hide-scrollbar.relative-position.q-tabs__content--align-center.scroll {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    width: auto;
  }

  ul#pills-tablet-minicalculator {
    background: #e3e2ea;
    box-shadow: inset 0rem 0rem 0.25rem rgb(0 0 0 / 2%);
    border-radius: 0.75rem;
    padding: $small;
    margin-bottom: $double;

    .col {
      margin: 0;
      padding: 0;
      .nav-link {
        background-color: #ecebef;
        border-radius: 0.625rem;
        padding: $half;
        width: 100%;
        color: $dark;
        position: relative;
        h2 {
          color: $dark;
          font-size: $font-size-md;
          line-height: $line-height-md;
          text-transform: uppercase;
          font-weight: $font-weight-medium;
          margin-bottom: 0;
        }
        &.active {
          background-color: white;
          color: $secondary;
          box-shadow: 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.06),
            0rem 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
        }
        .btn-icon {
          margin-bottom: $half;
        }
        .btn-tag {
          border-radius: 100%;
          width: 1.5rem;
          height: 1.5rem;
          position: absolute;
          top: $half;
          right: $half;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
      }
      &.nav-item {
        margin-right: $small;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .tag-long {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: $small 0.75rem $small $small;
    border-radius: 0.75rem;
    max-width: fit-content;
    margin-bottom: $double;
    .icon-holder {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: $normal;
    }
    .tag-text {
      margin-right: $normal;
    }

    &:hover {
      background: #811d8a !important;
    }
  }

  .btn-row {
    .btn-dense {
      padding: 0.75rem 1.25rem;
      box-shadow: 0rem 0.625rem 0.9375rem rgba(78, 149, 251, 0.1);
      border-radius: 0.75rem;
      font-size: $normal;
      line-height: $line-height-base;
      margin-right: 1.5rem;
      color: white;
      text-transform: none;
      font-weight: $font-weight-medium;
      &::before {
        box-shadow: none;
      }
    }
    a.text-secondary {
      font-size: $normal;
      line-height: $line-height-base;
      font-weight: $font-weight-medium;
    }
    a:hover {
      color: #3e77c9 !important;
    }
    a.q-btn:hover {
      color: white !important;
    }
  }

  .q-tab-panels {
    background: transparent;

    .q-tab-panel {
      padding: 1.5rem 0 0;
      background: transparent;
    }
  }
  .q-tab {
    &__content {
      padding: 0.5rem 0;
    }

    &__label {
      color: #77809d;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
    }

    &__indicator {
      margin-right: 0.125rem;
      margin-left: 0.125rem;
    }
    &--active {
      background: #d5d9eb;
      border-radius: 0.25rem 0.25rem 0rem 0rem;

      .q-tab__label {
        color: #1d2c5b;
      }
      .q-tab__indicator {
        color: #d5d9eb !important;
      }
    }

    &--inactive {
      .q-tab__indicator {
        color: #d5d9eb !important;
      }
    }
  }

  .q-checkbox {
    .t-xs {
      color: #111a37;
    }
    svg {
      margin-left: 0.5rem;
    }
  }
}
</style>
