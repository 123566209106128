<template>
  <div class="utas-calculator-layout-container">
    <header
      id="sticky-header"
      class="container-fluid bg-primary"
    >
      <div class="container-xxl">
        <nav
          id="navbarDesktop"
          class="navbar navbar-dark"
        >
          <div>
            <div class="navbar-brand">
              <img
                :src="`${imageUrlHelper.getImageUrl(
                  '/genertel-logo.svg',
                  componentUrl
                )}`"
                alt="genertel-logo"
                @click="backToHome"
              >
            </div>
          </div>
        </nav>
      </div>
    </header>
    <DynamicBreadcrumb
      v-if="!isThankYouPage"
      section-title="Utasbiztosítás kalkulátor"
      :current-page-counter="0"
      :all-page-counter="4"
      :page-title="texts?.vtxt_firstPageTitle_label"
    />
    <TravelInsuranceFirstPage
      v-if="viewModel"
      ref="firstPageRef"
      :view-model="viewModel"
      :texts="texts"
      @validate-form="validateForm"
    />
    <div class="button-container">
      <CalculatorButtons
        :secondbtn="'Tovább'"
        :hide-back-button="false"
        @go-to-next-page="goToNextPage"
        @go-to-prev-page="goToPreviousPage"
      />
    </div>
  </div>
  <StickyCart v-if="showCart && Screen.width > 375" />
</template>

<script setup>
import { apiHandler } from '@/utils/infrastructure/apiHandler'
import TravelInsuranceFirstPage from '@/views/ProductViews/TravelInsuranceFirstPage.vue'
import { Screen } from 'quasar'
import { computed, onBeforeUnmount, onMounted, ref, inject, onUnmounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useCalculatorStore } from '../../../stores/calculatorStore'
import { useTravelWizardStore } from '../../../stores/travelWizardStore'
import imageUrlHelper from '../../../utils/infrastructure/imageUrlHelper.js'
import { useTravelDestionationsStore } from '@/stores/travelDestionationsStore'
import optionValues from "@/utils/minicalculatorOptionsValues/optionValues";
import DevToolsUtas from "@/components/controls/DevToolsUtas.vue";

// HOOKS AND VARS
const router = useRouter()
const route = useRoute()
const calculatorStore = useCalculatorStore()
const travelDestinationStore = useTravelDestionationsStore()
const pushDataLayer = inject('pushDataLayer')
const showCart = computed(() => {
  return calculatorStore.getShowCart
})
const travelWizardStore = useTravelWizardStore()
const utasFormModel = ref(optionValues.getTravelValues())
//STATE

const firstPageRef = ref(null)

if (sessionStorage.getItem('vm') !== null) {
  travelWizardStore.setViewModel(JSON.parse(sessionStorage.getItem('vm')))
  sessionStorage.removeItem('vm')
}
const viewModel = ref(travelWizardStore.getViewModel)
const backtohome = ref(false)
const texts = ref(travelWizardStore.getTexts)

const currentURL = ref(window.location.href)

// PROPS
const props = defineProps({
  isSavePage: {
    type: Boolean,
    default: false,
  },
  isThankYouPage: {
    type: Boolean,
    default: false,
  },
})

const mapPurpose = (id) => utasFormModel.value.utazasOkaOpciok.find((item) => item.value === id).label

const mapVehicle = (id) => utasFormModel.value.utazasiJarmuOpciok.find((item) => item.value === id).label

const mapDestination = async (id) => {
  await travelDestinationStore.setTravelDestinations()
  return travelDestinationStore.getDestinations.find((item) => item.value === +id).label
}

//LIFE CYCLES

onMounted(async () => {
  const urls = []
  const options = []
  const names = []

  if (texts.value === null || texts.value === undefined) {
    urls.push('/calculator/ContentApi/GetTexts')
    options.push({
      params: {
        guid: 'C573C5ED-4751-408D-81E7-8E90B7775C45', // TODO: check if this is the correct one
      },
    })
    names.push('getTexts')
  }
  if (viewModel.value === null || viewModel.value === undefined) {
    urls.push('/calculator/TravelWizardApi/StartWizard')
    options.push({})
    names.push('startWizard')
  }

  const responseData = await apiHandler.getMultiple(urls, names, options)

  // Ha vannak route paraméterek a minikalkulátorból és még MODEL sincs lementve
  if (Object.keys(route.query).length !== 0 && (viewModel.value === null || viewModel.value === undefined)) {
    travelWizardStore.setViewModel(responseData.startWizard.ResponseData, '1.Page-NewCalculate')
    viewModel.value = travelWizardStore.getViewModel
    viewModel.value.PlaneCoverage.Value = route.query.MINI_115 === '3'
    viewModel.value.TripCoverage.Value = route.query.MINI_114 === '2'
    viewModel.value.SkiingCoverage.Value = route.query.MINI_114 === '3'
    viewModel.value.BeachCoverage.Value = route.query.MINI_114 === '1'
    viewModel.value.Purpose.Value.Id = route.query.MINI_114
    viewModel.value.Purpose.Value.Text = mapPurpose(viewModel.value.Purpose.Value.Id)
    viewModel.value.Vehicle.Value.Id = route.query.MINI_115
    viewModel.value.Vehicle.Value.Text = mapVehicle(viewModel.value.Vehicle.Value.Id)
    viewModel.value.Destination.Value.Id = route.query.MINI_116
    viewModel.value.Destination.Value.Text = await mapDestination(viewModel.value.Destination.Value.Id)
    viewModel.value.AutoAssistanceType.Value.Id = null
    viewModel.value.ContractorIsInsured = {
      ...travelWizardStore.getViewModel.ContractorIsInsured,
      Value: true,
    }
    if (route.query.MINI_177 === 'true') {
      viewModel.value.JustCode = '70802628'
    }
  }
  // Ha nincsenek route paraméterek a minikalkulátorból és még MODEL sincs lementve
  else if (Object.keys(route.query).length === 0 && (viewModel.value === null || viewModel.value === undefined)) {
    router.push({ name: 'utasbiztositascalculatorstart' })
  }
  // Ha már van elmentett MODEL
  else {
    // Ha vannak route paraméterek a minikalkulátorból akkor felül írjuk a MODEL-ben található értékeket
    // viewModel.value = travelWizardStore.getViewModel
    if ((route.query.MINI_114 && route.query.MINI_115 && route.query.MINI_116)
      && (route.query.MINI_114 !== viewModel.value?.Purpose?.Value?.Id ||
      route.query.MINI_115 !== viewModel.value.Vehicle.Value.Id ||
      route.query.MINI_116 !== viewModel.value.Destination.Value.Id)) {
      viewModel.value.PlaneCoverage.Value = route.query.MINI_115 === '3'
      viewModel.value.TripCoverage.Value = route.query.MINI_114 === '2'
      viewModel.value.SkiingCoverage.Value = route.query.MINI_114 === '3'
      viewModel.value.BeachCoverage.Value = route.query.MINI_114 === '1'

      viewModel.value.Purpose.Value.Id = route.query.MINI_114
      viewModel.value.Purpose.Value.Text = mapPurpose(viewModel.value.Purpose.Value.Id)
      viewModel.value.Vehicle.Value.Id = route.query.MINI_115
      viewModel.value.Vehicle.Value.Text = mapVehicle(viewModel.value.Vehicle.Value.Id)
      viewModel.value.Destination.Value.Id = route.query.MINI_116
      viewModel.value.Destination.Value.Text = await mapDestination(viewModel.value.Destination.Value.Id)
    }
  }
  // Ha van a route paraméterek között kuponkód
  if (route.query.couponCode) {
    viewModel.value.CouponCode.Value = route.query.couponCode
  }
  viewModel.value.HasAcceptConditionOpened = { Value: false }
  viewModel.value.HasProductGuideOpened = { Value: false }
  viewModel.value.HasCivilCodeOpened = { Value: false }
  viewModel.value.HasMediationInformationOpened = { Value: false }
  viewModel.value.TravelProductGuideDocumentUrl = { Value: '' }
  viewModel.value.TravelAcceptConditionDocumentUrl = { Value: '' }
  viewModel.value.TravelCivilCodeDocumentUrl = { Value: '' }

  travelWizardStore.setViewModel(viewModel.value, 'FirstPageLayout - 1')

  if (texts.value === null || texts.value === undefined) {
    travelWizardStore.setTexts(responseData.getTexts.ResponseData)
  }
  texts.value = travelWizardStore.getTexts

  viewModel.value.Page = 1
  travelWizardStore.setViewModel(viewModel.value, 'FirstPageLayout - 2')

  if (viewModel.value.CoverageStartDate.Value) {
    viewModel.value.CoverageStartDate.Value = viewModel.value.CoverageStartDate.Value.replaceAll('-','.')
  }

  if (viewModel.value.CoverageEndDate.Value) {
    viewModel.value.CoverageEndDate.Value = viewModel.value.CoverageEndDate.Value.replaceAll('-','.')
  }

  window.dataLayer?.push({
    event: 'flowEvent',
    flow: 'biztositas',
    product: 'utasbiztositas',
    block: 'utazas adatai',
    blockId: '1',
    interaction: 'megjelenes'
  })
})

onUnmounted(() => {
  if ( viewModel.value.InsuredAdultsNumber.Value.Id +
    viewModel.value.InsuredChildrenNumber.Value.Id +
    viewModel.value.InsuredPensionersNumber.Value.Id === 0 ) {
    viewModel.value.InsuredAdultsNumber.Value = viewModel.value.InsuredAdultsNumber.Values[2]
    viewModel.value.InsuredChildrenNumber.Value = viewModel.value.InsuredChildrenNumber.Values[0]
    viewModel.value.InsuredPensionersNumber.Value = viewModel.value.InsuredPensionersNumber.Values[0]
    travelWizardStore.setViewModel(viewModel.value, 'FirstPageLayout - 3')
  }
})

onBeforeUnmount(() => {
  if (backtohome.value) {
  }
})

// FUNCTIONS
async function validateForm() {
  firstPageRef.value.$refs.inputRef.validate()
}

async function goToNextPage() {
  let isValid = await firstPageRef.value.$refs.inputRef.validate()
  if (!isValid) {
    scrollToError()
  } else {
    await travelWizardStore.setCurrentPage('csomagvalasztas')
    await travelWizardStore.setViewModel(viewModel.value, 'FirstPageLayout - 4')
    await travelWizardStore.saveViewModel({ pageId: 2, sendEmail: false })
    const device = window.innerWidth > 1199 ? "desktop" : "mobil";
    window.dataLayer?.push({
      event: 'calculation_step',
      flow: 'biztositas',
      product: 'utasbiztositas',
      block: 'utazas adatai',
      blockId: '1',
      device,
      user_type: 'uj_kotes',
    })
    router.push('/utasbiztositas/utasbiztositas-kalkulator/csomagvalasztas')
  }
}

function scrollToError() {
  var validations = document.getElementsByClassName('error-message')
  // Ha van error, akkor az elsőt scrollolja be ( nincs error error-message nélkül )
  if (validations.length > 0) {
    let element = validations[0]
    // Kalkulátoron minden input to classja
    while (element && !element.classList.contains('input-container')) {
      element = element.parentElement
    }
    if (element) {
      // Input container teteje + 10 px
      let offsetTop = element.getBoundingClientRect().top + window.scrollY - 10
      window.scrollTo({ top: offsetTop, behavior: 'smooth' })
    }
    // Ha véletlen lenne olyan input ahol még sincs container, akkor az error messagehez magához scrollozunk + 100 px. Failsafe, nem kellene hogy előforduljon
    else {
      let offsetTop =
        validations[0].getBoundingClientRect().top + window.scrollY - 100
      window.scrollTo({ top: offsetTop, behavior: 'smooth' })
    }
  }
}

function goToPreviousPage() {
  // console.log('MATT - visszalépés előtt VM: ', viewModel.value)
  router.push({ name: 'utasbiztositascalculatorstart' })
}

function backToHome() {
  backtohome.value = true
  router.push('/')
}

</script>

<style lang="scss" scoped>
.utas-calculator-layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-bottom: 5rem;

  header {
    margin-bottom: 5rem;
  }

  .second-page {
    margin-right: auto;
    margin-left: auto;
    max-width: 90rem;
  }
}

.navbar {
  height: 4.5rem;
}

.navbar-brand {
  cursor: pointer;
}

:deep(.navbar-buttons) {
  .btn {
    box-shadow: 0rem 0.625rem 0.9375rem #d5525e;
    border-radius: $half;
    letter-spacing: 0;
    font-weight: $font-weight-medium;
    text-transform: none;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 2.5rem;
    background-color: $N-200;

    svg {
      margin-right: $half;
    }

    span {
      letter-spacing: 0 !important;
      font-size: 0.875rem;
      line-height: 1.5rem;
    }
  }

  .btn-icon {
    min-width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: $normal + $small;
  }
}

.button-container {
  padding: 0 2rem;
}

.calculator-container {
  padding: 0;
  margin-right: auto;
  margin-left: auto;
  max-width: 86rem;
  width: 100%;
  gap: 1.5rem;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: $breakpoint-xl-max) {
  .utas-calculator-layout-container {
    padding-bottom: 2.5rem;

    header {
      margin-bottom: 2.5rem;
    }
  }

  .calculator-container {
    padding: 0 2rem;
  }

  .button-container {
    padding: 0 1rem;
  }
}

@media screen and (max-width: $breakpoint-lg-max) {
  .utas-calculator-layout-container {
    padding-bottom: 2.5rem;

    header {
      margin-bottom: 2.5rem;
    }
  }

  .calculator-container {
    padding: 0 2rem;
    gap: 2rem;
  }
}

@media screen and (max-width: $breakpoint-md-max) {
  .utas-calculator-layout-container {
    padding-bottom: 2.5rem;

    header {
      margin-bottom: 2.5rem;
    }
  }

  .calculator-container {
    padding: 0 2rem;
    gap: 1.5rem;
  }
}

@media screen and (max-width: $breakpoint-sm-max) {
  .utas-calculator-layout-container {
    padding-bottom: 1.5rem;

    header {
      margin-bottom: 1.5rem;
    }
  }

  .calculator-container {
    padding: 0 1rem;
    gap: 1.5rem;
  }
}

@media screen and (max-width: $breakpoint-xs-max) {
  .utas-calculator-layout-container {
    padding-bottom: 1.5rem;

    header {
      margin-bottom: 1.5rem;
    }
  }

  .calculator-container {
    padding: 0 1rem;
    gap: 1.5rem;
  }
}

@media screen and (max-width: 23.4375rem) {
  .container-xxl {
    padding: 0 1rem;
  }
}
</style>
