<template>
  <div>
    <q-input
      ref="inputRef"
      v-model="value"
      mask="####.##.##"
      standout
      :label="label"
      bg-color="white"
      :disable="disable"
      :readonly="readOnly"
      :error="!!veeErrors[0] || warningMessages?.length > 0"
      :class="
        warningMessages?.length > 0 && veeErrors.length == 0
          ? 'has-warning'
          : ''
      "
      @change="inputChanged"
      @focus="setMetaTouched"
      @click="openPickerOverlay"
      @blur="inputChanged"
    >
      <template #append>
        <span
          name="event"
          class="cursor-pointer"
        >
          <PhIconManager
            ph-icon="ph-calendar-blank"
            :size="16"
            color="#4E95FB"
          />
          <q-popup-proxy
            ref="popupProxy"
            class="date-picker"
            transition-show="scale"
            transition-hide="scale"
            :offset="[offsetcomp, 10]"
            @before-hide="inputChanged(value)"
            @show="setDatePickerWidth()"
            @hide="validateOFF.value = false"
          >
            <q-date
              ref="datePickerRef"
              v-model:model-value="value"
              :options="rangeOption"
              minimal
              mask="YYYY.MM.DD"
              first-day-of-week="1"
              :default-year-month="defaultYearMonth"
              :default-view="defaultView"
              :class="navigationMinYearMonth ? 'disabled-years' : ''"
              :navigation-min-year-month="
                navigationMinYearMonth
                  ? startDate.toFormat('yyyy/MM')
                  : ''
              "
              :navigation-max-year-month="navigationMaxYearMonth ? endDate.toFormat('yyyy/MM') : ''"
              color="blue-5"
              emit-immediately
              :locale="{
                days: [
                  'Vasárnap',
                  'Hétfő',
                  'Kedd',
                  'Szerda',
                  'Csütörtök',
                  'Péntek',
                  'Szombat',
                ],
                daysShort: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
                months: [
                  'Január',
                  'Február',
                  'Március',
                  'Április',
                  'Május',
                  'Június',
                  'Július',
                  'Augusztus',
                  'Szeptember',
                  'Október',
                  'November',
                  'December',
                ],
                monthsShort: [
                  'Jan',
                  'Feb',
                  'Márc',
                  'Ápr',
                  'Máj',
                  'Jún',
                  'Júl',
                  'Aug',
                  'Szept',
                  'Okt',
                  'Nov',
                  'Dec',
                ],
              }"
              @blur="validateQForm"
              @update:model-value="closeOnDayPick"
            />
          </q-popup-proxy>
        </span>
      </template>
      <template #label>
        {{ label }}
        <Tooltip :tooltip-content="tooltipContent" />
      </template>
      <template #error>
        <ValidationMessage
          :messages="veeErrors"
          :info-messages="infoMessages"
          :warning-messages="warningMessages"
        />
      </template>
    </q-input>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed, inject } from 'vue'
import { useField } from 'vee-validate'
import { useFormChild } from 'quasar'
import * as ValidationMessages from '@/utils/validations/validationMessages.js'
import { customDefineRule } from '@/utils/validations/customDefineRule.js'
import {
  getInfoMessagesForValue,
  getWarningMessagesForValue,
} from '@/utils/validations/customValidator.js'
import { VueScreenSizeMixin } from 'vue-screen-size'

const allowValidation = ref(false)
const isMobile = computed(() => VueScreenSizeMixin.methods.getScreenWidth() <= 991)
const DateTime = window.luxon.DateTime
const validateOFF = ref(true);

const props = defineProps({
  id: {
    type: String,
    required: false,
  },
  label: {
    type: String,
    required: true,
  },
  disable: {
    type: Boolean,
    default: false,
  },
  validationRules: {
    type: String,
    required: false,
    default: '',
  },

  infoMessageRules: {
    type: String,
    default: '',
  },

  warningMessageRules: {
    type: String,
    default: '',
  },

  modelValue: {
    type: [String, Array, Object],
    required: false,
    default: null,
  },

  initInputValue: {
    type: [String, Array, Object, window.luxon.DateTime],
    default: null,
  },

  inTab: {
    type: Boolean,
    required: false,
  },

  inAccordion: {
    type: Boolean,
    required: false,
  },

  currentSection: {
    type: String,
    required: false,
  },

  tooltipContent: {
    type: String,
    default: '',
  },

  startDate: {
    type: window.luxon.DateTime,
    required: false,
  },
  endDate: {
    type: window.luxon.DateTime,
    required: false,
    default: window.luxon.DateTime.now().plus({ year: 1 }),
  },
  minDate: {
    type: window.luxon.DateTime,
    required: false,
  },
  maxDate: {
    type: window.luxon.DateTime,
    required: false,
    default: window.luxon.DateTime.now().plus({ year: 1 }),
  },
  warningMessagesTarget: {
    type: Object,
    required: false,
    default: () => {},
  },
  navigationMinYearMonth: {
    type: Boolean,
    required: false,
  },
  navigationMaxYearMonth: {
    type: Boolean,
    required: false,
  },
  ignoreOverrideInput: {
    type: Boolean,
    required: false,
    default: false
  },
  isVal333Needed: {
    type: Boolean,
    required: false,
    default: false,
  },
  defaultYearMonth: {
    type: String,
    required: false,
    default: undefined,
  },
  defaultView: {
    type: String,
    required: false,
    default: 'Calendar',
  },
  item: {
    type: String,
    required: false,
    default: null,
  }
})

const emit = defineEmits(['update:modelValue','update:removeValue'])

const removeValueIfNotValid = inject('_removeValueIfNotValid')
const viewModel = inject('viewModel')

const infoMessages = ref(null)
const warningMessages = ref(null)
const inputRef = ref(null)
const datePickerRef = ref(null)
const inputWidth = ref(null)
const popupProxy = ref(null)
const readOnly = ref(false)

onMounted(() => {
  if (props.initInputValue !== null) {
    value.value = props.initInputValue
    emit('update:modelValue', value.value)
    handleInfoWarningMessages(value.value)
    validateQForm()
  }
  const input = inputRef.value.$el.getBoundingClientRect()
  inputWidth.value = input.width
})

watch(
  () => props.modelValue,
  (newValue) => {
    value.value = newValue
    validateQForm()
    handleInfoWarningMessages(value.value)
  }
)

function rangeOption(date) {
  // Példa a returnre:
  // return date >= '2019/02/03' && date <= '2019/02/15'
  const startdate = props.startDate.toFormat('yyyy/MM/dd')
  const enddate = props.endDate.toFormat('yyyy/MM/dd')
  return date >= startdate && date <= enddate
}

const checkInRange = (date) => {
  const startdate = props.startDate.toFormat('yyyy.MM.dd')
  const enddate = props.endDate.toFormat('yyyy.MM.dd')
  return date >= startdate && date <= enddate
}

const offsetcomp = computed(() => {
  return inputWidth.value - 32
})

async function inputChanged() {
  // console.log('MATT - inputChanged: ', value.value)
  // console.log('MATT - validatteOFF: ', validateOFF.value)
  if (value.value) {
    // console.log('MATT - VAN --->')
    const isValid = await validateQForm()
    if (isValid) {
      // console.log('MATT - VALID --->')
      const startDate = props.startDate.toFormat('yyyy.MM.dd')
      const endDate = props.endDate.toFormat('yyyy.MM.dd')
      if (value.value >= startDate) {
        allowValidation.value = true
        emit('update:modelValue', value.value)
      }
      if (value.value > endDate) {
        if(!props.ignoreOverrideInput) {
          value.value = endDate
        }
      } else {
        allowValidation.value = true
        emit('update:modelValue', value.value)
      }
    } else {
      // console.log('MATT - INVALID --->')
      allowValidation.value = false
      handleInfoWarningMessages(value.value)
    }
  } else {
    // console.log('MATT - NINCS --->', value.value, props.item)
    if (props.item  === 'utas-dp-start' ||
        props.item === 'utas-dp-end' ||
        props.item === 'contractorBirthDate' ||
        props.item === 'insAdult' ||
        props.item === 'insChild' ||
        props.item === 'insNyugger'
    ) {
      // console.log('MATT - indul a remove...')
      emit('update:removeValue', props.item)
    } else if (props.item === 'dev-menu') {
      emit('update:modelValue', value.value)
    }
    setTimeout(() => {
      if (!validateOFF.value) {
        validateQForm()
        allowValidation.value = false
        handleInfoWarningMessages(null)
      }
    },300)
  }
}

async function validateQForm() {
  // run vee-validate validations
  await veeValidate()
  // console.log('MATT - validateOFF: ', validateOFF.value)
  if (veeErrors.value.length > 0) {
    if (removeValueIfNotValid.value && !meta.valid && meta.touched) {
      value.value = null
      inputChanged()
      return true
    }
    return false
  } else {
    return true
  }
}

function resetValidationQForm() {
  veeErrors.value = []
}

useFormChild({
  validate: validateQForm, // Function; Can be async; // Should return a Boolean (or a Promise resolving to a Boolean)
  resetValidation: resetValidationQForm, // Optional function which resets validation
  requiresQForm: false, // should it error out if no parent QForm is found?
})

function handleInfoWarningMessages(value) {
  infoMessages.value = getInfoMessagesForValue(value, props.infoMessageRules)
  warningMessages.value = getWarningMessagesForValue(
    value,
    props.warningMessageRules,
    props.warningMessagesTarget
  )
}

function openPickerOverlay() {
  if (isMobile.value) {
    readOnly.value = true
    popupProxy.value.show()
  }
}

function setMetaTouched() {
  // console.log('MATT - setMetaTouched')
  meta.touched = true
  validateOFF.value = false
}

const {
  errors: veeErrors,
  value,
  meta,
  validate: veeValidate,
} = useField(
  props.id,
  `${props.isVal333Needed
    ? 'VAL_333_'+ props.id +'|'+ props.validationRules
    : props.validationRules}|VAL_299`,
  {
    validateOnValueUpdate: false,
  }
)

function DateValidator(input) {
  input = input.replaceAll('.', '-')

  if(input.length !== 10){
    return false;
  }

  const selectedDate = DateTime.fromISO(input).toFormat('yyyy.MM.dd')
  return selectedDate == 'Invalid DateTime' ? false : true
}

customDefineRule('VAL_333_' + props.id, (value) => {
  if (value != undefined) {
      value = value.replaceAll('.', '-')

      const selectedDate = DateTime.fromISO(value).toFormat('yyyy.MM.dd')
      const minDate = (props.minDate).toFormat('yyyy.MM.dd')
      const maxDate = (props.maxDate).toFormat('yyyy.MM.dd')

      if (!DateValidator(selectedDate)) {
        return ValidationMessages.VAL_333
      }

      if (selectedDate != 'Invalid Date') {
        if ((minDate > selectedDate) || (maxDate < selectedDate)) {

          return ValidationMessages.VAL_333
        }else{
          return true
        }
      }else{
        return true
      }
  }
    return true
})

customDefineRule('VAL_299', (value) => {
  // console.log('MATT - datepicker input: ' + props.id, value)
  const dateRegex = /^\d{4}\.\d{2}\.\d{2}$/;
  if (dateRegex.test(value)) {
    // valid dátumformátum ellenörzés - START
    const [year, month, day] = value.split(".").map(Number);
    const date = new Date(year, month - 1, day);
    if (
      date.getFullYear() !== year ||
      date.getMonth() + 1 !== month ||
      date.getDate() !== day
    ) {
      return 'Hibás dátum formátum!'
    }
    // valid dátumformátum ellenörzés - END
    return true
  } else {
    if (value?.length === 0 && props.label === 'Teszt rendszerdátum') {
      return true
    } else {
      return ValidationMessages.VAL_299
    }
  }
})

const setDatePickerWidth = () => {
  validateOFF.value = true
  if (props.navigationMaxYearMonth && !checkInRange(value.value)) {
    value.value = null
  }
  const input = inputRef.value.$el.getBoundingClientRect()
  const inputWidth = input.width
  datePickerRef.value.$el.style.width = `${inputWidth}px`
}

function closeOnDayPick(inputValue, reason) {
  if (reason == 'add-day') {
    value.value = inputValue
    popupProxy.value.hide()
    inputChanged(inputValue)
  }
}
</script>

<script>
export default {
  name: 'DatePicker',
  mixins: [VueScreenSizeMixin],
}
</script>
<style lang="scss" scoped>
.date-picker {
  z-index: 1000;
}

.q-field__append i {
  display: none !important;
}

.q-date {
  box-shadow: none;
  border-radius: 0.5rem;
  color: $D-500;
  overflow: hidden;
}

:deep(.q-date__today) {
  box-shadow: none;
  font-family: $font-family-sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #4e95fb;
  display: flex;
  align-items: center;

  &:after {
    content: '';
    width: 0.25rem;
    height: 0.25rem;
    border-radius: 100%;
    background: #4e95fb;
  }
}

:deep(.q-date__calendar-days) {
  .q-date__calendar-item {
    span {
      font-size: 0.75rem;
    }
    div{
      font-size: 0.75rem;
    }
  }
}

:deep(.q-date__years) {
  flex-direction: column;

  > div:first-child {
    position: relative;
    bottom: 0.375rem;

    > button {
      width: 1rem;
      height: 1rem;
    }
    .q-btn__content {
      &:before {
        content: url('../../assets/images/caret-up.svg');
        position: relative;
        top: 0.5rem;
      }
    }
  }

  > div:last-child {
    position: relative;
    top: 0.375rem;

    > button {
      width: 1rem;
      height: 1rem;
    }
    .q-btn__content {
      &:before {
        content: url('../../assets/images/caret-down.svg');
        position: relative;
        top: 0.5rem;
      }
    }
  }
  .q-icon {
    visibility: hidden;
  }
  .q-date__years-item {
    span {
      font-size: 0.9rem;
    }
  }
}

:deep(.q-date__years-content) {
  padding: 0;
}

:deep(.q-date__navigation),
:deep(.q-input) {
  .q-field__control {
    width: 100%;

    &:before {
      border-bottom: none;
      transition: none;
    }
  }
}

:deep(.q-date__navigation) {
  margin-bottom: 0.625rem;
  margin: 0 0.9375rem;

  > div:nth-child(1) {
    order: 2;
  }

  > div:nth-child(2) {
    order: 3;
  }

  > div:nth-child(3) {
    order: 4;
    width: auto;
  }

  > div:nth-child(5) .block {
    color: #4e95fb;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  > div:nth-child(1),
  > div:nth-child(3) {
    width: 1.5625rem !important;
  }

  > div:nth-child(2) .block {
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-family: $font-family-sans-serif;
    font-weight: 600;
  }

  > div:nth-child(4),
  div:nth-child(6) {
    display: none;
  }

  > div:first-child,
  > div:last-child {
    width: 8%;
  }
  > .q-date__arrow:nth-child(1) {
    > button {
      &:before {
        content: url('../../assets/images/caret-left.svg');
        top: 0.25rem;
        right: 0.0625rem;
      }
    }
    i {
      visibility: hidden;
    }
  }

  > .q-date__arrow:nth-child(3) {
    > button {
      &:before {
        content: url('../../assets/images/caret-right.svg');
        top: 0.25rem;
        left: 1px;
      }
    }
    i {
      visibility: hidden;
    }
  }
}

:deep(.q-field--standout) {
  &.q-field * {
    color: $D-400 !important;
    border-width: 0 !important;
  }

  .q-field__control:before {
    background-color: transparent;
  }
  &.q-field--highlighted .q-field__control {
    box-shadow: none;
  }

  .q-field__control {
    border-radius: $half !important;
    padding: 0 1rem !important;
    box-shadow: inset 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.06) !important;
    border: 0.0625rem solid #f5f4f8 !important;

  }
  .q-field__label {
    font-size: $font-size-sm;
    line-height: $line-height-sm;
    font-weight: $font-weight-medium;
  }
  .q-field--float {
    .q-field__label {
      transform: translateY(-40%) scale(10 / 12) !important;
    }
  }
  .q-field__native,
  .q-field__prefix,
  .q-field__suffix,
  .q-field__input {
    font-weight: $font-weight-bold !important;
    cursor: pointer;
    color: $dark !important;
  }
  .q-field__append {
    background: transparent !important;
    width: auto !important;
  }
}

:deep(.q-field--standout:hover) {
  .q-field__control {
    border: 0.0625rem solid $border-hover !important;
    background-color: $N-100 !important;
  }
}

:deep(.q-field--standout.q-field--focused) {
  .q-field__control {
    border: 0.0625rem solid $border-active !important;
  }
}

:deep(.q-field--focused:hover) {
  .q-field__control {
    background-color: white !important;
    border: 0.0625rem solid $border-active !important;
  }
}

:deep(.q-field--float:hover) {
  &.q-field--focused {
    .q-field__control {
      border: 0.0625rem solid $border-active !important;
    }
  }
}

:deep(.q-field--float:hover) {
  .q-field__control {
    border: 0.0625rem solid $border-data-hover !important;
    background-color: $input-data-bg-hover !important;
  }
}

:deep(.q-field--error) {
  & * {
    color: $input-text-validation !important;
  }
  .q-field__control {
    border: 0.0625rem solid $border-validation !important;
    background-color: white !important;
  }
}

:deep(.q-field--standout) {
  &.q-field.q-field--error * {
    color: $input-text-validation !important;
  }
}

:deep(.q-field--standout.q-field .q-field__append) {
  background: transparent !important;
  width: auto !important;
  svg,
  svg * {
    color: $N-600 !important;
  }
}
.disabled-years {
  :deep(.q-date__years) {
    .disabled {
      display: none;
    }
  }
}

:deep(.q-field--standout.q-field--readonly .q-field__control:before) {
  opacity: 0.1;
  background-color: #d8d8d8;
  color: rgba(216, 216, 216, 0.1) !important;

  border: 0.0625rem solid $border-active 0.1 !important;
}
</style>
