<template>
  <div class="debugwindow">
    <!-- button -->
    <q-btn
      class="text-color-debug"
      @click="showPage = 1"
    >
      ViewModel
    </q-btn>
    <q-btn
      class="text-color-debug"
      @click="showPage = 2"
    >
      Texts
    </q-btn>
    <q-btn
      class="text-color-debug"
      @click="showPage = 3"
    >
      CurrentUrl
    </q-btn>
    <q-btn
      class="text-color-debug"
      @click="showPage = 4"
    >
      Page
    </q-btn>
    <q-btn
      v-if="viewModel.Product != 30"
      class="text-color-debug"
      @click="showPage = 5"
    >
      TestMode
    </q-btn>
    <q-btn @click="copyVM">
      Copy to clipboard
    </q-btn>
    <q-btn @click="downloadViewModel">
      Download ViewModel
    </q-btn>
    <q-btn @click="downloadGalaxyXml('Calculate', 'Request')">
      Download calculate request GalaxyXml
    </q-btn>
    <q-btn @click="downloadGalaxyXml('Calculate', 'Response')">
      Download calculate response GalaxyXml
    </q-btn>
    <q-btn @click="downloadGalaxyXml('CheckOffer', 'Request')">
      Download checkoffer request GalaxyXml
    </q-btn>
    <q-btn @click="downloadGalaxyXml('CheckOffer', 'Response')">
      Download checkoffer response GalaxyXml
    </q-btn>

    <hr>
    <!-- viewModel -->
    <div
      v-if="showPage == 1"
      class="text-left"
    >
      <pre class="overflowHidden-debug">{{ viewModel }}</pre>
    </div>
    <!-- text -->
    <div v-if="showPage == 2">
      <pre class="overflowHidden-debug">{{ texts }}</pre>
    </div>
    <!-- curentUrl-->
    <div v-if="showPage == 3">
      <pre class="overflowHidden-debug">{{ currentUrl }}</pre>
    </div>
    <!-- page-->
    <div v-if="showPage == 4">
      <span>Jelenleg ezen az oldalon áll: {{ page }}</span>
      <br>
      <span>Melyik oldalra Szeretne ugrani?</span>
      <br>
      <span>Az összes lehetőség: {{ viewModel.PageCount }}</span>
      <br>
      <input
        v-model="nextPage"
        type="number"
        class="input-bg"
      >
      <br>
      <q-btn @click="nextPageClick">
        Tovább
      </q-btn>
      <br>
      <span class="error">{{ pageError }}</span>
    </div>
    <!-- test mode -->
    <div
      v-if="showPage == 5"
      class="test--date"
    >
      <DatePicker
        id="TM_TSD"
        v-model:model-value="testSystemDate"
        item="dev-menu"
        :start-date="Date9YearsAgo(DateTime.now())"
        :min-date="DateTime.now()"
        :end-date="DateNextYear(DateTime.now())"
        :init-input-value="testSystemDate"
        label="Teszt rendszerdátum"
        @update:model-value="testSystemDateChanged"
      />
      <div
        v-if="
          viewModel.Product == 5 ||
            viewModel.Product == 30 ||
            viewModel.Product == 33 ||
            viewModel.Product == 34 ||
            viewModel.Product == 35 ||
            viewModel.Product == 36
        "
      >
        <CheckboxInput
          id="TM_BLC"
          v-model="blackList"
          @update:model-value="blacklistCheckChanged"
        >
          Blacklist ellenőrzés
        </CheckboxInput>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { copyToClipboard } from 'quasar'
import axios from 'axios'
import { useTravelWizardStore } from "@/stores/travelWizardStore";
import { useRouter } from "vue-router";
import DatePicker from "@/components/inputs/DatePicker.vue";

const router = useRouter()

const DateTime = window.luxon.DateTime
const props = defineProps({
  viewModel: {
    type: Object,
    required: false,
  },
  page: {
    type: Number,
    required: false,
  },
  texts: {
    type: Object,
    required: false,
  },
  currentUrl: {
    type: String,
    required: false,
  }
})
const emit = defineEmits([
  'update:testSystemDate',
  'update:blacklistCheck',
])
const showPage = ref(5)
const nextPage = ref(0)
const pageError = ref('')
const travelWizardStore = useTravelWizardStore()
const testSystemDate = ref()
const blackList = ref(props.viewModel.TechnicalData.BlacklistCheck.Value)

onMounted( () => {
  if (props.viewModel.TestSystemDate.Value) {
    testSystemDate.value = props.viewModel.TestSystemDate.Value
  }
})

onUnmounted(() => {
  //
})

function blacklistCheckChanged() {
  emit('update:blacklistCheck', blackList.value)
}
function testSystemDateChanged(e) {
  // console.log('MATT - event: ', e)
  testSystemDate.value = e
  emit('update:testSystemDate', testSystemDate.value)
}
function Date9YearsAgo(d) {
  let date = DateTime.fromISO(d).minus({ years: 9 })
  return date
}
function DateNextYear(d) {
  let date = DateTime.fromISO(d).plus({ years: 1 })
  return date
}
function downloadViewModel() {
  let viewModel = JSON.stringify(props.viewModel, null, 2)
  const url = window.URL.createObjectURL(new Blob([viewModel]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', 'viewModel.json')
  link.click()
}
function nextPageClick() {
  let path = travelWizardStore.getPagePaths[nextPage.value - 1].replace(
    /^\//,
    ''
  )
  pageNavigator(path)
}
function pageNavigator(page, isSixthPage = false) {
  router.push({ path: '/utasbiztositas/utasbiztositas-kalkulator/' + page })
}

async function downloadGalaxyXml(funciton, type) {
  let galaxyXml
  const fileName = 'Travel' + '_' + funciton + '_' + type + '.xml'
  await axios
    .get(
      import.meta.env.VITE_PORTAL_API_BASE_URL + '/calculator/TravelWizardApi/GetGalaxyXml',
      {
        params: {
          function: funciton,
          type: type,
        },
      }
    )
    .then((response) => (galaxyXml = response.data))
  const galaxyXmlUrl = window.URL.createObjectURL(new Blob([galaxyXml]))
  const date = DateTime.now().toFormat('yyyy_MM_dd')
  const link = document.createElement('a')
  link.href = galaxyXmlUrl

  link.setAttribute('download', `${date}_${fileName}`)
  link.click()
}

const copyVM = () => {
  copyToClipboard(JSON.stringify(props.viewModel, null, 2))
    .then(() => {
      console.log('Sikeres másolás')
    })
    .catch(() => {
      console.log('Sikertelen másolás')
    })
}

</script>

<style scoped lang="scss">
.cursor-debug {
  cursor: pointer;
}
.overflowHidden-debug {
  overflow: hidden;
}
.input-bg {
  background-color: #ffffff;
}
.debugli {
  cursor: pointer;
}

div.debugwindow {
  z-index: 10000;
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 500px;
  position: fixed;
  display: block;
  right: 0;
  top: 0;
  border-style: solid;
  border-width: 1px;
  overflow-y: auto;
  background-color: aliceblue;
}
button {
  min-height: 36px;
  height: unset !important;
  .v-btn__content {
    white-space: normal;
    display: block;
    flex: none;
  }
}

div.debugwindow hr {
  margin-bottom: 20px;
  border-color: #ccc;
  width: 96%;
  margin-left: 10px;
}

div.debugwindow .layout {
  padding: 0 10px;
  position: relative;
}
div.debugwindow .v-text-field--enclosed {
  max-width: 191px !important;
}
div.debugwindow .v-input__slot input {
  cursor: pointer !important;
}
div.debugwindow .menuable__content__active[data-v-fa92f686] {
  top: 423px !important;
  left: auto !important;
  right: 2px !important;
  padding-top: 0px;
}
div.debugwindow .checkbox-description {
  max-width: 17rem !important;
  font-size: 0.9rem !important;
}

.error {
  color: red;
}
.debugwindow {
  z-index: 100000;
  width: 50%;
  height: 50%;
  position: fixed;
  display: block;
  right: 0;
  bottom: 0;
  border-style: solid;
  border-width: 1px;
  overflow-y: auto;
  background-color: aliceblue;
}
</style>
