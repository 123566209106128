<template>
  <q-form
    ref="vehicleAssistanceFormRef"
    name="vehicleAssistanceBox"
  >
    <div class="car-assistance-container">
      <div class="calculator-holder">
        <Row66
          id="KALK_119"
          class="input-container"
        >
          <template #first>
            <div class="calculator-description-holder">
              <TitleTooltipModal
                htag="h3"
                class="input-title"
                :tooltip-content="tooltipContent"
                :tooltip-title="assistanceTitle"
              >
                {{ assistanceTitle }}
              </TitleTooltipModal>
              <p
                v-if="assistanceDescription"
                class="small"
                v-html="assistanceDescription"
              />
            </div>
          </template>
          <template #second>
            <div class="input-holder">
              <SelectInput
                id="UT_115"
                :key="serviceLevels.name"
                v-model:model-value="value"
                label="Kérjük, válasszon!"
                :options="serviceLevels"
                :init-option-value="value"
                @focus="setMetaTouched"
              />
              <div
                v-if="assistanceServices && value && value !== 0"
                class="car-assistance-option-description"
              >
                <div
                  v-for="service in assistanceServices"
                  :key="service.name"
                >
                  <div
                    v-if="value !==0 && (Number(service.level) % (Number(value) + 1)) == 0"
                  >
                    <div class="option-name">
                      <p class="document-description">
                        {{ service.name }}
                      </p>
                    </div>
                    <div class="prices">
                      <p class="document-description">
                        {{ service.prices }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Row66>
      </div>
      <div v-if="value && value !== 0">
        <div
          v-for="(car, index) in carsData"
          :key="car"
          class="calculator-holder"
          style="margin-top: 1rem;"
        >
          <Row66
            id="KALK_119"
            class="input-container"
          >
            <template #first>
              <div class="calculator-description-holder">
                <TitleTooltipModal
                  htag="h3"
                  class="input-title"
                  :tooltip-content="dataTooltipContent"
                  :tooltip-title="dataTitle"
                >
                  {{ carsData.length > 1 ? index + 1 + '. ' : '' }} {{ dataTitle }}
                </TitleTooltipModal>
              </div>
              <p
                v-if="dataDescription"
                class="small"
                v-html="dataDescription"
              />
              <a
                v-if="index > 0"
                class="read-more d-flex text-secondary text-bold text-sm w-fit-content"
                style="font-size: 0.875rem;line-height: 2rem; margin: 1rem 0"
                @click="removeCar(index)"
              >
                <PhIconManager
                  height="1.25rem"
                  width="1.25rem"
                  ph-icon="ph-trash"
                  class="icon-margin"
                />
                Új jármű törlése
              </a>
            </template>
            <template #second>
              <div class="input-holder">
                <RubSelectInput
                  id="UT_116"
                  :key="car.plateNumber"
                  v-model:model-value="car.year"
                  input-type="year-number"
                  label="Gyártási év"
                  :options="modelValues"
                  max-length="4"
                  validation-rules="VAL_100|VAL_304|VAL_329|VAL_330"
                  :init-option-value="car.year"
                  :tooltip-content="carYearTooltipContent"
                  is-autocomplete
                  add-new-value
                />
              </div>
              <div class="input-holder">
                <TextInput
                  id="UT_117"
                  v-model:model-value="car.plateNumber"
                  class="plate-number"
                  label="Rendszám"
                  max-length="8"
                  validation-rules="VAL_100|VAL_191_BETWEEN_6_8|VAL_345|VAL_346"
                  :init-input-value="car.plateNumber"
                  :tooltip-content="carPlateTooltipContent"
                />
              </div>
              <a
                v-if="
                  maxNumberOfCars > carsData.length && index === carsData.length - 1
                "
                class="read-more d-flex text-secondary text-bold text-sm"
                style="font-size: 0.875rem; margin-top: 1.75rem"
                @click="addNewCar"
              >
                <PhIconManager
                  ph-icon="ph-plus"
                  height="1.25rem"
                  width="1.25rem"
                  class="icon-margin"
                />
                Új jármű hozzáadása
              </a>
            </template>
          </Row66>
        </div>
      </div>
    </div>
  </q-form>
</template>

<script setup>
import {customDefineRule} from '@/utils/validations/customDefineRule'
import * as ValidationMessages from '@/utils/validations/validationMessages.js'
import RubSelectInput from '@/components/inputs/RubSelectInput.vue'
import {computed, onMounted, ref, watch} from 'vue'

const YEARS_TO_DISPLAY = 16

const props = defineProps({
  assistanceTitle: {
    type: String,
    default: '',
    required: true,
  },
  assistanceDescription: {
    type: String,
    required: false,
  },
  dataTitle: {
    type: String,
    required: false,
  },
  dataTooltipContent: {
    type: String,
    required: false,
  },
  dataDescription: {
    type: String,
    required: false,
  },
  tooltipContent: {
    type: String,
    required: false,
  },
  carYearTooltipContent: {
    type: String,
    required: false,
  },
  carPlateTooltipContent: {
    type: String,
    required: false,
  },
  serviceLevels: {
    type: Array
  },
  maxNumberOfCars: {
    type: Number,
    required: false,
    default: 10,
  },
  autoAssistanceType: {
    type: Number,
    default: null,
  },
  autoAssistanceCars: {
    type: Array
  },
})

const modelValues = computed( () => {
  const currentYear = new Date().getFullYear()

  return [...Array(YEARS_TO_DISPLAY).keys()].map((year) => {
    return {
      label: `${currentYear - year}`,
      value: currentYear - year,
    }
  })
})

const carsProps =  computed ( () => {
  if (props.autoAssistanceCars.length !== 0) {
    return  props.autoAssistanceCars.map(vehicle => {
      return {
        year: vehicle.YearOfProduction.Value,
        plateNumber: vehicle.PlateNumber.Value,
      }
    })
  } else {
    return [{
      year: '',
      plateNumber: null,
    }]
  }
})

const carsData = ref(carsProps.value)
const value = ref(props.autoAssistanceType)

const emits = defineEmits(['updateVehicleAssistance'])

function setMetaTouched() {
  meta.touched = true
}

watch(value, (value) => {
      emits('updateVehicleAssistance', {
        assistance: {
          selected: value,
          carsData: carsData.value
        }
      })
    }
)


const assistanceServices = [
  {name: 'Szerviz ajánlás, kapcsolattartás szervízzel:', prices: 'tényleges költségek', level: 6},
  {name: 'Helyszíni javítás és/vagy a legközelebbi szervízbe szállítás:', prices: 'tényleges költségek', level: 6},
  {name: 'Gépjármű tárolása az első munkanapig:', prices: 'tényleges költségek', level: 6},
  {name: 'Hazaszállítás autómentővel:', prices: '200 000 HUF', level: 2},
  {name: 'Hazaszállítás autómentővel:', prices: '300 000 HUF', level: 3},
  {name: 'Telefon/taxi költség:', prices: '50 EUR/eset', level: 6},
  {name: 'B kategóriás csereautó vagy:', prices: '3 nap', level: 3},
  {name: 'Utasok tovább (vissza) utaztatása vagy:', prices: '500 EUR/eset', level: 3},
  {name: 'Szállás megszervezése és költségek vállalása:', prices: '60 EUR/személy', level: 3},
]

const serviceLevels = ref(props.serviceLevels)


customDefineRule('VAL_100', (input) => {
  if (!input) {
    return ValidationMessages.VAL_100
  }
  return true
})

customDefineRule('VAL_191_BETWEEN_6_8', (input) => {
  if (input?.length < 6 || input?.length > 8) {
    return ValidationMessages.VAL_191_BETWEEN_6_8
  }
  return true
})
customDefineRule('VAL_304', (input) => {
  const year = new Date().getFullYear()
  if (input > 999 && year - input > 15) {
    return ValidationMessages.VAL_304
  }
  return true
})

customDefineRule('VAL_329', (input) => {
  if (input.toString()?.length !== 4 || !/^\d+$/.test(input)) {
    return ValidationMessages.VAL_329
  }
  return true
})
customDefineRule('VAL_330', (input) => {
  const year = new Date().getFullYear()
  if (parseInt(input) > year && input?.length > 0) {
    return ValidationMessages.VAL_330
  }
  return true
})
customDefineRule('VAL_345', (input) => {
  if (/[\s\W]/.test(input) && input?.length > 0) {
    return ValidationMessages.VAL_345
  }
  return true
})
customDefineRule('VAL_346', (input) => {
  if (input?.length > 0) {
    const firstChar = input.charAt(0)
    const lastChar = input.charAt(input.length - 1)

    const isAlphabet = /^[a-zA-Z]$/.test(firstChar)
    const isNumber = /^[0-9]$/.test(lastChar)

    if (!isAlphabet || !isNumber) {
      return ValidationMessages.VAL_346
    }
  }
  return true
})

const addNewCar = () => {
  props.maxNumberOfCars > carsData.value.length &&
  carsData.value.push({
    year: '',
    plateNumber: null,
  })
}

const removeCar = (index) => {
  carsData.value.splice(index, 1)
  emits('updateVehicleAssistance', {
    assistance: {
      selected: value.value,
      carsData: carsData.value
    }
  })
}

const isEmpty = (fieldValue) => {
  return fieldValue === undefined || fieldValue === null ||  fieldValue === ''
}

watch(carsData.value, (carsData) => {
  const hasEmptyField = carsData.some(car => isEmpty(car.year) || isEmpty(car.plateNumber))
    if (!hasEmptyField) {
      emits('updateVehicleAssistance', {
        assistance: {
          selected: value.value,
          carsData: carsData
        }
      })
    }
  }
)

onMounted(() => {
  value.value = props.autoAssistanceType
})
</script>

<style lang="scss" scoped>
.icon-margin {
  margin-right: 0.5rem;
}

.car-assistance-container {
  display: flex;
  flex-direction: column;
}

.w-fit-content {
  width: fit-content;
}

.car-assistance-option-description {
  margin-top: 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 3px 0px #0000001a;
  box-shadow: 0px 1px 2px 0px #0000000f;

  > div {
    > div {
      margin: 0.5rem 0rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @media screen and (max-width: $breakpoint-lg-max) {
        display: block;
      }

      p {
        padding-bottom: 0;
      }

      .option-name {
        > p {
          color: #4a567c;
        }
      }

      .prices {
        text-align: right;
        @media screen and (max-width: $breakpoint-lg-max) {
          text-align: left;
        }

        > p {
          font-weight: 600;
          color: #0c1224;
        }
      }
    }
  }
}

.hide {
  display: none;
}

.mb-1 {
  margin-bottom: 1rem;
}

:deep(.q-field__label.no-pointer-events.absolute.ellipsis) {
  left: 0;
  position: absolute;
}

.option-name {
  width: 60%;
}

@media screen and (max-width: $breakpoint-lg-max) {
  .car-assistance-option-description {
    > div {
      display: flex;
      flex-direction: column;

      .prices {
        text-align: left;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-xs-max) {
  .calculator-holder {
    .input-container {
      padding: 1.5rem 1rem;
    }
  }
  .option-name {
    width: 100%;
  }
}
</style>
