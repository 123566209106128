<template>
  <q-input
    v-model="value"
    class="numeric-up-down"
    standout
    bottom-slots
    :item-aligned="true"
    :borderless="true"
    :validation-rules="validationRules"
    :error="!!veeErrors[0]"
    :mask="mask"
    @focus="setMetaTouched"
    @blur="inputChanged"
  >
    <template #prepend>
      <div
        :class="value == minNumber ? ' disable' : ''"
        class="cursor-pointer minus-icon-holder"
        @click="minus()"
      >
        <PhIconManager
          height="1.25rem"
          width="1.25rem"
          ph-icon="ph-minus-circle"
        />
      </div>
    </template>
    <template #append>
      <div
        class="cursor-pointer plus-icon-holder"
        :class="value == maxNumber ? ' disable' : ''"
        @click="plus()"
      >
        <PhIconManager
          height="1.25rem"
          width="1.25rem"
          ph-icon="ph-plus-circle"
        />
      </div>
    </template>
  </q-input>
  <div
    v-if="veeErrors[0]"
    class="q-field__inner q-field--with-bottom"
  >
    <div class="q-field__bottom row items-start q-field__bottom--animated">
      <div class="q-field__messages col">
        <ValidationMessage
          :messages="veeErrors"
          :info-messages="infoMessages"
          :warning-messages="warningMessages"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { useField } from 'vee-validate'
import { useFormChild } from 'quasar'
import { onMounted, inject } from 'vue'

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  initInputValue: {
    type: [Number, String],
    default: null,
  },
  minNumber: {
    type: Number,
    default: null,
  },
  maxNumber: {
    type: Number,
    default: null,
  },
  validationRules: {
    type: String,
    required: false,
    default: '',
  },
  warningMessageRules: {
    type: String,
    default: '',
  },
  mask: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['update:modelValue'])

const removeValueIfNotValid = inject('_removeValueIfNotValid')

async function validateQForm() {
  // run vee-validate validations
  await veeValidate()

  if (veeErrors.value.length > 0) {
    if(removeValueIfNotValid.value && !meta.valid && meta.touched){
      resetValue();
      return true;
    }
    return false
  } else {
    return true
  }
}
onMounted(() => {
  if (props.initInputValue !== null) {
    value.value = props.initInputValue
    emit('update:modelValue', value.value, props.id)
    validateQForm()
  }
})

function setMetaTouched() {
  meta.touched = true
}

const {
  handleChange,
  errors: veeErrors,
  value,
  meta,
  validate: veeValidate,
} = useField(props.id, props.validationRules, {
  validateOnValueUpdate: false,
  bails: true,
})
function inputChanged(){
  let numberValue = Number(value.value)
  emit('update:modelValue', numberValue)
  validateQForm()
}

function plus() {
  if (value.value < props.maxNumber) {
    value.value++
  }
  emit('update:modelValue', value.value)
  validateQForm()
}
function minus() {
  if (value.value > props.minNumber) {
    value.value--
  }
  emit('update:modelValue', value.value)
  validateQForm()
}

async function resetValue(){
  value.value = null;
  emit('update:modelValue', value.value);
  await validateQForm();
}

function resetValidationQForm() {}

useFormChild({
  validate: validateQForm, // Function; Can be async; // Should return a Boolean (or a Promise resolving to a Boolean)
  resetValidation: resetValidationQForm, // Optional function which resets validation
  requiresQForm: false, // should it error out if no parent QForm is found?
})
</script>
<style lang="scss" scoped>
.numeric-up-down {
  width: 8rem;
  border: 0.0625rem solid #f5f4f8;
  box-shadow: 0rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.06), 0rem 0.0625rem 0.1875rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  background-color: #ffffff;
  padding: 1.125rem 1rem !important;

  :deep(.q-field__inner) {
    .q-field__control {
      align-items: center;
    }
    .q-field__control {
      height: auto;
    }
    .q-field__prepend {
      pointer-events: all;
      height: 1.25rem;
      width: 1.25rem;
      font-size: 0rem;
    }
    .q-field__append {
      pointer-events: all;
      height: 1.25rem;
      width: 1.25rem;
      font-size: 0rem;
    }

    .plus-icon-holder,
    .minus-icon-holder {
      user-select: none;
      height: 1.25rem;
      width: 1.25rem;
      font-size: 0rem;
      svg {
        font-size: 0rem;
        color: $secondary;
      }
    }
    .disable {
      svg {
        color: #b4b9cb;
      }
    }
    .q-field__native {
      padding: 0;
      text-align: center;
    }
    .q-field__append.q-field__marginal.q-anchor--skip {
      display: none;
    }
  }
  @media screen and (max-width: $breakpoint-lg-max) {
    padding: 0.5rem;
    width: 17.9375rem;
    margin-right: 8.8125rem;
    :deep(.q-field__control) {
      height: 2.5rem !important;
    }
    :deep(.q-field__native) {
      min-width: 10.9375rem;
      width: 100%;
    }
    :deep(.q-field__inner) {
      .q-field__prepend,
      .q-field__append {
        height: 1.5rem;
        width: 1.5rem;
      }

      .minus-icon-holder,
      .plus-icon-holder {
        height: 1.5rem;
        width: 1.5rem;

        svg {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
    }
  }
  @media screen and (max-width: $breakpoint-md-max) {
    width: 100%;
  }
}
</style>
