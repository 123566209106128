<template>
  <div class="document-holder-container">
    <div
      v-if="!isSafari"
      class="all-documents-holder"
    >
      <p class="all-documents-paragraph">
        Az alábbi gomb segítségével egyben letöltheti az összes dokumentumot,
        ezután nyilatkozhat tartalmuk megismeréséről, a feltételek
        elfogadásáról.
      </p>
      <q-btn class="download-btn primary">
        <PhIconManager
          width="1rem"
          height="1rem"
          ph-icon="ph-file-arrow-down"
        />
        <div
          class="btn-title"
          @click="DownloadAllDocument"
        >
          Összes dokumentum letöltése
        </div>
      </q-btn>
    </div>
    <div
      v-if="!isSafari"
      class="allagree-holder"
    >
      <CheckboxInput
        id="UT_151"
        class="allagree-checkbox-holder"
        validation-rules="VAL_372"
        :call-revalidate="revalidateAcceptAllStatements"
        @update:model-value="AcceptAllStatements"
      >
        Összes elfogadása
      </CheckboxInput>
    </div>
    <div class="documentum-holder">
      <p class="documentum-paragraph">
        Kijelentem, hogy a biztosítási ajánlat megtétele előtt megismertem az
        Utasbiztosításra vonatkozó biztosítási termékismertetőt.
      </p>
      <q-btn
        class="download-btn"
        @click="DownloadProductGuide"
      >
        <FileArrowDown />
        <div class="btn-title">
          TERMÉKISMERTETŐ LETÖLTÉSE
        </div>
      </q-btn>
      <div class="allagree-holder">
        <CheckboxInput
          id="UT_141"
          v-model:model-value="viewModel.ProductGuide.Value"
          class="allagree-checkbox-holder"
          validation-rules="VAL_154|VAL_159|VAL_164"
          :call-revalidate="productGuideRevalidate"
        >
          Kijelentem
        </CheckboxInput>
      </div>
    </div>
    <div class="documentum-holder">
      <p class="documentum-paragraph">
        Az ajánlat megtételét megelőzően az ügyféltájékoztatót és az
        utasbiztosítási feltételeket megismertem, valamint a megkötendő
        biztosítási szerződéssel összefüggésben a jelen ajánlaton rögzített tájékoztatást kaptam.
      </p>
      <q-btn
        class="download-btn"
        @click="DownloadCivilCode"
      >
        <FileArrowDown />
        <div class="btn-title">
          Ügyfél tájékoztató letöltése
        </div>
      </q-btn>
      <q-btn
        class="download-btn"
        @click="DownloadAcceptCondition"
      >
        <FileArrowDown />
        <div class="btn-title">
          Biztosítási feltételek letöltése
        </div>
      </q-btn>
      <div class="allagree-holder">
        <CheckboxInput
          id="UT_143"
          v-model:model-value="viewModel.AcceptCondition.Value"
          class="allagree-checkbox-holder"
          validation-rules="VAL_230|VAL_231|VAL_232"
          :call-revalidate="acceptConditionRevalidate"
        >
          Elfogadom
        </CheckboxInput>
      </div>
    </div>
    <p class="document-subtitle">
      Felhívjuk a figyelmet, hogy a biztosító a biztosítási szerződésekre
      vonatkozó általános feltételekben külön fejezet(ek)ben foglalta össze a
      Polgári Törvénykönyvtől lényegesen eltérő rendelkezéseket.
    </p>
    <div class="documentum-holder">
      <p class="documentum-paragraph">
        A biztosítási szerződésekre vonatkozó általános feltételeknek a "Polgári
        Törvénykönyvtől lényegesen eltérő rendelkezései" című fejezetben
        foglaltakat kifejezetten elfogadom.
      </p>
      <div class="allagree-holder">
        <CheckboxInput
          id="UT_144"
          v-model:model-value="viewModel.CivilCode.Value"
          class="allagree-checkbox-holder"
          validation-rules="VAL_228|VAL_229|VAL_234"
          :call-revalidate="civilCodeRevalidate"
        >
          Elfogadom
        </CheckboxInput>
      </div>
    </div>
    <div class="documentum-holder">
      <p class="documentum-paragraph">
        Kijelentem, hogy a biztosítási ajánlat megtétele előtt a biztosítási
        igényeimet, szükségleteimet felmérték és a javasolt biztosítási termék
        összhangban van az igényfelmérés során általam megadott adatokkal.
      </p>
      <div class="allagree-holder">
        <CheckboxInput
          id="UT_142"
          v-model:model-value="viewModel.ClaimSurvey.Value"
          class="allagree-checkbox-holder"
          validation-rules="VAL_235|VAL_236|VAL_237"
          :call-revalidate="claimSurveyRevalidate"
        >
          Kijelentem
        </CheckboxInput>
      </div>
    </div>
    <div
      v-if="travelWizardStore.getFromGenerali"
      class="documentum-holder"
    >
      <p class="documentum-paragraph">
        Kijelentem, hogy a biztosításközvetítőre vonatkozó tájékoztatást a
        biztosításközvetítőtől átvettem.
      </p>
      <q-btn
        class="download-btn"
        @click="DownloadMediationInformation"
      >
        <FileArrowDown />
        <div class="btn-title">
          Közvetítői tájékoztató letöltése
        </div>
      </q-btn>
      <div class="allagree-holder">
        <CheckboxInput
          id="UT_155"
          v-model:model-value="viewModel.MediationInformation.Value"
          class="allagree-checkbox-holder"
          validation-rules="VAL_507|VAL_508|VAL_509"
          :call-revalidate="mediationInformationRevalidate"
        >
          Kijelentem
        </CheckboxInput>
      </div>
    </div>
  </div>
</template>
<script setup>
import {inject, onMounted, ref, computed, watch} from 'vue'
import { defineRule } from 'vee-validate'
import { useTravelWizardStore } from '../../stores/travelWizardStore'
import FileArrowDown from '@/components/icons/FileArrowDown.vue'
import * as ValidationMessages from '@/utils/validations/validationMessages.js'
import { customDefineRule } from '@/utils/validations/customDefineRule'

const travelWizardStore = useTravelWizardStore()
const viewModel = ref(travelWizardStore.getViewModel)
const converter = inject('converter')

const isSafari = ref(false)

onMounted(() => {
  if (
    !navigator.userAgent.includes('Chrome') &&
    navigator.userAgent.includes('Safari')
  ) {
    isSafari.value = true
  }
})


const allStatements = ref(false)
const productGuideRevalidate = ref(0)
const mediationInformationRevalidate = ref(0)
const acceptConditionRevalidate = ref(0)
const civilCodeRevalidate = ref(0)
const claimSurveyRevalidate = ref(0)
const revalidateAcceptAllStatements = ref(0)

const increaseValidation = (refValue) => { refValue.value += 1}


const checkAllDocumentOpened = () =>
  viewModel.value.HasCivilCodeOpened.Value &&
  viewModel.value.HasProductGuideOpened.Value &&
  viewModel.value.HasAcceptConditionOpened.Value &&
    (travelWizardStore.getFromGenerali
      ? viewModel.value.HasMediationInformationOpened.Value === true
      : true)

const isAllOpened = computed(() => {
  return (
    viewModel.value.HasAcceptConditionOpened.Value === true &&
    viewModel.value.HasProductGuideOpened.Value === true &&
    viewModel.value.HasCivilCodeOpened.Value === true &&
    (travelWizardStore.getFromGenerali
      ? viewModel.value.HasMediationInformationOpened.Value === true
      : true)
  )
})

function AcceptAllStatements(value) {
  allStatements.value = value
  if (value) {
    viewModel.value.AcceptCondition.Value = true
    viewModel.value.ProductGuide.Value = true
    viewModel.value.ClaimSurvey.Value = true
    viewModel.value.CivilCode.Value = true
    if (travelWizardStore.getFromGenerali) {
      viewModel.value.MediationInformation.Value = true
    }
    if (isAllOpened.value) {
      scrollToBankSection()
    }
  } else {
    viewModel.value.AcceptCondition.Value = false
    viewModel.value.ProductGuide.Value = false
    viewModel.value.ClaimSurvey.Value = false
    viewModel.value.CivilCode.Value = false
    if (travelWizardStore.getFromGenerali) {
      viewModel.value.MediationInformation.Value = false
    }
  }

  if (checkAllDocumentOpened()) {
    increaseValidation(revalidateAcceptAllStatements)
  }
  travelWizardStore.setViewModel(viewModel.value, 'DocInput - 1')
}

async function DownloadAcceptCondition() {
  const link = document.createElement('a')
  downloadCalled.value = true
  link.href = travelWizardStore.getDocuments.biztositasifeltetelek.Link
  link.setAttribute('target', '_blank')
  link.click()
  viewModel.value.HasAcceptConditionOpened.Value = true
  downloadCalled.value = false
  travelWizardStore.setViewModel(viewModel.value, 'DocInput - 2')
  if (viewModel.value.CivilCode.Value) {
    increaseValidation(civilCodeRevalidate)
  }

  if (viewModel.value.AcceptCondition.Value === true) {
    increaseValidation(acceptConditionRevalidate)
  }

  if (viewModel.value.ClaimSurvey.Value === true) {
    increaseValidation(claimSurveyRevalidate)
  }

  if (allStatements.value === true && checkAllDocumentOpened()) {
    increaseValidation(revalidateAcceptAllStatements)
  }
}

const productGuideIsTouched = ref(false)
const acceptConditionIsTouched = ref(false)
const civilCodeIsTouched = ref(false)
const claimSurveyIsTouched = ref(false)
const mediationInformationIsTouched = ref(false)

watch(
  () => viewModel.value.ClaimSurvey.Value,
  () => {
    claimSurveyIsTouched.value = true
  }
)

watch(
  () => viewModel.value.ProductGuide.Value,
  () => {
    productGuideIsTouched.value = true
  }
)

watch(
  () => viewModel.value.AcceptCondition.Value,
  () => {
    acceptConditionIsTouched.value = true
  }
)

watch(
  () => viewModel.value.CivilCode.Value,
  () => {
    civilCodeIsTouched.value = true
  }
)

watch(
  () => viewModel.value.CivilCode.Value,
  () => {
    civilCodeIsTouched.value = true
  }
)

watch(
  () => viewModel.value.MediationInformation.Value,
  () => {
    mediationInformationIsTouched.value = true
  }
)

watch(
  () => viewModel.value.HasProductGuideOpened.Value,
  () => {
    if (productGuideIsTouched.value === true) {
      increaseValidation(productGuideRevalidate)
    }
    if (claimSurveyIsTouched.value === true) {
      increaseValidation(claimSurveyRevalidate)
    }
  }
)

watch(
  () => viewModel.value.HasAcceptConditionOpened.Value,
  () => {
    if (acceptConditionIsTouched.value === true) {
      increaseValidation(acceptConditionRevalidate)
    }
    if(civilCodeIsTouched.value === true) {
      increaseValidation(civilCodeRevalidate)
    }
    if (claimSurveyIsTouched.value === true) {
      increaseValidation(claimSurveyRevalidate)
    }
  }
)

watch(
  () => viewModel.value.HasCivilCodeOpened.Value,
  () => {
    if (acceptConditionIsTouched.value === true) {
      increaseValidation(acceptConditionRevalidate)
    }
    if (civilCodeIsTouched.value === true) {
      increaseValidation(civilCodeRevalidate)
    }
    if (claimSurveyIsTouched.value === true) {
      increaseValidation(claimSurveyRevalidate)
    }
  }
)

watch(
  () => viewModel.value.HasMediationInformationOpened.Value,
  () => {
    if (mediationInformationIsTouched.value === true) {
      increaseValidation(mediationInformationRevalidate)
    }
  }
)

async function DownloadMediationInformation() {
  const link = document.createElement('a')
  downloadCalled.value = true
  link.href = travelWizardStore.getDocuments.kozvetitoitajekoztato.Link
  link.setAttribute('target', '_blank')
  link.click()
  viewModel.value.HasMediationInformationOpened.Value = true
  downloadCalled.value = false
  travelWizardStore.setViewModel(viewModel.value, 'DocInput - 3')
  if (viewModel.value.MediationInformation.Value === true) {
    increaseValidation(mediationInformationRevalidate)
  }

  if (allStatements.value === true && checkAllDocumentOpened()) {
    increaseValidation(revalidateAcceptAllStatements)
  }
}

async function DownloadProductGuide() {
  const link = document.createElement('a')
  downloadCalled.value = true
  link.href = travelWizardStore.getDocuments.termekismerteto.Link
  link.setAttribute('target', '_blank')
  link.click()
  viewModel.value.HasProductGuideOpened.Value = true
  downloadCalled.value = false
  travelWizardStore.setViewModel(viewModel.value, 'DocInput - 4')
  if (viewModel.value.ProductGuide.Value === true) {
    increaseValidation(productGuideRevalidate)
  }

  if (viewModel.value.ClaimSurvey.Value === true) {
    increaseValidation(claimSurveyRevalidate)
  }

  if (allStatements.value === true && checkAllDocumentOpened()) {
    increaseValidation(revalidateAcceptAllStatements)
  }
}

async function DownloadCivilCode() {
  const link = document.createElement('a')
  downloadCalled.value = true
  link.href = travelWizardStore.getDocuments.ugyfeltajekoztato.Link
  link.setAttribute('target', '_blank')
  link.click()
  viewModel.value.HasCivilCodeOpened.Value = true
  downloadCalled.value = false
  travelWizardStore.setViewModel(viewModel.value, 'DocInput - 5')
  if (viewModel.value.CivilCode.Value === true) {
    increaseValidation(civilCodeRevalidate)
  }

  if (viewModel.value.AcceptCondition.Value === true) {
    increaseValidation(acceptConditionRevalidate)
  }

  if (viewModel.value.ClaimSurvey.Value === true) {
    increaseValidation(claimSurveyRevalidate)
  }

  if (allStatements.value === true && checkAllDocumentOpened()) {
    increaseValidation(revalidateAcceptAllStatements)
  }
}
const downloadCalled = ref(false)
function DownloadAllDocument() {
  DownloadAcceptCondition()
  DownloadProductGuide()
  DownloadCivilCode()
  if (travelWizardStore.getFromGenerali) {
    DownloadMediationInformation()
  }
  viewModel.value.HasAcceptConditionOpened.Value = true
  viewModel.value.HasProductGuideOpened.Value = true
  viewModel.value.HasCivilCodeOpened.Value = true
  if (travelWizardStore.getFromGenerali) {
    viewModel.value.HasMediationInformationOpened.Value = true
  }
  travelWizardStore.setViewModel(viewModel.value, 'DocInput - 6')
}

function scrollToBankSection() {
  const bankcardField = document.querySelector('.bankcard-container')
  if (bankcardField) {
    bankcardField.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    })
  }
}

customDefineRule('VAL_230', (value) => {
  if (
    value === false &&
    (viewModel.value.HasAcceptConditionOpened.Value === undefined ||
      viewModel.value.HasAcceptConditionOpened.Value === false ||
      viewModel.value.HasCivilCodeOpened.Value === undefined ||
      viewModel.value.HasCivilCodeOpened.Value === false)
  ) {
    return ValidationMessages.VAL_230
  } else {
    return true
  }
})

customDefineRule('VAL_231', (value) => {
  if (
    value === true &&
    (viewModel.value.HasAcceptConditionOpened.Value === undefined ||
      viewModel.value.HasAcceptConditionOpened.Value === false ||
      viewModel.value.HasCivilCodeOpened.Value === undefined ||
      viewModel.value.HasCivilCodeOpened.Value === false)
  ) {
    return ValidationMessages.VAL_231
  } else {
    return true
  }
})

customDefineRule('VAL_232', (value) => {
  if (
    value === false &&
      viewModel.value.HasAcceptConditionOpened.Value === true &&
      viewModel.value.HasCivilCodeOpened.Value === true
  ) {
    return ValidationMessages.VAL_232
  } else {
    return true
  }
})

customDefineRule('VAL_154', (value) => {
  if (
    value === false && viewModel.value.HasProductGuideOpened.Value !== true
  ) {
    return ValidationMessages.VAL_154
  } else {
    return true
  }
})

customDefineRule('VAL_159', (value) => {
  if (value === true && viewModel.value.HasProductGuideOpened.Value !== true) {
    return ValidationMessages.VAL_159
  } else {
    return true
  }
})

customDefineRule('VAL_164', (value) => {
  if (
    value === false &&
    (viewModel.value.HasProductGuideOpened.Value !== undefined ||
      viewModel.value.HasProductGuideOpened.Value === true) &&
    downloadCalled.value == false
  ) {
    return ValidationMessages.VAL_164
  } else {
    return true
  }
})

customDefineRule('VAL_235', (value) => {

  if (
    value === false &&
    (viewModel.value.HasAcceptConditionOpened.Value === undefined ||
      viewModel.value.HasAcceptConditionOpened.Value === false ||
      viewModel.value.HasCivilCodeOpened.Value === undefined ||
      viewModel.value.HasCivilCodeOpened.Value === false ||
      viewModel.value.HasProductGuideOpened.Value === undefined ||
      viewModel.value.HasProductGuideOpened.Value === false)
  ) {
    return ValidationMessages.VAL_235
  } else {
    return true
  }
})

customDefineRule('VAL_372', (value) => {
  if (
    value === true &&
    (viewModel.value.HasAcceptConditionOpened.Value === undefined ||
      viewModel.value.HasAcceptConditionOpened.Value === false ||
      viewModel.value.HasProductGuideOpened.Value === undefined ||
      viewModel.value.HasProductGuideOpened.Value === false)
  ) {
    return ValidationMessages.VAL_372
  } else {
    return true
  }
})

customDefineRule('VAL_236', (value) => {
  if (value === true && (
    viewModel.value.HasAcceptConditionOpened.Value === undefined ||
      viewModel.value.HasAcceptConditionOpened.Value === false ||
      viewModel.value.HasCivilCodeOpened.Value === undefined ||
      viewModel.value.HasCivilCodeOpened.Value === false ||
      viewModel.value.HasProductGuideOpened.Value === undefined ||
      viewModel.value.HasProductGuideOpened.Value === false)
  ) {
    return ValidationMessages.VAL_236
  } else {
    return true
  }
})

customDefineRule('VAL_372_MEDIATION', (value) => {
  if (
    value === true && viewModel.value.HasMediationInformationOpened.Value !== true
  ) {
    return ValidationMessages.VAL_372_MEDIATION
  } else {
    return true
  }
})

customDefineRule('VAL_237', (value) => {
  if (
    value === false &&
    (viewModel.value.HasCivilCodeOpened.Value === true &&
      viewModel.value.HasCivilCodeOpened.Value === true &&
      viewModel.value.HasProductGuideOpened.Value === true)
  ) {
    return ValidationMessages.VAL_237
  } else {
    return true
  }
})

customDefineRule('VAL_228', (value) => {
  if (
    value === false &&
    (viewModel.value.HasAcceptConditionOpened.Value === undefined ||
      viewModel.value.HasAcceptConditionOpened.Value === false ||
      viewModel.value.HasCivilCodeOpened.Value === undefined ||
      viewModel.value.HasCivilCodeOpened.Value === false)
  ) {
    return ValidationMessages.VAL_228
  } else {
    return true
  }
})

customDefineRule('VAL_229', (value) => {
  if (
    value === true &&
    (viewModel.value.HasAcceptConditionOpened.Value === undefined ||
      viewModel.value.HasAcceptConditionOpened.Value === false ||
      viewModel.value.HasCivilCodeOpened.Value === undefined ||
      viewModel.value.HasCivilCodeOpened.Value === false)
  ) {
    return ValidationMessages.VAL_229
  } else {
    return true
  }
})

customDefineRule('VAL_234', (value) => {
  if (
    value === false &&
    viewModel.value.HasAcceptConditionOpened.Value === true &&
    viewModel.value.HasCivilCodeOpened.Value === true
  ) {
    return ValidationMessages.VAL_234
  } else {
    return true
  }
})
customDefineRule('VAL_100_CHECKBOX_DOCUMENTS', (value) => {
  if ((value == false || value == null) && downloadCalled.value == false) {
    return ValidationMessages.VAL_100
  }
  return true
})


customDefineRule('VAL_507', (value) => {
  if (
    value === false && viewModel.value.HasMediationInformationOpened.Value !== true
  ) {
    return ValidationMessages.VAL_507
  } else {
    return true
  }
})

customDefineRule('VAL_508', (value) => {
  if (value === true && viewModel.value.HasMediationInformationOpened.Value !== true) {
    return ValidationMessages.VAL_508
  } else {
    return true
  }
})

customDefineRule('VAL_509', (value) => {
  if (value === false && viewModel.value.HasMediationInformationOpened.Value === true) {
    return ValidationMessages.VAL_509
  } else {
    return true
  }
})
</script>
<style lang="scss" scoped>
.document-holder-container {
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  :deep(.q-item__label) {
    font-size: 0.75rem;
  }
  :deep(.q-checkbox) {
    align-items: flex-end;
  }

  @media screen and (max-width: $breakpoint-xs-max) {
    padding: unset;
    margin-left: 0.5rem;
  }
}
.all-documents-paragraph {
  padding-bottom: 1rem;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.download-btn {
  min-height: unset;
  padding: 0;
  border-radius: 0.375rem;
  align-items: none;
  width: fit-content;
  @media screen and (max-width: $breakpoint-xs-max) {
    width: 100% !important;
  }
  :deep(.q-btn__content) {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    border: 0.0625rem solid #4e95fb;
    border-radius: 0.375rem;
    background-color: white;
    padding: 0.25rem 0.75rem;
    @media screen and (max-width: $breakpoint-xs-max) {
      padding: 0.5rem 0.8rem;
    }
    .btn-title {
      width: auto;
      color: #4e95fb;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 1rem;
      @media screen and (max-width: $breakpoint-xs-max) {
        font-size: 14px;
      }
    }
    svg {
      width: auto;
      max-width: unset;
      color: #4e95fb;
    }
  }
  &.primary {
    :deep(.q-btn__content) {
      @media screen and (max-width: $breakpoint-xs-max) {
        padding: 0.5rem 1rem;
      }
      background-color: #4e95fb;
      .btn-title {
        width: auto;
        color: white;
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 1rem;
        @media screen and (max-width: $breakpoint-xs-max) {
          font-size: 14px;
        }
      }
      svg {
        width: auto;
        max-width: unset;
        color: white;
      }
    }
  }
}
.allagree-holder {
  @media screen and (max-width: $breakpoint-xs-max) {
    background: #ffffff;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0px 1px 3px 0px #0000001a;
    :deep(.q-checkbox) {
      margin-top: unset !important;
    }
    :deep(.q-item__label) {
      font-size: 14px;
    }
  }
}
.allagree-checkbox-holder {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  align-items: center;
  margin: 0 !important;
}
.documentum-holder {
  background: #ffffff;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem 1rem;
  .documentum-paragraph {
    margin: 0;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: #4a567c;
  }
  :deep(.q-field__inner) {
    padding-bottom: 0;
  }
}
@media screen and (max-width: $breakpoint-lg-max) {
  .document-holder-container {
    width: 100%;
  }
}
@media screen and (max-width: $breakpoint-xs-max) {
  .document-holder-container {
    margin: 0;
  }
}
.document-subtitle {
  font-weight: 500;
  font-size: 0.75rem !important;
  line-height: 1.25rem;
  color: #4a567c;
  margin: 0 !important;
}
</style>
