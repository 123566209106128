<template>
  <q-select
    :id="id"
    v-model="value"
    :popup-content-class="'custom-popup-height'"
    :class="[
      value && isAutocomplete ? 'hasValue ' + customClass : customClass,
      warningMessages?.length > 0 && veeErrors.length == 0 ? 'has-warning' : '',
      isDestinationInput ? 'no-dropdown-arrow' : ''
    ]"
    :options="selectOptions"
    standout
    virtual-scroll-item-size="32"
    :error="!!veeErrors[0] || warningMessages?.length > 0"
    :label="label"
    :use-input="isAutocomplete"
    emit-value
    :bg-color="isDisabled ? '#EEF0F7' : 'white'"
    map-options
    :stack-label="isStackLabel"
    :maxlength="maxLength"
    :readonly="isReadonly"
    behavior="menu"
    :disable="isDisabled"
    @blur="onBlur"
    @keydown="keyDown"
    @filter="filterFn"
    @update:model-value="
      handleChange($event),
      handleInfoWarningMessages(value),
      inputChanged(value)
    "
    @new-value="addValue"
    @focus="onFocus($event), setMetaTouched($event)"
  >
    <template #label>
      {{ label }}
      <Tooltip :tooltip-content="tooltipContent" />
    </template>
    <template #append>
      <PhIconManager
        ph-icon="ph-caret-down"
        :size="16"
      />
    </template>
    <template #error>
      <ValidationMessage
        :messages="veeErrors"
        :info-messages="infoMessages"
        :warning-messages="warningMessages"
      />
    </template>
    <template #no-option>
      <q-item>
        <q-item-section class="text-grey">
          {{ 'Nincs találat' }}
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script setup>
import { useField } from 'vee-validate'
import { useFormChild } from 'quasar'
import { onMounted, ref, watch, inject } from 'vue'
import {
  getInfoMessagesForValue,
  getWarningMessagesForValue,
} from '@/utils/validations/customValidator.js'
const { getFormattedNumber } = inject('util')

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: false,
  },
  options: {
    type: Array,
    required: false,
    default: () => [
      {
        label: 'opció1',
        value: '1',
      },
      {
        label: 'opció2',
        value: '2',
      },
      {
        label: 'alap opctió',
        value: '3',
      },
      {
        label: 'megint alap opció',
        value: '4',
      },
    ],
  },
  tooltipContent: {
    type: String,
    required: false,
    default: '',
  },
  validationRules: {
    type: String,
    required: false,
    default: '',
  },
  infoMessageRules: {
    type: String,
    required: false,
    default: '',
  },
  warningMessageRules: {
    type: String,
    required: false,
    default: '',
  },
  modelValue: {
    type: [String, Object],
    required: false,
    default: '0',
  },
  initOptionValue: {
    type: Number,
    default: null,
  },
  isAutocomplete: {
    type: Boolean,
    default: false,
  },
  isReadonly: {
    type: Boolean,
    default: false,
  },
  isStackLabel: {
    type: Boolean,
    default: false,
  },
  customClass: {
    type: String,
    default: null,
  },
  popupCustomClass: {
    type: String,
    default: null
  },
  inputType: {
    type: String,
    default: '',
  },
  maxLength: {
    type: Number,
    default: -1,
  },
  addNewValue: {
    type: Boolean,
    default: false,
  },
  isAddressInput: {
    type: Boolean,
    default: false,
  },
  isDestinationInput: {
    type: Boolean,
    default: false,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  texts: {
    type: Object,
    default: () => ({}),
  },
  formatNumber: {
    type: Boolean,
    required: false,
    default: false,
  },
  warningMessagesTarget: {
    type: Object,
    required: false,
    default: () => {},
  },
})

const emit = defineEmits(['update:modelValue', 'updateSelectedLabel'])

const removeValueIfNotValid = inject('_removeValueIfNotValid')

const infoMessages = ref(null)
const warningMessages = ref(null)
const selectOptions = ref(null)
const optionsArray = ref([])
const keyString = ref('')
const inputChangedTrigger = ref(false)
const iValue = ref(0)
function getText(textId) {
  if (props.texts && textId && textId.includes('vtxt')) {
    return props.texts[textId]
  } else {
    return textId
  }
}
onMounted(() => {
  if (props.initOptionValue !== null) {
    const indexOf = props.options.findIndex(
      (_) => _.value == props.initOptionValue
    )
    if (indexOf >= 0) {
      value.value = props.options[indexOf].value
      handleInfoWarningMessages(value.value)
    } else {
      value.value = null
    }
  }
  if (props.options != null && props.options.length > 0) {
    props.options.forEach((element) => {
      if (props.formatNumber) {
        element.label = getFormattedNumber(getText(element.label))
      } else {
        element.label = getText(element.label)
      }
    })
  }
  selectOptions.value = props.options
  optionsArray.value = props.options
})

const {
  handleChange,
  errors: veeErrors,
  value,
  meta,
  validate: veeValidate,
} = useField(props.id, props.validationRules, {
  validateOnValueUpdate: false,
})

useFormChild({
  validate: validateQForm, // Function; Can be async; // Should return a Boolean (or a Promise resolving to a Boolean)
  resetValidation: resetValidationQForm, // Optional function which resets validation
  requiresQForm: false, // should it error out if no parent QForm is found?
})
function onFocus() {
  if (props.isAutocomplete) {
    value.value = null
    veeErrors.value = []
    selectOptions.value = []
    optionsArray.value = []
  }
}

function setMetaTouched() {
  meta.touched = true
}

async function validateQForm() {
  // run vee-validate validations
  await veeValidate()

  if (veeErrors.value.length > 0) {
    if (removeValueIfNotValid.value && !meta.valid && meta.touched) {
      value.value = null
      inputChanged()
      return true
    }
    return false
  } else {
    return true
  }
}

function inputChanged() {
  emit('update:modelValue', value.value)

  let selectedLabel = optionsArray.value.find(
    (element) => element.value == value.value
  )
  if (selectedLabel != undefined)
    emit('updateSelectedLabel', selectedLabel.label)
  else emit('updateSelectedLabel', '')
  validateQForm()
}

function resetValidationQForm() {}

function handleInfoWarningMessages(value) {
  infoMessages.value = getInfoMessagesForValue(value, props.infoMessageRules)
  warningMessages.value = getWarningMessagesForValue(
    value,
    props.warningMessageRules,
    props.warningMessagesTarget
  )
}

function filterFn(value, update, abort) {
  if (props.isAutocomplete) {
    update(() => {
      if (props.isDestinationInput) {
        selectOptions.value = props.options.filter(
          (v) => v.label.toLowerCase().indexOf(value.toLowerCase()) === 0
        )
      } else {
        selectOptions.value = props.options.filter(
          (v) => v.label.toLowerCase().indexOf(value.toLowerCase()) > -1
        )
      }

      if (selectOptions.value.length === 0 && props.isAddressInput) {
        selectOptions.value.push(value)
      }
    })
  } else {
    update(() => {
      selectOptions.value = props.options
    })
  }
}

function keyDown(e) {
  e = e ? e : window.event

  const numberRegex = /^[0-9]$/
  const textRegex = /^[a-zA-ZáéíóöőúüűÁÉÍÓÖŐÚÜŰ ]+$/
  const isDeleteOrBackspace = e.keyCode === 8 || e.keyCode === 46

  if (props.inputType !== 'year-number') {
    if (props.maxLength > 0) {
      if ((e.keyCode === 8 || e.keyCode === 46) && iValue.value > 0) {
        iValue.value--
      } else if (props.maxLength >= 0 && iValue.value < props.maxLength) {
        if (e.key.match(/^[a-zA-Z0-9_.-]*$/u)) {
          iValue.value++
        }
      } else {
        e.preventDefault()
        return
      }
    }
    else {
      if (keyString.value.length >= 0) {
        if (inputChangedTrigger.value || value.value !== null) {
          if (isDeleteOrBackspace) {
            keyString.value = ''
            return
          }
        }
        const firstChar = keyString.value[0]
        const isNumber = numberRegex.test(firstChar)
        const isValidTextChar = textRegex.test(e.key)
        const isValidNumberChar = numberRegex.test(e.key)
        if (isDeleteOrBackspace) {
          keyString.value = keyString.value.slice(1)
        } else if (isNumber && keyString.value.length < 4 && isValidNumberChar && e.key.length == 1) {
          keyString.value += e.key
        } else if (!isNumber && keyString.value.length < 30 && isValidTextChar && e.key.length == 1) {
          keyString.value += e.key
        } else {
          e.preventDefault()
          return
        }
      } else if (
        (textRegex.test(e.key) || numberRegex.test(e.key)) &&
        !isDeleteOrBackspace && e.key.length == 1
      ) {
        keyString.value += e.key
      } else if (!isDeleteOrBackspace) {
        e.preventDefault()
      }
      // inputChangedTrigger.value = false
    }
  }

  switch (props.inputType) {
    case 'text-with-space': {
      let isValidKey = containsLettersAndSpace(e.key)
      if (!isValidKey) {
        e.preventDefault()
      }
      return
    }
    case 'max-length': {
      if ((e.keyCode === 8 || e.keyCode === 46) && iValue.value > 0) {
        iValue.value--
      } else if (props.maxLength >= 0 && iValue.value < props.maxLength) {
        if (e.key.match(/^[a-zA-Z0-9_.-]*$/u)) {
          iValue.value++
        }
      } else {
        e.preventDefault()
      }
      return
    }
    case 'readonly': {
      e.preventDefault()
    }
    case 'year-number': {
      if ((e.keyCode === 8 || e.keyCode === 46) && iValue.value > 0) {
        iValue.value = ''
      } else if (e.key.match(/^[0-9]*$/u)) {
        if (iValue.value.length < 4) {
          iValue.value = iValue.value + e.key
        }
      } else {
        e.preventDefault()
      }
      return
    }
    case 'street-name': {
      let isValidKey = containsLettersSpaceDotHyphen(e.key)
      if (!isValidKey) {
        e.preventDefault()
      }
      return
    }
    default: {
      return
    }
  }
}

function containsLettersAndSpace(str) {
  return /^[\p{L} ]+$/u.test(str)
}

function containsLettersSpaceDotHyphen(str) {
  return /^[a-záéíóöőúüűäA-ZÁÉÍÓÖŐÚÜŰÄ0-9.\/\- ]+$/u.test(str)
}

function onBlur(e) {
  if (props.inputType !== 'year-number') {
    iValue.value = 0
  }
}

function addValue(val, done) {
  if (!props.addNewValue) return

  if (val.length > 0) {
    if (
      !optionsArray.value.filter(
        (v) => v.label.toLowerCase() == val.toLowerCase()
      ).length > 0
    ) {
      optionsArray.value.push({
        value: val,
        label: val,
      })
    }
    done(
      {
        value: val,
        label: val,
      },
      'add-unique'
    )
  }
}

watch(
  () => props.options,
  (value) => {
    optionsArray.value = value
  }
)
</script>

<script>
export default {
  name: 'SelectInput',
}
</script>

<style lang="scss" scoped>
.custom-popup-height{
  max-height: 160px;
}
.hasValue {
  :deep(.q-field__input) {
    display: none !important;
  }
}
:deep(.q-field--standout) {
  &.q-field * {
    border-width: 0 !important;
  }
  .q-field__label {
    color: #4a567c !important;
  }
  .q-field__control:before {
    background-color: transparent;
  }
  &.q-field--highlighted .q-field__control {
    box-shadow: none;
  }

  .q-field__control {
    border-radius: $half !important;
    padding: 0 1rem !important;
    box-shadow: inset 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.06) !important;
    border: 1px solid #f5f4f8 !important;
  }

  .q-field__label {
    font-size: $font-size-sm;
    line-height: $line-height-sm;
  }

  .q-field--float {
    .q-field__label {
      transform: translateY(-40%) scale(10 / 12) !important;
    }
  }

  .q-field__native.row.items-center {
    display: none !important;
  }

  .q-field__prefix,
  .q-field__suffix,
  .q-field__input {
    font-weight: $font-weight-bold !important;
  }

  .q-field__append {
    background: transparent !important;
    width: auto !important;
  }
}

.q-field--standout:hover {
  .q-field__control {
    background: red !important;
    border: 1px solid $border-hover !important;
  }
}

:deep(.q-field--standout.q-field--focused) {
  .q-field__control {
    border: 1px solid $border-active !important;
  }
}


:deep(.q-field--float:hover) {
  .q-field__control {
    border: 0.125rem solid $border-data-hover !important;
    background-color: $input-data-bg-hover !important;
  }
}

:deep(.q-field--float:hover) {
  &.q-field--focused {
    .q-field__control {
      border: 1px solid $border-active !important;
    }
  }
}
.q-field--focused:hover {
  :deep(.q-field__control) {
    background-color: white !important;
    border: 1px solid $border-active !important;
  }
}

:deep(.q-field--error) {
  & * {
    color: $input-text-validation !important;
  }

  .q-field__control {
    border: 1px solid $border-validation !important;
    background-color: white !important;
  }
  span {
    color: red;
  }
}

:deep(.q-field--standout) {
  &.q-field.q-field--error * {
    color: $input-text-validation !important;
  }
}

:deep(.q-field--standout.q-field .q-field__append) {
  background: transparent !important;
  width: auto !important;

  svg,
  svg * {
    color: $N-600 !important;
  }
}

:deep(.q-field__append.q-field__marginal.row.no-wrap.items-center) {
  width: auto;
}

:deep(.q-field__control-container.col.relative-position.row.no-wrap.q-anchor--skip) {
  font-size: 0.875rem;
  span {
    line-height: 1.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.q-field--error {
  :deep(.q-field__control-container.col.relative-position.row.no-wrap.q-anchor--skip) {
    span {
      color: $danger !important;
    }
  }
}

:deep(.q-field__suffix.no-pointer-events.row.items-center) {
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #0c1224 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  margin-left: 0.3125rem !important;
}

:deep(.q-field__label.no-pointer-events.absolute.ellipsis) {
  color: #4a567c !important;
  align-items: center !important;
}

.no-dropdown-arrow {
  .q-field__append.q-field__marginal.row.no-wrap.items-center {
    background: transparent !important;
    width: auto !important;
    svg,
    svg * {
      color: transparent !important;
    }
  }
  :deep(.q-field__append) {
    display: none;
  }
}

.no-placeholder-value {
  :deep(.q-field__label.no-pointer-events.absolute.ellipsis) {
    color: #4a567c !important;
    order: 1;
    position: absolute;
  }
  :deep(.q-field__native.row.items-center) {
    order: 2;
  }
}
.q-field--standout.q-select.q-field--float:hover {
  :deep(.q-field__control) {
    border: 1px solid $border-normal;
  }
}
</style>
