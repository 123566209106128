//ERROR:
const VAL_100 = 'Kérjük, válaszoljon!'
const VAL_100_DATEPICKER_START_DATE = 'Kérjük, válasszon kezdő dátumot!'
const VAL_103 = 'Kérjük, adja meg a teljes nevét! (Vezetéknév Keresztnév)'
const VAL_104 =
  'Kérjük, ellenőrizze, hogy megfelelően adta-e meg a nevét! A név mező nem tartalmazhat különleges karaktert vagy számot.'
const VAL_105 =
  'Nem megfelelő formátum. Kérjük, ellenőrizze, hogy megfelelően adta-e meg telefonszámát! 06 20 1111111'
const VAL_106 = 'Kérjük, ellenőrizze, hogy jól adta-e meg email címét! (pl. valaki@valami.hu)'
const VAL_107 = 'A bankszámlaszám formátuma hibás! Elvárt formátum kétszer vagy háromszor 8 számjegy, speciális karakterek (pl. kötőjel) és szóközök nélkül.'
const VAL_111 = 'A megadott irányítószám rendszerünk szerint nem létezik. Kérjük, keresse ügyfélszolgálatunkat a 06 (1) 288 0000 telefonszámon!'
const VAL_114 = 'Biztosítást csak 18 éven felüli személyek köthetnek.'
const VAL_115 =
  'A biztosítás kezdő napja nem lehet a mai napnál korábbi időpont!'
const VAL_116 =
  'A biztosítás vége nem lehet korábbi időpont, mint a biztosítás kezdő napja!'
const VAL_126 = 'A szerződésnek minimum 1 biztosítottat tartalmaznia kell!'
const VAL_128 = 'A szerződéshez maximum 10 biztosított tartozhat!'
const VAL_131 = 'A szerződéshez maximum 9 biztosított gépjármű tartozhat'
const VAL_178 =
  'A szerződésnek minimum 1 felnőtt biztosítottat tartalmaznia kell!'
const VAL_238 = 'Erre a gépjárműre casco biztosítás nem köthető!'
const VAL_297 = 'A biztosítás időtartama maximum 30 nap lehet!'
const VAL_191 = 'A rendszámnak legalább 6 karakterből kell állnia!'
const VAL_191_BETWEEN_6_8 = 'A rendszámnak legalább 6, maximum 8 karakterből kell állnia!'
const VAL_206 = 'Az email cím formátuma hibás!'
const VAL_299 = 'Hibás formátumú érték!'
const VAL_333 = 'A megadott dátum nem megfelelő, kérjük, ellenőrizze!'
const VAL_345 =
  'A szóköz és a különleges karakterek használata ebben a mezőben nem lehetséges.'
const VAL_320 = '90 napnál távolabbi útra nem köthető biztosítás!'
const VAL_346 = 'Nem megfelelő a rendszám formátuma.'
const VAL_108 = 'A mezőben megadott értéknek 11 számból kell állnia.'
const VAL_183 = 'A mezőben megadott értéknek 6 karakterből kell állnia!'
const VAL_184 = 'A mezőben megadott értéknek 4 karakterből kell állnia!'
const VAL_322 = 'Maximum 9 gyerek biztosított tartozhat egy szerződéshez!'
const VAL_349 = 'A megadott email címek eltérnek.'
const VAL_284 = 'A megadott kötvényszám formátuma hibás!'
const VAL_467 =
  'Kérjük, ellenőrizze, hogy megfelelően adta-e meg! A mező nem tartalmazhat különleges karaktert.'
const VAL_148 = 'A megadott kuponkód sajnos már lejárt.'
const VAL_149 = 'A megadott kuponkódot korábban már felhasználta.'
const VAL_ACCIDENT_MAX = 'A biztosítottak száma nem haladhatja meg a tizet.'
const VAL_132 =
  'Az általános ingóságok összértéke nem haladhatja meg a 25 000 000 forintot. Amennyiben ezen érték felett szeretne biztosítani, kérjük, vegye fel a kapcsolatot ügyfélszolgálatunkkal a 06 (1) 288 0000 telefonszámon.'
const VAL_378 =
  'Az ingatlan biztosítási összege nem haladhatja meg a 200 000 000 forintot. Amennyiben ezen érték felett szeretne biztosítani, kérjük, vegye fel a kapcsolatot ügyfélszolgálatunkkal a 06 (1) 288 0000 telefonszámon.'
const VAL_407 = 'Bérlőként csak ingóságra köthet biztosítást.'
const VAL_442 = 'Erre az ingatlanra biztosítás nem köthető.'
const VAL_356 =
  'Biztosítás legfeljebb 350 négyzetméter alapterületű ingatlanra köthető.'
const VAL_123 =
  'A biztosítandó ingatlan alapterülete nem lehet kisebb 10 négyzetméternél.'
const VAL_329 =
  'A megadott évszám nem megfelelő formátumú, kérjük, 4 számjegyet adjon meg!'
const VAL_330 = 'A megadott dátum nem lehet jövőbeli.'
const VAL_357 = '1800 előtt épült ingatlanra biztosítás nem köthető.'
const VAL_135 = 'Vályog falazatú épületre biztosítás nem köthető.'
const VAL_402 = 'Vegyes falazatú épületre biztosítás nem köthető.'
const VAL_405 =
  'Nád/Fazsindely/Szalma fedésű ingatlanra biztosítás nem köthető.'
const VAL_406 = 'Külterületen fekvő ingatlanra nem köthető biztosítás.'
const VAL_433 =
  'Az Építés-, szerelésbiztosítás kiegészítő fedezetet automatikusan hozzáadjuk ajánlatához. Ezt a kiegészítő biztosítást egy későbbi lépésben eltávolíthatja, azonban kár esetén csak akkor fizet a biztosító, ha ezt a kiegészítő biztosítást igyénybe veszi. A felújítás/építés/átépítés befejezésével ügyfélszolgálatunkon lehetősége van kérni a kiegészítő fedezet törlését.'
const VAL_409 = 'Lakatlan épületre biztosítás nem köthető.'
const VAL_403 =
  'Vállalkozás céljára használt ingatlanra biztosítás nem köthető.'
const VAL_411 = 'A megadott dátum nem megfelelő, kérjük, ellenőrizze!'
const VAL_363 =
  'A főépületben tárolt ingóságok biztosítási összege maximum 25 000 000 forint lehet.'
const VAL_364 =
  'Az értéktárgyak biztosítási összege maximum 5 000 000 forint lehet.'
const VAL_413 = 'Lakásbiztosítást csak fogyasztó köthet.'
const VAL_420 =
  'Az értéktárgyak 200 000 forint feletti részére az „Értéktárgy kiegészítő biztosítás (200 000 Ft érték felett)” kiegészítő biztosításban nyújtunk fedeztet, melyet hozzáadtunk ajánlatához.'
const VAL_445 =
  'Az általános ingóságok minimális biztosítási összege: {0} forint lehet.'
const VAL_464 =
  'A megadott beállításokkal egyik lakásbiztosításunk sem köthető.'
const VAL_465 =
  'A megadott beállításokkal Start Minősített Fogyasztóbarát Otthonbiztosítás köthető, melyet a díjszámítás későbbi oldalán választhat ki.'
const VAL_414 =
  'Több mint 60 nappal előre Genertel Lakásbiztosításunk csomagjai (Start+, Komfort, Extra) közül választhat, Start Minősített Fogyasztóbarát Otthonbiztosítást nem köthet. Amennyiben kizárólag ez a biztosítási termék érdekli, térjen vissza később.'
const VAL_415 = 'Több mint 180 nappal előre nem köthet lakásbiztosítást.'
const VAL_421 = 'Lakásbiztosítást legfeljebb 60 napra előre köthet.'
const VAL_458 =
  'A biztosítási időszak kezdete nem elsejére esik, ezért az első díjrészlettel együtt az aktuális tört időszak (hónap vagy negyedév) díját is szükséges megfizetni. Ennek összegét az adatellőrző oldalon tesszük közzé.'
const VAL_393 =
  'A nyereményjátékban történő részvétel egyik feltétele a marketing hozzájárulás megadása. Kérjük, ha részt szeretne venni, fogadja el a marketing nyilatkozatot. '
const VAL_408 = 'Lakatlan épületre biztosítás nem köthető.'
const VAL_361 =
  'A melléképület(ek)ben tárolt ingóságok összesített biztosítási összege maximum 10 000 000 forint lehet.'
const VAL_447 =
  'A mező kalkulált értékének felülírásával töröljük a részletesen megadott adatokat.'
const VAL_459 =
  'Többszerződéses kedvezmény a START Minősített Fogyasztóbarát Otthonbiztosítás esetében nem vehető igénybe!'
const VAL_460 =
  'Ez a kedvezmény csak Start+, Komfort és Extra lakásbiztosítás esetén érvényesíthető!'
const VAL_146 =
  'Helytelen partner azonosító, kérjük, ellenőrizze, hogy megfelelő kódot adott-e meg!'
const VAL_372 =
  'Kérjük, nyissa meg és olvassa végig az összes kapcsolódó dokumentumot!'
const VAL_372_MEDIATION =
  'Kérjük, nyissa meg és olvassa végig az összes kapcsolódó dokumentumot!'
const VAL_156 =
  'Kérjük, nyissa meg, olvassa végig és jelölje be, hogy megismerte és elfogadja a biztosítási feltételeket, illetve, hogy az azokban foglaltakon túl további tájékoztatást nem kapott!'
const VAL_161 =
  'Kérjük, nyissa meg és olvassa végig a biztosítási feltételeket!'
const VAL_161_TRAVEL =
  'Kérjük, nyissa meg és olvassa végig a biztosítási feltételeket és az ügyfél tájékoztatót!'
const VAL_166 =
  'Kérjük, jelölje be, hogy megismerte és elfogadja a biztosítási feltételeket, illetve, hogy az azokban foglaltakon túl további tájékoztatást nem kapott!'
const VAL_154 =
  'Kérjük, nyissa meg a termékismertető dokumentumot, olvassa végig és jelölje be, hogy megismerte annak tartalmát!'
const VAL_159 =
  'Kérjük, nyissa meg a termékismertető dokumentumot és olvassa végig!'
const VAL_164 =
  'Kérjük, jelölje be, hogy megismerte a termékismertető dokumentum tartalmát!'
const VAL_371 =
  'Kérjük, nyissa meg, olvassa végig és jelölje be, hogy megismerte és elfogadja az összes kapcsolódó dokumentumot!'
const VAL_373 =
  'Kérjük, jelölje be, hogy megismerte és elfogadja az összes kapcsolódó dokumentumot, illetve hogy a biztosítási ajánlat írásba foglalása előtt a biztosítási igényeit, szükségleteit felmérték és a javasolt biztosítási termék összhangban van az igényfelmérés során általa megadott adatokkal!'
const VAL_157 =
  'Kérjük, jelölje be, hogy megismerte és elfogadja a biztosítási feltételeknek a Polgári Törvénykönyvtől és a szokásos szerződési gyakorlattól lényegesen eltérő rendelkezéseit tartalmazó fejezeteiben foglaltakat!'
const VAL_162 =
  'Kérjük, nyissa meg és olvassa végig a biztosítási feltételek Polgári Törvénykönyvtől és a szokásos szerződési gyakorlattól lényegesen eltérő rendelkezéseit tartalmazó fejezeteiben foglaltakat!'
const VAL_216 =
  'Kérjük, jelölje be, hogy megismerte és elfogadja a biztosítási feltételek Polgári Törvénykönyvtől lényegesen eltérő rendelkezéseit tartalmazó fejezeteiben foglaltakat!'
const VAL_359 =
  'A melléképület(ek) alapterülete összesen legfeljebb 150 négyzetméter lehet.'
const VAL_147 =
  'A megadott kuponkód nem létezik. Kérjük, ellenőrizze, hogy helyesen adta-e meg.'
const VAL_466 =
  'Köszönjük, hogy ismét a Genertelt választja, hűségét 5% kedvezménnyel díjazzuk. A hűségkedvezmény érvényesítéséhez adja meg korábbi szerződése kötvényszámát.'
const VAL_261 =
  'Ilyen kötvényszám nem található a rendszerünkben, kérjük ellenőrizze!'
const VAL_423 =
  'A megadott beállításokkal egyik lakásbiztosításunk sem köthető, kérjük, módosítson a kiválasztott fizetési ütemen és/vagy fizetési módon.'
const VAL_379 =
  'Az ingatlan biztosítási összege nem haladhatja meg a 200 000 000 forintot. Amennyiben ezen érték felett szeretne biztosítani, kérjük, vegye fel a kapcsolatot ügyfélszolgálatunkkal a 06 (1) 288 0000 telefonszámon.'
const VAL_444 = 'Az ingatlan minimális biztosítási összege: {0} forint lehet.'
const VAL_380 = 'A melléképület(ek) összesített biztosítási összege maximum 30 000 000 forint lehet.'
const VAL_446 = 'A melléképület(ek) minimális biztosítási összege: {0} forint lehet.'
const VAL_365 = 'Fontosnak tartjuk, hogy fenntarthatósági és környezetvédelmi törekvéseink mentén papírmentes megoldások felé vegyük az irányt. Köszönjük, ha ebben Ön is velünk tart és valamelyik online alternatívát választja. Ráadásul a többi fizetési mód esetén nem csak környezetünkért tehet, de kedvezményben is részesül.'
const VAL_418 = 'Havi fizetési ütem és banki átutalásos/online bankkártyás fizetési mód együttes választása esetén kizárólag Start Minősített Fogyasztóbarát Otthonbiztosítást köthet.'
const VAL_417 = 'Tartamkedvezménnyel Genertel Lakásbiztosításunk (Start+, Komfort, Extra) köthető. Amennyiben a Start Minősített Fogyasztóbarát Otthonbiztosítás érdekli, ezt a terméket tartamkedvezmény nélkül van lehetősége kiválasztani.'
const VAL_422 = 'Start Minősített Fogyasztóbarát Otthonbiztosításunk tartamkedvezménnyel nem köthető. Kérjük, módosítsa válaszát a folytatáshoz.'
const VAL_150 = 'A megadott kuponkód erre a termékre nem érvényes.'
const VAL_213 =
  'A megadott kuponkód felhasználása a kiválasztott fizetési ütemmel nem lehetséges. Kérjük, válasszon másik fizetési ütemet a kedvezmény érvényesítéséhez!'
const VAL_214 =
  'A megadott kuponkód felhasználása minimum éves díjhoz kötött, melyet nem ért el. Választhat kiegészítő biztosításokat vagy nagyobb csomagot, vagy folytathatja a kuponkód érvényesítése nélkül.'
const VAL_151 = 'A megadott kuponkód ezen a felületen nem érvényes.'
const VAL_434 = 'Új kiegészítő biztosítás maximum 7 éves kisállatra köthető.'
const VAL_456 = 'Új kiegészítő biztosítás minimum 6 hónapos kisállatra köthető.'
const VAL_448 = 'Lakásbiztosítást legfeljebb 60 napra előre köthet.'
const VAL_454 =
  'Felhívjuk figyelmét, hogy online csak akkor köthet szerződést, ha elektronikus kapcsolattartást választ. Amennyiben az elektronikustól eltérő kommunikációs módot választana, kérjük, keresse fel telefonos ügyfélszolgálatunkat.'
const VAL_457 = 'A megadott kuponkód beválthatósági feltételei nem teljesülnek!'
const VAL_304 =
  'Úgy látjuk, az Ön járműve 15 évnél idősebb, ezért nem tudja igénybe venni az assistance kiegészítő szolgáltatást.'
const VAL_324 = 'Kérem, hogy a korábbiakban megadott korcsoportoknak megfelelő születési időt adjon meg!'
const VAL_390 = 'A biztosított életkora maximum 79 év lehet!'
const VAL_507 = 'Kérjük, nyissa meg a biztosításközvetítői tájékoztatót, olvassa végig és jelölje be, hogy megismerte annak tartalmát!'
const VAL_508 = 'Kérjük, nyissa meg és olvassa végig a biztosításközvetítői tájékoztatót!'
const VAL_509 = 'Kérjük, jelölje be, hogy megismerte a biztosításközvetítői dokumentum tartalmát!'
const VAL_230 = 'Kérjük, nyissa meg, olvassa végig és jelölje be, hogy megismerte és elfogadja az ügyféltájékoztatót és biztosítási feltételeket, illetve, hogy az abban foglaltakon túl további tájékoztatást nem kapott!'
const VAL_231 = 'Kérjük, nyissa meg és olvassa végig az ügyféltájékoztatót!'
const VAL_232 = 'Kérjük, jelölje be, hogy megismerte és elfogadja az ügyféltájékoztatót és biztosítási feltételeket, illetve, hogy az azokban foglaltakon túl további tájékoztatást nem kapott!'
const VAL_228 = 'Kérjük, jelölje be, hogy megismerte és elfogadja az ügyféltájékoztatónak a „Polgári Törvénykönyvtől lényegesen eltérő rendelkezései” című fejezetében foglaltakat!'
const VAL_229 = 'Kérjük, nyissa meg és olvassa végig az ügyféltájékoztatónak a „Polgári Törvénykönyvtől lényegesen eltérő rendelkezései” című fejezetében foglaltakat!'
const VAL_234 = 'Kérjük, jelölje be, hogy megismerte és elfogadja az ügyféltájékoztatónak  és biztosítási feltételeknek a „Polgári Törvénykönyvtől lényegesen eltérő rendelkezései” című fejezetében foglaltakat!'
const VAL_235 = 'Kérjük, nyissa meg, olvassa végig és jelölje be, hogy megismerte és elfogadja az összes kapcsolódó dokumentumot!'
const VAL_236 = 'Kérjük, nyissa meg és olvassa végig az összes kapcsolódó dokumentumot!'
const VAL_237 = 'Kérjük, jelölje be, hogy megismerte és elfogadja az összes kapcsolódó dokumentumot, illetve hogy a biztosítási ajánlat írásba foglalása előtt a biztosítási igényeit, szükségleteit felmérték és a javasolt biztosítási termék összhangban van az igényfelmérés során általa megadott adatokkal!'
const VAL_512 = 'A választott lehetőségekkel lakásbiztosítás nem köthető. Kérjük, módosítsa a fizetési módot vagy ütemet a folytatáshoz.'
const VAL_513 = 'A megadott kötvényszámhoz tartozó szerződés nem Utasbiztosítás! Elállás csak utasbiztosításra kezdeményezhető!'
const VAL_514 = 'A kockázatviselési időszak megkezdődött, a szerződéstől már nem lehet elállni!'
const VAL_515 = 'A megadott kötvényszámon már szerepel szerződés elállási igény, vagy a szerződéstől már elálltak!'

//dummy:
const VAL_001 = 'bigger than 10'
const VAL_002 = 'bigger than 100'
const VAL_003 = 'bigger than 1000'
const VAL_004 = 'Csak egész szám lehet!'
const VAL_101 = 'Minimum két szó!'

//INFO:
//dummy:
const INFO_001 = 'Infó: ez 5 karakternél rövidebb'
const INFO_002 = 'Infó: Nagy kezdőbetű ajánlott'

//WARNING:
//dummy:
const WARN_001 = 'Warning: ez 5 karakternél hosszabb'
const WARN_002 = 'Warning: mondatvégi írásjel ajánlott'

// CROSS VALIDATION
const VAL_POC_CROSS_FIELD =
  'Ha ez előző mező asd, akkor ez a mező nem lehet 123'
const VAL_BACKEND_VALIDATION = 'A mező értéke nem lehet asd - backend validáció'

export {
  //dummy:
  INFO_001,
  INFO_002,
  VAL_001,
  VAL_002,
  VAL_003,
  VAL_004,
  VAL_100,
  VAL_101,
  VAL_103,
  VAL_104,
  VAL_105,
  VAL_106,
  VAL_126,
  VAL_107,
  VAL_108,
  VAL_111,
  VAL_114,
  VAL_115,
  VAL_116,
  VAL_123,
  VAL_128,
  VAL_131,
  VAL_132,
  VAL_135,
  VAL_146,
  VAL_147,
  VAL_148,
  VAL_149,
  VAL_150,
  VAL_151,
  VAL_154,
  VAL_156,
  VAL_157,
  VAL_159,
  VAL_161,
  VAL_161_TRAVEL,
  VAL_162,
  VAL_164,
  VAL_166,
  VAL_178,
  VAL_183,
  VAL_184,
  VAL_191,
  VAL_191_BETWEEN_6_8,
  VAL_206,
  VAL_213,
  VAL_214,
  VAL_216,
  VAL_299,
  VAL_238,
  VAL_261,
  VAL_284,
  VAL_297,
  VAL_304,
  VAL_320,
  VAL_322,
  VAL_324,
  VAL_329,
  VAL_330,
  VAL_333,
  VAL_345,
  VAL_346,
  VAL_378,
  VAL_407,
  VAL_349,
  VAL_356,
  VAL_357,
  VAL_359,
  VAL_361,
  VAL_363,
  VAL_364,
  VAL_365,
  VAL_371,
  VAL_372,
  VAL_372_MEDIATION,
  VAL_373,
  VAL_379,
  VAL_380,
  VAL_390,
  VAL_393,
  VAL_402,
  VAL_403,
  VAL_405,
  VAL_406,
  VAL_408,
  VAL_409,
  VAL_411,
  VAL_413,
  VAL_414,
  VAL_415,
  VAL_417,
  VAL_418,
  VAL_420,
  VAL_421,
  VAL_422,
  VAL_423,
  VAL_433,
  VAL_434,
  VAL_442,
  VAL_444,
  VAL_445,
  VAL_446,
  VAL_447,
  VAL_448,
  VAL_454,
  VAL_456,
  VAL_457,
  VAL_458,
  VAL_459,
  VAL_460,
  VAL_464,
  VAL_465,
  VAL_466,
  VAL_467,
  VAL_507,
  VAL_508,
  VAL_509,
  VAL_230,
  VAL_231,
  VAL_232,
  VAL_228,
  VAL_229,
  VAL_234,
  VAL_235,
  VAL_236,
  VAL_237,
  VAL_ACCIDENT_MAX,
  VAL_BACKEND_VALIDATION,
  VAL_POC_CROSS_FIELD,
  WARN_001,
  WARN_002,
  VAL_512,
  VAL_513,
  VAL_514,
  VAL_515
}
