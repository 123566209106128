import { createStore } from 'vuex'

const store = createStore({
  state() {
    return {
      allowedReferrers: [
        '/',
        '/utasbiztositas-ismerteto',
        '/utasbiztositas',
        '/utasbiztositas/utasbiztositas-kalkulator',
        '/utasbiztositas/utasbiztositas-kalkulator/kezdes',
        '/utasbiztositas/utasbiztositas-kalkulator/alapadatok',
        '/utasbiztositas/utasbiztositas-kalkulator/csomagvalasztas',
        '/utasbiztositas/utasbiztositas-kalkulator/szerzodeskotes',
        '/utasbiztositas/utasbiztositas-kalkulator/adatellenorzes',
        '/utasbiztositas/utasbiztositas-kalkulator/mentes',
        '/utasbiztositas/utasbiztositas-kalkulator/sikeres-mentes',
        '/utasbiztositas/utasbiztositas-kalkulator/koszonjuk',
      ], // Pages that are allowed to navigate to the restricted page
      fromHomePage: sessionStorage.getItem('fromHomePage') || false,
    }
  },
  mutations: {
    setFromHomePage(state, isFromHome) {
      sessionStorage.setItem('fromHomePage', isFromHome)
    },
  },
})

export default store
