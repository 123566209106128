<template>
  <div class="form-holder">
    <h1 v-if="!!title">
      {{ title }}
    </h1>
    <q-form ref="inputForm">
      <TextInput
        :id="idName"
        v-model:model-value="nameModel"
        :label="labelName"
        :max-length="60"
        validation-rules="VAL_100|VAL_103|VAL_104"
        :tooltip-content="getTooltip(idName)"
      />
      <PhoneInput
        :id="idPhone"
        v-model:model-value="phoneNumber"
        class="callback-phone"
        :label="labelChunkPhoneNumber"
        :area-code-label="labelDialingCode"
        :tooltip-content="getTooltip(idPhone)"
        @set-phone-number="setPhoneNumber"
      />

      <SelectInput
        v-if="selectVisible &&
          (selectOptions.length > 0 || getSelectInputOptions(idSelect).length > 0)
        "
        :id="idSelect"
        v-model:model-value="subjectModel"
        :label="labelSelect"
        :options="
          selectOptions.length > 0
            ? selectOptions
            : getSelectInputOptions(idSelect)
        "
        validation-rules="VAL_100_SELECT"
        :tooltip-content="getTooltip(idSelect)"
      />
      <TextAreaInput
        v-if="commentVisible"
        :id="idComment"
        v-model:model-value="commentModel"
        :label="labelComment"
        :show-counter="true"
        max-length="500"
        :tooltip-content="getTooltip(idComment)"
      />
      <p
        v-if="showAlreadyCustomerQuestion"
        class="t-xs text-left mb-16px mt-3"
      >
        Van már társaságunknál élő biztosítása?
      </p>
      <Row12
        v-if="showAlreadyCustomerQuestion"
        class="text-left mb-24px"
      >
        <RadioInput
          id="MINI_122_MINI_123"
          :key="alreadyCustomerOptions"
          v-model:model-value="alreadyCustomerModel"
          :options="alreadyCustomerOptions"
          validation-rules="VAL_100_RADIO"
        />
      </Row12>
    </q-form>

    <Row12
      v-if="showDataHandlingParagraph"
      class="text-left mt-3"
    >
      <paragraph-read-more
        :module-content="moduleContent"
        :more-information-text="moreInformationText"
        :show-more-information-button="!!moreInformationText"
      />
    </Row12>
    <div :class="templateClass">
      <slot
        class="mt-3"
        name="middle"
      />
    </div>
    <ReCaptcha
      v-show="recaptchaOn"
      ref="recaptcha"
      class="mb-captcha"
      :class="reCaptchaClass"
      :recaptcha-valid="reCaptchaValid"
      @recaptcha-error="handleRecaptchaError"
      @recaptcha-success="handleRecaptchaSuccess"
    />
    <div
      class="button-holder"
      :class="buttonClass"
    >
      <q-btn
        id="PHC6"
        class="btn btn-secondary btn-dense"
        @click="sendFormData"
      >
        {{ callbackButtonText }}
      </q-btn>
    </div>
  </div>
</template>

<script setup>
import { ref, inject } from 'vue'
import axios from 'axios'
import router from '../../router/index'
import { useGlobalValuesStore } from '../../stores/globalValuesStore'
import { focusFirstValidation } from '../../utils/validations/validationFocus'

const props = defineProps({
  formData: {
    type: Array,
    default: () => [],
  },
  idName: {
    type: String,
    default: 'CB_100',
  },
  idPhone: {
    type: String,
    default: 'CB_101',
  },
  idComment: {
    type: String,
    default: 'CB_115',
  },
  idSelect: {
    type: String,
    default: 'CB_104',
  },
  labelName: {
    type: String,
    default: 'Teljes név',
  },
  labelChunkPhoneNumber: {
    type: String,
    default: 'Telefonszám',
  },
  labelDialingCode: {
    type: String,
    default: 'Pl. 30',
  },
  labelComment: {
    type: String,
    default: 'Megjegyzés',
  },
  labelSelect: {
    type: String,
    default: 'Milyen típusú biztosítás érdekli?',
  },
  moduleContent: {
    type: String,
    default:
      'A „Visszahívást kérek” gomb megnyomásával Ön hozzájárul ahhoz, hogy a Genertel Biztosító Zrt. (1132 Budapest, Váci út 36-38.) a megadott elérhetőségen 3 munkanapon belül felvegye Önnel a kapcsolatot. Tájékoztatjuk, hogy a fent megadott személyes adatait',
  },
  moreInformationText: {
    type: String,
    default:
      'a Genertel Biztosító Zrt. kizárólag az Ön egyszeri visszahívása érdekében, üzletszerzési célból kezeli. A megadott adatokat a Genertel Biztosító Zrt. az Ön hozzájárulásának visszavonásáig, de legfeljebb a kapcsolatfelvételt követő 30 napig kezeli, majd ezt követően törli rendszereiből. A személyes adatai kezelésével kapcsolatos jogokról és jogérvényesítési lehetőségekről az <a>Adatkezelési tájékoztató</a> VII-X. pontjaiban, a közvetlen üzletszerzési célú adatkezelésekről pedig a II. 9. pontjában tájékozódhat.',
  },
  showAlreadyCustomerQuestion: {
    type: Boolean,
    default: false,
  },
  alreadyCustomerOptions: {
    type: Array,
    default: () => [
      {
        label: 'Igen',
        value: true,
      },
      {
        label: 'Nem',
        value: false,
      },
    ],
  },
  selectOptions: {
    type: Array,
    default: () => [],
  },
  redirectLink: {
    type: String,
    default: '/visszahivast-kerek/koszonjuk ',
  },
  sendFormInComponent: {
    type: Boolean,
    default: true,
  },
  buttonClass: {
    type: String,
    default: '',
  },
  reCaptchaClass: {
    type: String,
    default: '',
  },
  templateClass: {
    type: String,
    default: '',
  },
  showDataHandlingParagraph: {
    type: Boolean,
    default: true,
  },
  title: {
    type: String,
    default: '',
  },
  recaptchaOn: {
    type: Boolean,
    default: false,
  },
  callbackButtonText: {
    type: String,
    default: 'Visszahívást kérek',
  },
  campaignName: {
    type: String,
    default: '',
  },
  commentVisible: {
    type: Boolean,
    default: true,
  },  
  selectVisible: {
    type: Boolean,
    default: true,
  },  
})

const showLoadingIndicator = inject('showLoadingIndicator')

const emit = defineEmits(['emitFormData'])

const nameModel = ref(null)
const subjectModel = ref(null)
const commentModel = ref(null)
const alreadyCustomerModel = ref(null)

const phoneNumber = ref(null)
const globalValues = useGlobalValuesStore()
const inputForm = ref(null)

const reCaptchaValid = ref(false)
const recaptcha = ref(null)

const sendFormData = async () => {
  recaptcha.value.onSubmit()
  await inputForm.value.validate().then(async (success) => {
    if (success && (reCaptchaValid.value || !props.recaptchaOn)) {
      const body = {
        name: nameModel.value,
        phoneNumber: phoneNumber.value.value,
        subject: subjectModel.value,
        comment: commentModel.value,
        isCustomer: alreadyCustomerModel.value,
        device: window.innerWidth >= 768 ? 'Web' : 'Mobil (web)',
        campaignName: props.campaignName,
      }
      if (props.sendFormInComponent) {
        showLoadingIndicator.value = true
        await axios
          .post(
            import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL +
              '/CustomerService/PostCustomerServicePhoneForm',
            body
          )
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              let customerServicePhoneFormData = {
                phoneNumber: phoneNumber.value,
                comment: commentModel.value,
              }
              globalValues.setCustomerServiceThankYouUserDisplayData(
                customerServicePhoneFormData
              )
              showLoadingIndicator.value = false
              router.push({ path: props.redirectLink })
            }
          })
      } else {
        emit('emitFormData', body)
      }
    }
    focusFirstValidation()
  })
}

function getTooltip(fieldName) {
  if (props.formData === null) return ''
  const field = props.formData.find((field) => field.name === fieldName)
  if (field) return field.tooltip
  else return ''
}

function getSelectInputOptions(fieldName) {
  if (props.formData === null) return null
  const field = props.formData.find((field) => field.name === fieldName)

  let selectInputOptions = []
  if (field) {
    field.formFieldValues.forEach((element) => {
      selectInputOptions.push(element.name)
    })
  }

  return selectInputOptions
}

function setPhoneNumber(value) {
  phoneNumber.value = value
}

function handleRecaptchaError() {
  reCaptchaValid.value = false
}

function handleRecaptchaSuccess(response) {
  reCaptchaValid.value = true
}
</script>

<style lang="scss" scoped>
.form-holder {
  max-width: 56.875rem;
  background: #f7f7fb;
  border-radius: 0.75rem;
  margin-left: auto;
  margin-right: auto;

  .q-checkbox {
    margin-top: 1.5rem !important;
    margin-bottom: 2rem !important;
  }

  .button-holder {
    margin-bottom: 2rem;
    .btn {
      line-height: 1.5rem;
    }
  }
  .text-holder {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: #4a567c;
    text-align: center;
  }
  .callback-phone {
    margin-left: 0;
    margin-right: 0;
  }
  .checkbox-text-holder {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #111a37;
  }

  :deep(.pre-dial) {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #1d2c5b;
  }

  .mb-captcha {
    margin-bottom: 2.5rem;
  }
}

@media screen and (max-width: $breakpoint-md-max) {
  .form-holder {
    .mb-captcha {
      margin-bottom: 2rem;
    }
  }
}

@media screen and (max-width: $breakpoint-xs-max) {
  .form-holder {
    padding: 1.5rem 1rem;
    .q-checkbox {
      margin-bottom: 1.5rem !important;
      p {
        font-size: 0.75rem;
        line-height: 1.25rem;
        color: #111a37;
      }
    }
    .button-holder {
      margin-bottom: 1.5rem;

      .btn {
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.5rem;
        width: 100%;
      }
    }
    .text-holder {
      text-align: left;
    }
    .checkbox-text-holder {
      font-size: 0.75rem;
    }

    :deep(.pre-dial) {
      font-size: 0.75rem;
      line-height: 1.25rem;
    }

    .mb-captcha {
      margin-bottom: 1rem;
    }
  }
}
</style>
