<template>
  <q-form
    ref="workerDiscountFormRef"
    @submit.prevent="usePartnerCode"
  >
    <div
      class="calculator-holder"
    >
      <Row66Xl48Lg
        id="KALK_119"
        class="input-container"
      >
        <template #first>
          <div class="calculator-description-holder">
            <TitleTooltipModal
              htag="h3"
              class="input-title"
              :tooltip-content="tooltipText"
              :tooltip-title="title"
            >
              {{ title }}
            </TitleTooltipModal>
            <p
              v-if="description"
              class="small"
              v-html="description"
            />
          </div>
        </template>
        <template #second>
          <div class="input-holder discount-code-container">
            <RubYesNoInput
              id="UT_113"
              v-model:model-value="yesNoDefaultValue"
              :option-true="optionTrue"
              :option-false="optionFalse"
              :default="yesNoDefaultValue"
              :init-input-value="yesNoDefaultValue"
            />
            <NumberInput
              v-if="yesNoDefaultValue"
              id="UT_114"
              v-model:model-value="value"
              :init-input-value="value"
              max-length="8"
              label="Kedvezménykód"
              validation-rules="VAL_100|VAL_146"
              :validate-on-mount="false"
              ignore-zero-default-value
              @update:model-value="usePartnerCode"
            />
          </div>
        </template>
      </Row66Xl48Lg>
    </div>
  </q-form>
</template>

<script setup>
import { customDefineRule } from '@/utils/validations/customDefineRule'
import * as ValidationMessages from '@/utils/validations/validationMessages.js'
import { onMounted, ref, watch } from 'vue'
import SubmodusTitle from "@/components/elements/SubmodusTitle.vue";
import NumberInput from "@/components/inputs/NumberInput.vue";


const props = defineProps({
  workerDiscountCode: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: '',
    required: true,
  },
  description: {
    type: String,
    required: false,
  },
  tooltipText: {
    type: String,
    required: false,
  },
  optionTrue: {
    type: String,
    default: '',
  },
  optionFalse: {
    type: String,
    default: '',
  },
  message: {
    type: String,
  },
  validationErrorCode: {
    type: String
  }
})

const value = ref(props.workerDiscountCode ?? null)
const yesNoDefaultValue = ref(value.value !== undefined && value.value !== null)
const validationErrorCode = ref(null)

customDefineRule('VAL_146', (input) => {
  if (value.value && validationErrorCode.value === 'VAL_146') {
    return ValidationMessages.VAL_146
  }
  return true
})
const emits = defineEmits(['usePartnerCode', 'revokePartnerCode'])


const usePartnerCode = (data) => {
  value.value = data
  emits('usePartnerCode', {
    code: {
      partnerCode: value.value,
    },
    callback: async (errorCode) => {
      validationErrorCode.value = errorCode
    }
  })
}

watch(yesNoDefaultValue, (ynValue) => {
    if (ynValue === false) {
      validationErrorCode.value = false
      value.value = null
      emits('revokePartnerCode')
    }
  }
)

onMounted(() => (value.value = props.workerDiscountCode))
</script>

<style lang="scss" scoped>
.discount-code-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}
</style>
