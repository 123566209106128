<template>
  <div class="text-input">
    <q-input
      v-model="value"
      :for="id"
      :mask="mask"
      unmasked-value
      standout
      :error="!!veeErrors[0] || warningMessages?.length > 0"
      :label="label"
      :class="(warningMessages?.length > 0 && veeErrors.length == 0)?'has-warning':''"
      :readonly="readonly"
      :maxlength="maxLength"
      :disable="isDisabled"
      :reverse-fill-mask="reverseFillMask"
      @keydown="keyDown"
      @blur="textInputHandleChange($event), handleInfoWarningMessages(value)"
      @change="inputChanged"
      @focus="setMetaTouched"
    >
      <template #label>
        {{ label }}
        <Tooltip :tooltip-content="tooltipContent" />
      </template>
      <template #append>
        <div
          v-sanitize="postfix"
          class="input-postfix"
        />
      </template>
      <template #error>
        <ValidationMessage
          :messages="veeErrors"
          :info-messages="infoMessages"
          :warning-messages="warningMessages"
        />
      </template>
    </q-input>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, inject } from 'vue'
import { useField } from 'vee-validate'
import {
  getInfoMessagesForValue,
  getWarningMessagesForValue,
} from '@/utils/validations/customValidator.js'
import { useFormChild } from 'quasar'

const props = defineProps({
  id: {
    type: String,
    required: true,
  },

  mask: {
    type: String,
    default: '',
  },

  label: {
    type: String,
    required: false,
  },

  tooltipContent: {
    type: String,
    default: '',
  },

  validationRules: {
    type: String,
    default: '',
  },

  infoMessageRules: {
    type: String,
    default: '',
  },

  warningMessageRules: {
    type: String,
    default: '',
  },

  modelValue: {
    type: [Number, String],
    default: '0',
  },
  maxLength: {
    type: Number,
    default: -1,
  },
  inputType: {
    type: String,
    default: '',
  },
  initInputValue: {
    type: [Number, String],
    default: null,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  maxNumber: {
    type: Number,
    default: null,
    required: false,
  },
  minNumber: {
    type: Number,
    default: null,
    required: false,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  postfix: {
    type: String,
    required: false,
    default: '',
  },
  reverseFillMask: {
    type: Boolean,
    required: false,
  },
  warningMessagesTarget: {
    type: Object,
    required: false,
    default: () => {},
  }
})

const emit = defineEmits(['update:modelValue','updateValidationResult'])

const removeValueIfNotValid = inject('_removeValueIfNotValid')

const infoMessages = ref(null)
const warningMessages = ref(null)

const prevValue = ref('')

onMounted(() => {
  if (props.initInputValue !== null) {
    value.value = props.initInputValue
    // Ebben az esetben azért true, mert ha van már alapból értéke, feltételezni kell, hogy az lehet hibás is, ezért vissza navigáláskor törölni kell.
    meta.touched = true;
    emit('update:modelValue', value.value, props.id)
    validateQForm()
  }
})

watch(
  () => props.modelValue,
  (newValue) => {
    value.value = newValue
    validateQForm()
    handleInfoWarningMessages(value.value)
  }
)

async function validateQForm() {
  // run vee-validate validations
  
  await veeValidate()
  
  emit('updateValidationResult', veeErrors.value.length > 0)
  if (veeErrors.value.length > 0) {
    if(removeValueIfNotValid.value && !meta.valid && meta.touched){
      value.value = null;
      inputChanged();
      return true;
    }
    return false
  } else {
    return true
  }
}

function textInputHandleChange(e)
{
  if(e.target.id != null && e.target.id != undefined && e.target.id != '')
  handleChange(e)
}

function keyDown(e) {
  e = e ? e : window.event
  switch (props.inputType) {
    case 'text-with-space': {
      let isValidKey = containsLettersAndSpace(e.key)
      if (!isValidKey) {
        e.preventDefault()
      }
      return
    }
    case 'readonly': {
      e.preventDefault()
    }
    case 'house-number': {
      let isValidKey = containsNumbersAndHyphen(e.key)
      if(e.keyCode == 9){
        return
      }
      if (!isValidKey && e.keyCode != 8 && e.keyCode != 9) {
        e.preventDefault()
      }
      return
    }
    case 'accident-number': {
      let isValidKey = containsOnlyNumbers(e.key)
      let numberValue = value.value + e.key
      if (
        e.keyCode != 8 &&
        e.keyCode != 9 &&
        e.keyCode != 46 &&
        e.keyCode != 37 &&
        e.keyCode != 39
      ) {
        if (
          !isValidKey ||
          numberValue > props.maxNumber ||
          numberValue < props.minNumber
        ) {
          e.preventDefault()
        }
      }

      return
    }
    default: {
      prevValue.value = value.value
      return
    }
  }
}

function inputChanged() {
  emit('update:modelValue', value.value, props.id)
  validateQForm()
}

function resetValidationQForm() {}

function handleInfoWarningMessages(value) {
  infoMessages.value = getInfoMessagesForValue(value, props.infoMessageRules)
  warningMessages.value = getWarningMessagesForValue(
    value,
    props.warningMessageRules,
    props.warningMessagesTarget
  )
}

function setMetaTouched() {
  meta.touched = true
}

const {
  handleChange,
  errors: veeErrors,
  value,
  meta,
  validate: veeValidate,
} = useField(props.id, props.validationRules, {
  validateOnValueUpdate: false,
  bails: true,
})

function containsOnlyNumbers(str) {
  return /^\d+$/.test(str)
}

function containsAtLeastOneDigit(str) {
  return /\d/.test(str)
}

function containsLettersAndSpace(str) {
  return /^[\p{L} ]+$/u.test(str)
}

function containsNumbersAndHyphen(str) {
  return /^[\d-]$/u.test(str)
}

useFormChild({
  validate: validateQForm, // Function; Can be async; // Should return a Boolean (or a Promise resolving to a Boolean)
  resetValidation: resetValidationQForm, // Optional function which resets validation
  requiresQForm: false, // should it error out if no parent QForm is found?
})
</script>

<script>
export default {
  name: 'TextInput',
}
</script>

<style lang="scss" scoped>
:deep(.q-field--standout) {
  &.q-field * {
    border-width: 0 !important;
  }

  .q-field__control:before {
    background-color: transparent;
  }

  &.q-field--highlighted .q-field__control {
    box-shadow: none;
  }

  .q-field__control {
    border-radius: $half !important;
    padding: 0 1rem !important;
    box-shadow: inset 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.06) !important;
    border: 1px solid #f5f4f8 !important;
    background: white;
  }

  .q-field__label {
    font-size: $font-size-sm;
    line-height: $line-height-sm;
    font-weight: $font-weight-medium;
    color: $D-400 !important;
  }

  .q-field--float {
    .q-field__label {
      transform: translateY(-40%) scale(10 / 12) !important;
    }
  }

  .q-field__native,
  .q-field__prefix,
  .q-field__suffix,
  .q-field__input {
    font-weight: $font-weight-bold !important;
  }

  .q-field__append {
    background: transparent !important;
    width: auto !important;
  }
}

:deep(.q-field--standout:hover) {
  .q-field__control {
    border: 1px solid $border-data-hover !important;
    background-color: $input-data-bg-hover !important;
  }
}

:deep(.q-field--standout.q-field--focused) {
  .q-field__control {
    border: 1px solid $border-active !important;
  }
}

:deep(.q-field--focused:hover) {
  .q-field__control {
    background-color: white !important;
    border: 1px solid $border-active !important;
  }
}

:deep(.q-field--float:hover) {
  .q-field__control {
    border: 1px solid $border-data-hover !important;
    background-color: $input-data-bg-hover !important;
  }
}

:deep(.q-field--float:hover) {
  &.q-field--focused {
    .q-field__control {
      border: 1px solid $border-active !important;
    }
  }
}

:deep(.q-field--error) {
  & * {
    color: $input-text-validation !important;
  }

  .q-field__control {
    border: 1px solid $border-validation !important;
    background-color: white !important;
  }
}

:deep(.q-field--standout) {
  &.q-field.q-field--error * {
    color: $input-text-validation !important;
  }
}

:deep(.q-field--standout.q-field .q-field__append) {
  background: transparent !important;
  width: auto !important;

  svg,
  svg * {
    color: $N-600 !important;
  }

  .input-postfix {
    color: $D-700;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
}

:deep(.q-field--standout.q-field--disabled) {
  .q-field__control {
    background: #eef0f7;
    .q-field__label {
      color: #b4b9cb !important;
    }
  }
}
</style>
