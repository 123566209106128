<template>
  <div class="radio-input">
    <q-option-group
      v-model="value"
      :options="options"
      type="radio"
      :error="!!veeErrors[0] || warningMessages?.length > 0 || infoMessages?.length > 0"
      :class="(warningMessages?.length > 0 && veeErrors.length == 0)?'has-warning':''"
      color="secondary"
      :size="'20px'"
      @update:model-value="handleChange($event), inputChanged()"
    />
    <div
      v-if="veeErrors[0] || warningMessages?.length > 0 || infoMessages?.length > 0"
      class="q-field__inner q-field--with-bottom .q-field--error"
    >
      <div class="q-field__bottom row items-start q-field__bottom--animated">
        <div class="q-field__messages col">
          <ValidationMessage
            :messages="veeErrors"
            :info-messages="infoMessages"
            :warning-messages="warningMessages"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, inject } from 'vue'
import { useField } from 'vee-validate'
import {
  getInfoMessagesForValue,
  getWarningMessagesForValue,
} from '@/utils/validations/customValidator.js'
import { useFormChild } from 'quasar'

const props = defineProps({
  id: {
    type: String,
    required: true,
  },

  validationRules: {
    type: String,
    required: false,
    default: '',
  },

  infoMessageRules: {
    type: String,
    required: false,
    default: '',
  },

  warningMessageRules: {
    type: String,
    required: false,
    default: '',
  },

  modelValue: {
    type: Boolean,
    required: false,
    default: null,
  },
  options: {
    type: Array,
    required: true,
    default: () => [],
  },
  initInputValue: {
    type: Object,
    default: null,
  },
  warningMessagesTarget: {
    type: Object,
    required: false,
    default: () => {},
  },
})

const emit = defineEmits(['update:modelValue'])

const removeValueIfNotValid = inject('_removeValueIfNotValid')

const infoMessages = ref(null)
const warningMessages = ref(null)

const {
  handleChange,
  errors: veeErrors,
  value,
  meta,
  validate: veeValidate,
} = useField(props.id, props.validationRules, {
  validateOnValueUpdate: false,
  bails: true,
})

onMounted(() => {
  if (props.initInputValue !== null) {
    value.value = props.initInputValue
    validateQForm()
    handleInfoWarningMessages(value.value)
  }
})

useFormChild({
  validate: validateQForm, // Function; Can be async; // Should return a Boolean (or a Promise resolving to a Boolean)
  resetValidation: resetValidationQForm, // Optional function which resets validation
  requiresQForm: false, // should it error out if no parent QForm is found?
})

function setMetaTouched() {
  meta.touched = true
}

async function validateQForm() {
  // run vee-validate validations
  await veeValidate()

  if (veeErrors.value.length > 0) {
    if(removeValueIfNotValid.value && !meta.valid && meta.touched){
      value.value = null;
      inputChanged();
      return true;
    }
    return false
  } else {
    return true
  }
}

function inputChanged() {
  setMetaTouched()
  emit('update:modelValue', value.value)
  handleInfoWarningMessages(value.value)
  validateQForm()
}

function resetValidationQForm() {}

function handleInfoWarningMessages(value) {
  infoMessages.value = getInfoMessagesForValue(value, props.infoMessageRules)
  warningMessages.value = getWarningMessagesForValue(
    value,
    props.warningMessageRules,
    props.warningMessagesTarget
  )
}
</script>
<style lang="scss" scoped>
:deep(.q-radio__inner.relative-position) {
  width: fit-content !important;
}
:deep(.q-radio__label) {
  flex: 1 !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

:deep(.q-option-group.q-gutter-x-sm) {
  margin-left: 0 !important;
}

:deep(.q-option-group) {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: $breakpoint-xs-max) {
  :deep(.q-option-group) {
    flex-direction: column;
  }
}

:deep(.q-gutter-x-sm > *, .q-gutter-sm > *) {
  margin-left: 0 !important;
}

.radio-input {
  .q-field__messages.col {
    line-height: 1.25rem !important;
    color: $danger;
    padding-top: 0.5rem;
    :deep(.warning){
      color: $warning !important;
      font-size: 0.75rem;
    }
  }
  .q-field--with-bottom {
    padding-bottom: 1.5rem;
  }
}
</style>
