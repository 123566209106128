import { defineAsyncComponent } from 'vue'

//Components
import Accordion from '@/components/Accordion.vue'
import CalculatorFooter from '@/components/CalculatorFooter.vue'
import ProductMinicalculator from '@/components/controls/ProductMinicalculator.vue'
import DesktopFooter from '@/components/DesktopFooter.vue'
import DesktopHeader from '@/components/DesktopHeader.vue'
import DocumentList from '@/components/DocumentList.vue'
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import HelloWorld from '@/components/HelloWorld.vue'
import MobileFooter from '@/components/MobileFooter.vue'
import MobileHeader from '@/components/MobileHeader.vue'
import StickyFooter from '@/components/stickyFooter/StickyFooter.vue'

//Inputs
import AdditionalPet from '@/components/inputs/AdditionalPet.vue'
import AddressInput from '@/components/inputs/AddressInput.vue'
import BankAccountNumberInput from '@/components/inputs/BankAccountNumberInput.vue'
import BuildingTypeChoiceInput from '@/components/inputs/BuildingTypeChoiceInput.vue'
import CalculatorAdditionalItem from '@/components/inputs/CalculatorAdditionalItem.vue'
import CalculatorAdditionalPricing from '@/components/inputs/CalculatorAdditionalPricing.vue'
import CalculatorAddressInput from '@/components/inputs/CalculatorAddressInput.vue'
import CalculatorButtons from '@/components/inputs/CalculatorButtons.vue'
import CalculatorCouponInput from '@/components/inputs/CalculatorCouponInput.vue'
import CheckboxInput from '@/components/inputs/CheckboxInput.vue'
import CouponView from "@/components/inputs/CouponView.vue";
import DocumentsInput from '@/components/inputs/DocumentsInput.vue'
import TravelInsuranceDocumentsInput from '@/components/inputs/TravelInsuranceDocumentsInput.vue'
import EstateAreaSizeModal from '@/components/inputs/EstateAreaSizeModal.vue'
import EstatePersonalytyModal from '@/components/inputs/EstatePersonalytyModal.vue'
import EstateTypeChoiceInput from '@/components/inputs/EstateTypeChoiceInput.vue'
import FileUploader from '@/components/inputs/FileUploader.vue'
import FinancialInstitutionsInput from '@/components/inputs/FinancialInstitutionsInput.vue'
import GameInput from '@/components/inputs/GameInput.vue'
import GameInputPOC from '@/components/inputs/GameInputPOC.vue'
import InsuranceTypeChoiceInput from '@/components/inputs/InsuranceTypeChoiceInput.vue'
import InsuranceTypeChoiceInputPOC from '@/components/inputs/InsuranceTypeChoiceInputPOC.vue'
import LoanAuthorizationInput from '@/components/inputs/LoanAuthorizationInput.vue'
import LoyaltyDiscountContractNumberInput from '@/components/inputs/LoyaltyDiscountContractNumberInput.vue'
import MarketingstatementInput from '@/components/inputs/MarketingstatementInput.vue'
import MarketingstatementInputPOC from '@/components/inputs/MarketingstatementInputPOC.vue'
import MfoEstateBaseInsurancePackageInput from '@/components/inputs/MFO/MfoEstateBaseInsurancePackageInput.vue'
import MfoHouseholdPackageSelectInput from '@/components/inputs/MFO/MfoHouseholdPackageSelectInput.vue'
import MfoHouseholdPackageSelectInputPOC from '@/components/inputs/MFO/MfoHouseholdPackageSelectInputPOC.vue'
import MfoLiabilityInsurancePackageInput from '@/components/inputs/MFO/MfoLiabilityInsurancePackageInput.vue'
import MfoPersonalityPackageInput from '@/components/inputs/MFO/MfoPersonalityPackageInput.vue'
import MfoSteadingPersonalityPackageInput from '@/components/inputs/MFO/MfoSteadingPersonalityPackageInput.vue'
import MfoValuablesPackageInput from '@/components/inputs/MFO/MfoValuablesPackageInput.vue'
import ModalButtons from '@/components/inputs/ModalButtons.vue'
import MultiContractNumberInput from '@/components/inputs/MultiContractNumberInput.vue'
import NumberInput from '@/components/inputs/NumberInput.vue'
import NumberInputPackage from '@/components/inputs/NumberInputPackage.vue'
import NumericUpDownInput from '@/components/inputs/NumericUpDownInput.vue'
import NumericUpDownInputPOC from '@/components/inputs/NumericUpDownInputPOC.vue'
import NumericUpDownInputWithUnit from '@/components/inputs/NumericUpDownInputWithUnit.vue'
import OuthouseRepeaterInput from '@/components/inputs/OuthouseRepeaterInput.vue'
import OuthouseRepeaterInputPOC from '@/components/inputs/OuthouseRepeaterInputPOC.vue'
import PartnerDiscountInput from '@/components/inputs/PartnerDiscountInput.vue'
import PhoneInput from '@/components/inputs/PhoneInput.vue'
import RadioInput from '@/components/inputs/RadioInput.vue'
import RubRadioInput from '@/components/inputs/RubRadioInput.vue'
import SelectInput from '@/components/inputs/SelectInput.vue'
import SelectInputEmpty from '@/components/inputs/SelectInputEmpty.vue'
import StickyCart from '@/components/inputs/StickyCart.vue'
import TextAreaInput from '@/components/inputs/TextAreaInput.vue'
import TextInput from '@/components/inputs/TextInput.vue'
import TextInputPackage from '@/components/inputs/TextInputPackage.vue'
import ToggleInput from '@/components/inputs/ToggleInput.vue'
import ValuablesModal from '@/components/inputs/ValuablesModal.vue'
import YesNoInput from '@/components/inputs/YesNoInput.vue'
import RubYesNoInput from '@/components/inputs/RubYesNoInput.vue'
import ZipCodeInput from '@/components/inputs/ZipCodeInput.vue'

//Controls
import Breadcrumb from '@/components/controls/Breadcrumb.vue'
import CalculatorAccordion from '@/components/controls/CalculatorAccordion.vue'
import MFODesktopPackage from '@/components/controls/Calculators/MFO/MFODesktopPackage.vue'
import MFODesktopPackagePOC from '@/components/controls/Calculators/MFO/MFODesktopPackagePOC.vue'
import MFOTabletMobilePackage from '@/components/controls/Calculators/MFO/MFOTabletMobilePackage.vue'
import MFOTabletMobilePackagePOC from '@/components/controls/Calculators/MFO/MFOTabletMobilePackagePOC.vue'
import Carousel from '@/components/controls/Carousel.vue'
import DynamicBreadcrumb from '@/components/controls/DynamicBreadcrumb.vue'
import Minicalculator from '@/components/controls/Minicalculator.vue'
import MobileMinicalculator from '@/components/controls/MobileMinicalculator.vue'
import ProductCallback from '@/components/controls/ProductCallback.vue'
import ReCaptcha from '@/components/controls/ReCaptcha.vue'
import TabletMinicalculator from '@/components/controls/TabletMinicalculator.vue'
import VisszahivastKerek from '@/components/controls/VisszahivastKerek.vue'
import Alert from '@/components/elements/Alert.vue'

//Modules
import AdministrationModule from '@/components/modules/AdministrationModule.vue'
import BreakingNewsModule from '@/components/modules/BreakingNewsModule.vue'
import ClaimsModule from '@/components/modules/ClaimsModule.vue'
import CookieModule from '@/components/modules/CookieModule.vue'
import DescriptionModule from '@/components/modules/DescriptionModule.vue'
import DescriptionModuleHtml from '@/components/modules/DescriptionModuleHtml.vue'
import DiscountsModule from '@/components/modules/DiscountsModule.vue'
import FAQModule from '@/components/modules/FAQModule.vue'
import HeroModule from '@/components/modules/HeroModule.vue'
import MiniCarouselModule from '@/components/modules/MiniCarouselModule.vue'
import ServicesModule from '@/components/modules/ServicesModule.vue'

//Elements
import BacktotopProduct from '@/components/elements/BacktotopProduct.vue'
import BacktotopProductCalculation from '@/components/elements/BacktotopProductCalculation.vue'
import CalculatorPackageLinks from '@/components/elements/CalculatorPackageLinks.vue'
import CalculatorRecommendationTooltip from '@/components/elements/CalculatorRecommendationTooltip.vue'
import CustomerServiceCard from '@/components/elements/CustomerServiceCard.vue'
import Dialog from '@/components/elements/Dialog.vue'
import DiscountBarTooltip from '@/components/elements/DiscountBarTooltip.vue'
import DiscountHint from '@/components/elements/DiscountHint.vue'
import GoogleScore from '@/components/elements/GoogleScore.vue'
import List from '@/components/elements/List.vue'
import MobileFooterItem from '@/components/elements/MobileFooterItem.vue'
import MobileHeaderItem from '@/components/elements/MobileHeaderItem.vue'
import NavigableDocumentLink from '@/components/elements/NavigableDocumentLink.vue'
import NavigableLink from '@/components/elements/NavigableLink.vue'
import ParagraphReadMore from '@/components/elements/ParagraphReadMore.vue'
import IconChattel from '@/components/elements/phicons/IconChattel.vue'
import IconProperty from '@/components/elements/phicons/IconProperty.vue'
import IconPropertyChattel from '@/components/elements/phicons/IconPropertyChattel.vue'
import Title from '@/components/elements/Title.vue'
import TitleTooltip from '@/components/elements/TitleTooltip.vue'
import TitleTooltipModal from '@/components/elements/TitleTooltipModal.vue'
import Tooltip from '@/components/elements/Tooltip.vue'
import TooltipModal from '@/components/elements/TooltipModal.vue'
import ValidationMessage from '@/components/elements/ValidationMessage.vue'

import SubmodusTitle from '@/components/elements/SubmodusTitle.vue'

//Layouts
import CalculatorLayout from '@/layouts/Calculators/CalculatorLayout.vue'
import MFODesktopPackageRowLayout from '@/layouts/Calculators/MFO/MFODesktopPackageRowLayout.vue'
import MFODesktopPackageRowLayoutPOC from '@/layouts/Calculators/MFO/MFODesktopPackageRowLayoutPOC.vue'
import UtasCalculatorFirstPageLayout from '@/layouts/Calculators/Utas/UtasCalculatorFirstPageLayout.vue'
import Row12 from '@/layouts/Row12Layout.vue'
import Row3333 from '@/layouts/Row3-3-3-3Layout.vue'
import Row444Md from '@/layouts/Row4-4-4-mdLayout.vue'
import Row444Sm from '@/layouts/Row4-4-4-smLayout.vue'
import Row444 from '@/layouts/Row4-4-4Layout.vue'
import Row48 from '@/layouts/Row4-8Layout.vue'
import Row48Md from '@/layouts/Row4-8mdLayout.vue'
import Row57 from '@/layouts/Row5-7Layout.vue'
import Row66Lg48Md from '@/layouts/Row6-6-lg-4-8-mdLayout.vue'
import Row66Md from '@/layouts/Row6-6-mdLayout.vue'
import Row66Sm from '@/layouts/Row6-6-smLayout.vue'
import Row66Xl48Lg from '@/layouts/Row6-6-xl-4-8-lgLayout.vue'
import Row66 from '@/layouts/Row6-6Layout.vue'
import Row84 from '@/layouts/Row8-4Layout.vue'
import CouponCalculator from '../../views/RubViews/CouponCalculator.vue'
import TeamWorkerDiscountCalculator from '../../views/RubViews/TeamWorkerDiscountCalculator.vue'
import VehicleAssistanceSupplement from '../../views/RubViews/VehicleAssistanceSupplement.vue'

const components = {
  HelloWorld,
  Carousel,
  DesktopHeader,
  MobileHeader,
  Header,
  HeroModule,
  ServicesModule,
  ClaimsModule,
  AdministrationModule,
  BlogModule: defineAsyncComponent(() =>
    import('@/components/modules/BlogModule.vue')
  ),
  Minicalculator,
  MobileMinicalculator,
  TabletMinicalculator,
  ProductMinicalculator,
  PackageTableAccident: defineAsyncComponent(() =>
    import('@/components/controls/PackageTableAccident.vue')
  ),
  PackageTableHousehold: defineAsyncComponent(() =>
    import('@/components/controls/PackageTableHousehold.vue')
  ),
  MobilePackageTable: defineAsyncComponent(() =>
    import('@/components/controls/MobilePackageTable.vue')
  ),
  MobilePackageTableAccident: defineAsyncComponent(() =>
    import('@/components/controls/MobilePackageTableAccident.vue')
  ),
  MiniCarouselModule,
  TextInput,
  SelectInput,
  SelectInputEmpty,
  CheckboxInput,
  PhoneInput,
  RadioInput,
  RubRadioInput,
  Footer,
  StickyFooter,
  CalculatorFooter,
  MobileFooter,
  DesktopFooter,
  Row3333,
  Row444,
  Row48,
  Row57,
  Row66,
  Row66Md,
  Row84,
  Row12,
  Title,
  Tooltip,
  AdditionalsModule: defineAsyncComponent(() =>
    import('@/components/modules/AdditionalsModule.vue')
  ),
  AdditionalsReadMoreModule: defineAsyncComponent(() =>
    import('@/components/modules/AdditionalsReadMoreModule.vue')
  ),
  DescriptionModule,
  DescriptionModuleHtml,
  DiscountsModule,
  FAQModule,
  ProductRecommendationModule: defineAsyncComponent(() =>
    import('@/components/modules/ProductRecommendationModule.vue')
  ),
  PricesModule: defineAsyncComponent(() =>
    import('@/components/modules/PricesModule.vue')
  ),
  NestedContent: defineAsyncComponent(() =>
    import('@/components/modules/NestedContent.vue')
  ),
  PackageCompareModule: defineAsyncComponent(() =>
    import('@/components/modules/PackageCompareModule.vue')
  ),
  PackageCompareAccident: defineAsyncComponent(() =>
    import('@/components/modules/PackageCompareAccident.vue')
  ),
  TextAreaInput,
  BreakingNewsModule,
  GoogleScore,
  Row444Md,
  BacktotopProduct,
  BacktotopProductCalculation,
  DescriptionModuleWithCollapse: defineAsyncComponent(() =>
    import('@/components/modules/DescriptionModuleWithCollapse.vue')
  ),
  DatePicker: defineAsyncComponent(() =>
    import('@/components/inputs/DatePicker.vue')
  ),
  DatePickerMonth: defineAsyncComponent(() =>
    import('@/components/inputs/DatePickerMonth.vue')
  ),
  DatePickerMobile: defineAsyncComponent(() =>
    import('@/components/inputs/DatePickerMobile.vue')
  ),
  DatePickerMobileMonth: defineAsyncComponent(() =>
    import('@/components/inputs/DatePickerMobileMonth.vue')
  ),
  Row48Md,
  List,
  DocumentList,
  ComplaintHandlingMobileTab: defineAsyncComponent(() =>
    import(
      '@/components/modules/ComplaintHandling/ComplaintHandlingMobileTab.vue'
    )
  ),
  ComplaintHandlingPersonalTab: defineAsyncComponent(() =>
    import(
      '@/components/modules/ComplaintHandling/ComplaintHandlingPersonalTab.vue'
    )
  ),
  ComplaintHandlingHelpTab: defineAsyncComponent(() =>
    import(
      '@/components/modules/ComplaintHandling/ComplaintHandlingHelpTab.vue'
    )
  ),
  ComplaintHandlingOnlineCustomerServiceTab: defineAsyncComponent(() =>
    import(
      '@/components/modules/ComplaintHandling/ComplaintHandlingOnlineCustomerServiceTab.vue'
    )
  ),
  Accordion,
  FileUploader,
  Alert,
  Dialog,
  HouseholdAdditionalsModule: defineAsyncComponent(() =>
    import('@/components/modules/householdAdditionalsModule.vue')
  ),
  DescriptionModuleAdditionals: defineAsyncComponent(() =>
    import('@/components/modules/DescriptionModuleAdditionals.vue')
  ),
  PackageCompareHousehold: defineAsyncComponent(() =>
    import('@/components/modules/PackageCompareHousehold.vue')
  ),
  MobilePackageTableHousehold: defineAsyncComponent(() =>
    import('@/components/controls/MobilePackageTableHousehold.vue')
  ),
  CouponPackagesModule: defineAsyncComponent(() =>
    import('@/components/modules/CouponPackagesModule.vue')
  ),
  PackageTable: defineAsyncComponent(() =>
    import('@/components/controls/PackageTable.vue')
  ),
  Row444Sm,
  Row66Sm,
  ParagraphReadMore,
  CookieModule,
  ToggleInput,
  MobileFooterItem,
  NavigableLink,
  NavigableDocumentLink,
  DevTools: defineAsyncComponent(() =>
    import('@/components/controls/DevTools.vue')
  ),
  DevToolsUtas: defineAsyncComponent(() =>
    import('@/components/controls/DevToolsUtas.vue')
  ),
  ValidationMessage,
  ZipCodeInput,
  MobileHeaderItem,
  ProductCallback,
  AddressInput,
  VisszahivastKerek,
  CustomerServiceCard,
  PhoneCallbackIFrame: defineAsyncComponent(() =>
    import('@/components/iframes/PhoneCallbackIFrame.vue')
  ),
  PhoneCallbackSuccessIFrame: defineAsyncComponent(() =>
    import('@/components/iframes/PhoneCallbackSuccessIFrame.vue')
  ),
  MinicalculatorIFrame: defineAsyncComponent(() =>
    import('@/components/iframes/MinicalculatorIFrame.vue')
  ),
  Error500IFrame: defineAsyncComponent(() =>
    import('@/components/iframes/Error500IFrame.vue')
  ),
  ReCaptcha,
  DiscountBarTooltip,
  CalculatorLayout,
  UtasCalculatorFirstPageLayout,
  Breadcrumb,
  CouponCalculator,
  TeamWorkerDiscountCalculator,
  VehicleAssistanceSupplement,
  CalculatorButtons,
  IconPropertyChattel,
  DynamicBreadcrumb,
  IconProperty,
  IconChattel,
  InsuranceTypeChoiceInput,
  InsuranceTypeChoiceInputPOC,
  DiscountHint,
  Row66Lg48Md,
  TitleTooltip,
  StickyCart,
  NumericUpDownInput,
  NumericUpDownInputPOC,
  NumericUpDownInputWithUnit,
  MarketingstatementInput,
  MarketingstatementInputPOC,
  GameInput,
  GameInputPOC,
  Row66Xl48Lg,
  CalculatorAccordion,
  CalculatorAdditionalItem,
  CalculatorRecommendationTooltip,
  CalculatorAdditionalPricing,
  ModalButtons,
  AdditionalPet,
  OuthouseRepeaterInput,
  OuthouseRepeaterInputPOC,
  DocumentsInput,
  TravelInsuranceDocumentsInput,
  CalculatorAddressInput,
  CalculatorCouponInput,
  BuildingTypeChoiceInput,
  YesNoInput,
  RubYesNoInput,
  EstateTypeChoiceInput,
  EstateAreaSizeModal,
  EstatePersonalytyModal,
  ValuablesModal,
  CalculatorPackageLinks,
  LoanAuthorizationInput,
  MfoHouseholdPackageSelectInput,
  MfoHouseholdPackageSelectInputPOC,
  MFODesktopPackageRowLayout,
  MFODesktopPackageRowLayoutPOC,
  SubmodusTitle,
  MFODesktopPackage,
  MFODesktopPackagePOC,
  MFOTabletMobilePackage,
  MFOTabletMobilePackagePOC,
  NumberInput,
  MfoEstateBaseInsurancePackageInput,
  MfoPersonalityPackageInput,
  MfoValuablesPackageInput,
  MfoSteadingPersonalityPackageInput,
  MfoLiabilityInsurancePackageInput,
  FinancialInstitutionsInput,
  BankAccountNumberInput,
  CouponView,
  PhoneCallbackCampaign: defineAsyncComponent(() =>
    import('@/components/controls/PhoneCallbackCampaign.vue')
  ),
  PhoneCallbackCampaignSuccess: defineAsyncComponent(() =>
    import('@/components/controls/PhoneCallbackCampaignSuccess.vue')
  ),
  PhoneCallbackSuccessComponent: defineAsyncComponent(() =>
    import('@/components/elements/PhoneCallbackSuccessComponent.vue')
  ),
  TextInputPackage,
  TitleTooltipModal,
  TooltipModal,
  MultiContractNumberInput,
  PartnerDiscountInput,
  LoyaltyDiscountContractNumberInput,
  NumberInputPackage,
}

export default function (app) {
  Object.entries(components).forEach(([name, component]) => {
    app.component(name, component)
  })
}
